import React, { Fragment, useEffect, useRef, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import NavBar from '../components/common/NavBar'
import Container from '@mui/joy/Container'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Component from '../components/common/Component'
import Typography from '@mui/joy/Typography'
import { err, formatDateTime, isDefined } from '../utils/functions'
import {
  getIntegrations,
  GetIntegrationsResult,
  importChannelOrders,
  IntegrationDisplayName,
  IntegrationDisplayNameToName,
  IntegrationName,
} from '../api/integration'
import CircularProgress from '@mui/joy/CircularProgress'
import { getOrders } from '../api/order'
import Button from '@mui/joy/Button'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import ModalClose from '@mui/joy/ModalClose'
import SingleSelect from '../components/common/SingleSelect'
import Alert, { AlertInput, handleAlert } from '../components/common/Alert'
import Avatar from '@mui/joy/Avatar'
import Box from '@mui/joy/Box'
import {
  List,
  ListDivider,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Tooltip,
} from '@mui/joy'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import MultipleSelect from '../components/common/MultipleSelect'
import Chip from '@mui/joy/Chip'
import { useReactToPrint } from 'react-to-print'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import OrderPage from './Order'
import { Order } from '../api/types'
import OrdersTable from './OrdersTable'
import dayjs from 'dayjs'
import { getAllSkus } from '../api/product'

const MAX_PACKING_SLIPS = 3

export default function OrdersPage(): JSX.Element {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [integrations, setIntegrations] = useState<GetIntegrationsResult>()
  const [importOrdersModalOpen, setImportOrdersModalOpen] =
    useState<boolean>(false)
  const [orders, setOrders] = useState<Order[] | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedOrders, setSelectedOrders] = useState<number[]>([])

  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [channels, setChannels] = useState<string[]>([])
  const [statuses, setStatuses] = useState<string[]>([])
  const [skus, setSkus] = useState<string[]>([])

  const [rows, setRows] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(25)

  const [availableSkus, setAvailableSkus] = useState<string[]>([])

  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [selectedImportChannel, setSelectedImportChannel] = useState<
    string | undefined
  >()

  const pickListRef = useRef<HTMLDivElement>(null)
  const printPickList = useReactToPrint({
    contentRef: pickListRef,
    documentTitle: `pick-list_${new Date().getTime()}`,
    onBeforePrint: async () => {
      setPickListLoading(true)
    },
    onAfterPrint: async () => {
      setPickListLoading(false)
    },
  })

  const [isPrinting, setIsPrinting] = useState(false)

  const [pickListLoading, setPickListLoading] = useState<boolean>(false)
  const [renderPackingSlips, setRenderPackingSlips] = useState<boolean>(false)
  const [renderPackingSlipsLoading, setRenderPackingSlipsLoading] = useState<
    boolean[]
  >([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promiseResolveRef = useRef<any>(null)
  useEffect(() => {
    if (
      isPrinting &&
      renderPackingSlipsLoading.length >= selectedOrders.length &&
      promiseResolveRef.current
    ) {
      printPackingSlip()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrinting, selectedOrders, renderPackingSlipsLoading])

  const handlePackingSlipPrint = () => {
    return new Promise((resolve) => {
      promiseResolveRef.current = resolve
      setRenderPackingSlipsLoading([])
      setPackingSlipLoading(true)
      setRenderPackingSlips(true)
      setIsPrinting(true)
    })
  }

  const packingSlipRef = useRef<HTMLDivElement>(null)
  const [packingSlipLoading, setPackingSlipLoading] = useState<boolean>(false)
  const printPackingSlip = useReactToPrint({
    contentRef: packingSlipRef,
    documentTitle: `packing-slips_${new Date().getTime()}`,
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null
      // setRenderPackingSlips(false)
      setRenderPackingSlipsLoading([])
      setPackingSlipLoading(false)
      setRenderPackingSlips(false)
      setIsPrinting(false)
    },
  })

  const handlePackingSlipLoaded = () => {
    setRenderPackingSlipsLoading((renderPackingSlipsLoading) =>
      renderPackingSlipsLoading.concat(true),
    )
  }

  const handleImportOrders = () => {
    if (!selectedImportChannel) return
    importChannelOrders(selectedImportChannel as IntegrationName)
      .then((res) => {
        handleAlert(setAlert, res, 'Imported Orders')
      })
      .catch((e) => err(e))
  }

  const handleQuery = () => {
    const channelNames = channels
      .map((v) => IntegrationDisplayNameToName[v])
      .filter(isDefined)

    const query = {
      startDate,
      endDate,
      skus,
      channels: channelNames,
      statuses,
      page,
      pageSize,
    }

    getOrders(query)
      .then((res) => {
        if (res.success && res.data) {
          setOrders(res.data)
          setRows(res.data?.[0]?.rows || 0)
        }
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    const query = {
      startDate,
      endDate,
      page,
      pageSize,
    }

    getOrders(query)
      .then((res) => {
        if (res.success && res.data) {
          setOrders(res.data)
          setRows(res.data?.[0]?.rows || 0)
        }
      })
      .catch((e) => err(e))
  }, [endDate, page, pageSize, startDate])

  useEffect(() => {
    getIntegrations()
      .then((res) => {
        if (res.success && res.data) {
          setIntegrations(res.data)
        }
      })
      .catch((e) => err(e))
    getAllSkus()
      .then((res) => {
        if (res.success && res.data) {
          setAvailableSkus(res.data)
        }
      })
      .catch((e) => err(e))
  }, [])

  return (
    <div
      id="bg"
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container maxWidth={'xl'}>
        <Grid container sx={{ mt: 1 }} spacing={3}>
          <Grid item xs={12}>
            <Component>
              <Grid
                container
                spacing={1}
                sx={{
                  px: md ? 2 : 0,
                }}
              >
                <Grid item xs={12} sx={{ position: 'relative', mb: 0 }}>
                  <Typography level="h3">
                    <strong>Orders</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="soft"
                    color="neutral"
                    sx={{ float: 'right' }}
                    onClick={() => setImportOrdersModalOpen(true)}
                  >
                    Import Orders
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={1} p={1}>
                    <Grid item xs={6} md={2}>
                      <DatePicker
                        value={
                          startDate
                            ? dayjs(new Date(startDate))
                            : dayjs().subtract(15, 'day')
                        }
                        onChange={(v) => setStartDate(v?.toISOString() || '')}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined',
                            label: 'Start Date',
                            size: 'small',
                            sx: {
                              backgroundColor: '#FBFCFE',
                              color: '#32383E',
                              padding: '0px 0px',
                              lineHeight: '30px',
                              fontSize: 'medium',
                              border: '1px',
                              borderStyle: 'solid',
                              borderColor:
                                'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
                              borderRadius: '6px',
                              boxShadow:
                                '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08)',
                              textAlign: 'left',
                              maxHeight: '600px',
                              overflow: 'visible',
                              '& .MuiOutlinedInput-root': {
                                height: '34px',
                                '& fieldset': {
                                  border: 'none',
                                  padding: '0px',
                                },
                              },
                              '& label': {
                                lineHeight: '18px',
                                background:
                                  'linear-gradient(rgb(251, 252, 254), rgb(251, 252, 254)) 0px 100% / 100% 0.77rem no-repeat',
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <DatePicker
                        value={endDate ? dayjs(new Date(endDate)) : dayjs()}
                        onChange={(v) => setEndDate(v?.toISOString() || '')}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined',
                            label: 'End Date',
                            size: 'small',
                            sx: {
                              backgroundColor: '#FBFCFE',
                              color: '#32383E',
                              padding: '0px 0px',
                              lineHeight: '34px',
                              fontSize: 'medium',
                              border: '1px',
                              borderStyle: 'solid',
                              borderColor:
                                'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
                              borderRadius: '6px',
                              boxShadow:
                                '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08)',
                              textAlign: 'left',
                              maxHeight: '600px',
                              overflow: 'visible',
                              '& .MuiOutlinedInput-root': {
                                height: '34px',
                                '& fieldset': {
                                  border: 'none',
                                },
                              },
                              '& label': {
                                lineHeight: '18px',
                                background:
                                  'linear-gradient(rgb(251, 252, 254), rgb(251, 252, 254)) 0px 100% / 100% 0.77rem no-repeat',
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <MultipleSelect
                        size="md"
                        floatLabel="Channels"
                        value={channels}
                        onChange={setChannels}
                        options={Object.keys(IntegrationDisplayNameToName)}
                      />
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <MultipleSelect
                        floatLabel="Status"
                        value={statuses}
                        onChange={setStatuses}
                        options={['Completed', 'Pending']}
                      />
                    </Grid>
                    <Grid item xs={4} md={2} lg={3}>
                      <MultipleSelect
                        freeSolo={true}
                        floatLabel="SKU"
                        value={skus}
                        onChange={setSkus}
                        options={availableSkus}
                      />
                    </Grid>

                    <Grid item xs={12} md={2} lg={1}>
                      <Button sx={{ float: 'right' }} onClick={handleQuery}>
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} ml={2} mt={3}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Chip
                        color="neutral"
                        startDecorator={<ReceiptLongIcon fontSize="small" />}
                        onClick={() => printPickList()}
                        disabled={!selectedOrders.length || pickListLoading}
                        endDecorator={
                          pickListLoading ? (
                            <CircularProgress
                              size="sm"
                              sx={{
                                ml: 0.5,
                                '--CircularProgress-size': '18px',
                              }}
                            />
                          ) : null
                        }
                      >
                        Pick List
                      </Chip>
                    </Grid>
                    <Grid item>
                      <Tooltip
                        size="sm"
                        title={
                          selectedOrders.length > MAX_PACKING_SLIPS
                            ? `Exceeded maximum number of packing slips to generate at once (${MAX_PACKING_SLIPS})`
                            : null
                        }
                      >
                        <Chip
                          color="neutral"
                          startDecorator={<ReceiptLongIcon fontSize="small" />}
                          onClick={() => handlePackingSlipPrint()}
                          disabled={
                            !selectedOrders.length ||
                            selectedOrders.length > MAX_PACKING_SLIPS ||
                            packingSlipLoading
                          }
                          endDecorator={
                            packingSlipLoading ? (
                              <CircularProgress
                                size="sm"
                                sx={{
                                  ml: 0.5,
                                  '--CircularProgress-size': '18px',
                                }}
                              />
                            ) : null
                          }
                        >
                          Packing Slip
                        </Chip>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={1}>
                    <Grid item xs={12}>
                      {orders ? (
                        <Tabs defaultValue="all" variant="outlined" size="md">
                          <TabList>
                            <Tab value="all">
                              <Typography p={1}>All</Typography>
                            </Tab>
                            <Tab value="open">
                              <Typography p={1}>Open</Typography>
                            </Tab>
                            <Tab value="ship">
                              <Typography p={1}>Ready to Ship</Typography>
                            </Tab>
                            <Tab value="completed">
                              <Typography p={1}>Completed</Typography>
                            </Tab>
                          </TabList>
                          <TabPanel value="all" sx={{ p: 0 }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box>
                                  <OrdersTable
                                    orders={orders || []}
                                    setSelectedOrders={(rows) =>
                                      setSelectedOrders(rows)
                                    }
                                    selectable={true}
                                    page={page}
                                    pageSize={pageSize}
                                    rows={rows}
                                    onPageChange={setPage}
                                    onPageSizeChange={setPageSize}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </TabPanel>
                          <TabPanel value="open" sx={{ p: 0 }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box>
                                  <OrdersTable
                                    orders={
                                      orders.filter(
                                        (order) =>
                                          (order.orderStatus &&
                                            ['open'].includes(
                                              order.orderStatus?.toLowerCase(),
                                            )) ||
                                          (order.shippingStatus &&
                                            !['fulfilled', 'shipped'].includes(
                                              order.shippingStatus?.toLowerCase(),
                                            )),
                                      ) || []
                                    }
                                    setSelectedOrders={(rows) =>
                                      setSelectedOrders(rows)
                                    }
                                    selectable={true}
                                    page={page}
                                    pageSize={pageSize}
                                    onPageChange={setPage}
                                    onPageSizeChange={setPageSize}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </TabPanel>
                          <TabPanel value="ship" sx={{ p: 0 }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box>
                                  <OrdersTable
                                    orders={
                                      orders.filter(
                                        (order) =>
                                          order.readyToShip ||
                                          ((order.shippingStatus ||
                                            order.orderStatus) &&
                                            ![
                                              'fulfilled',
                                              'shipped',
                                              'completed',
                                            ].includes(
                                              (
                                                order.shippingStatus ||
                                                order.orderStatus ||
                                                ''
                                              )?.toLowerCase(),
                                            ) &&
                                            (!order.paymentStatus ||
                                              ['paid'].includes(
                                                order.paymentStatus?.toLowerCase(),
                                              ))),
                                      ) || []
                                    }
                                    setSelectedOrders={(rows) =>
                                      setSelectedOrders(rows)
                                    }
                                    selectable={true}
                                    page={page}
                                    pageSize={pageSize}
                                    onPageChange={setPage}
                                    onPageSizeChange={setPageSize}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </TabPanel>
                          <TabPanel value="completed" sx={{ p: 0 }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box>
                                  <OrdersTable
                                    orders={
                                      orders.filter(
                                        (order) =>
                                          (order.orderStatus &&
                                            ['completed', 'paid'].includes(
                                              order.orderStatus?.toLowerCase(),
                                            )) ||
                                          (order.shippingStatus &&
                                            order.paymentStatus &&
                                            ['fulfilled', 'shipped'].includes(
                                              order.shippingStatus?.toLowerCase(),
                                            ) &&
                                            ['paid'].includes(
                                              order.paymentStatus?.toLowerCase(),
                                            )),
                                      ) || []
                                    }
                                    setSelectedOrders={(rows) =>
                                      setSelectedOrders(rows)
                                    }
                                    selectable={true}
                                    page={page}
                                    pageSize={pageSize}
                                    onPageChange={setPage}
                                    onPageSizeChange={setPageSize}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </TabPanel>
                        </Tabs>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Component>
          </Grid>
        </Grid>

        <div style={{ display: 'none' }}>
          <Box ref={pickListRef} px={1}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography level="title-lg" pt={2}>
                  <strong>Pick List - {formatDateTime(new Date())}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <List variant="outlined">
                  <ListItem>
                    <ListItemDecorator></ListItemDecorator>
                    <ListItemContent>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography level="title-sm">Title</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography level="title-sm">SKU</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography level="title-sm">Qt.</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography level="title-sm">Location</Typography>
                        </Grid>
                      </Grid>
                    </ListItemContent>
                  </ListItem>
                  <ListDivider />
                  {orders
                    ?.filter(
                      (order) =>
                        order.id &&
                        selectedOrders.includes(order.id) &&
                        order.items?.length,
                    )
                    ?.map((order) => {
                      return (
                        <ListItem nested={true} key={order.id}>
                          <ListItem>
                            <ListItemDecorator>
                              <Avatar
                                sx={{
                                  borderRadius: '6px',
                                  border: '1px solid #eee',
                                  width: '24px',
                                  height: '24px',
                                }}
                                size="sm"
                                src={`/channel_icons/${order?.channel?.toLowerCase()}.png`}
                              ></Avatar>
                            </ListItemDecorator>
                            <ListItemContent>
                              <Typography textAlign="left" level="body-xs">
                                <strong>Order #{order.externalOrderId}</strong>
                              </Typography>
                            </ListItemContent>
                          </ListItem>
                          <List>
                            {order.items?.map((item) => {
                              const product = item.product
                              return (
                                <Fragment key={item.id}>
                                  <ListItem>
                                    <ListItemDecorator>
                                      <Avatar
                                        alt={product?.title?.charAt(0) || ' '}
                                        src={item.productImages?.[0]?.url}
                                        sx={{
                                          borderRadius: '2px',
                                          border: '1px solid #eee',
                                          height: '60px',
                                          width: '60px',
                                        }}
                                      ></Avatar>
                                    </ListItemDecorator>
                                    <ListItemContent>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <Grid item xs={6}>
                                          <Typography level="body-sm">
                                            {product?.title}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Typography level="body-sm">
                                            {item.sku}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Typography level="body-sm">
                                            {item.quantity}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Typography level="body-xs">
                                            Location: {product?.location}
                                          </Typography>
                                          <Typography level="body-xs">
                                            Warehouse: {product?.wharehouse}
                                          </Typography>
                                          <Typography level="body-xs">
                                            Bin: {product?.bin}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </ListItemContent>
                                  </ListItem>
                                  <ListDivider />
                                </Fragment>
                              )
                            })}
                          </List>
                        </ListItem>
                      )
                    })}
                </List>
              </Grid>
            </Grid>
          </Box>
        </div>

        <div style={{ display: 'none' }}>
          <Box ref={packingSlipRef} px={1}>
            {renderPackingSlips
              ? orders
                  ?.filter(
                    (order) => order.id && selectedOrders.includes(order.id),
                  )
                  .slice(0, MAX_PACKING_SLIPS)
                  .map((order, i) => {
                    return (
                      <div
                        className="print-container"
                        key={order.id}
                        style={{
                          margin: '0',
                          padding: '0',
                        }}
                      >
                        <div
                          // className="page-break"
                          style={{
                            display: 'block',
                            pageBreakBefore: 'always',
                            height: 'fit-to-content',
                            overflow: 'hidden',
                            width: '100vw',
                          }}
                        />
                        <OrderPage
                          orderId={order.id}
                          integrations={integrations}
                          onLoaded={() => handlePackingSlipLoaded()}
                          renderContent={true}
                        />
                      </div>
                    )
                  })
              : null}
          </Box>
        </div>

        <Box py={3} px={1}></Box>
      </Container>
      <Modal
        open={importOrdersModalOpen}
        onClose={() => setImportOrdersModalOpen(false)}
      >
        <ModalDialog
          layout="center"
          sx={{ overflow: 'auto', p: 2 }}
          minWidth="lg"
        >
          <ModalClose />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography level="h4">Import Orders</Typography>
            </Grid>
            <Grid item xs={12}>
              <SingleSelect
                label="Select Channel"
                value={
                  selectedImportChannel
                    ? IntegrationDisplayName[selectedImportChannel] || ''
                    : ''
                }
                onChange={(value) =>
                  setSelectedImportChannel(
                    value ? IntegrationDisplayNameToName[value] : '',
                  )
                }
                options={Object.keys(IntegrationDisplayNameToName)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ float: 'right' }}
                onClick={() => handleImportOrders()}
              >
                Import
              </Button>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}
