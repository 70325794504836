import React, { useContext, useEffect, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import NavBar from '../../components/common/NavBar'
import ClientSelect from '../../components/admin/ClientSelect'
import CreateClient from '../../components/admin/CreateClient'
import Container from '@mui/material/Container'
import { isSuperAdmin } from '../../utils/functions'
import AppContext from '../../app/AppContext'
import Component from '../../components/common/Component'
import Box from '@mui/joy/Box'
import TemplateLibraryDashboard from './TemplateLibraryDashboard'

export default function SuperAdmin(): JSX.Element {
  const { appState } = useContext(AppContext)
  const isMounted = useRef(true)
  const roleId = appState.user.roleId
  const clientId = appState.client.id
  useEffect(() => {
    if (isMounted.current) {
      return () => {
        isMounted.current = false
      }
    }
  }, [clientId])

  // TODO snackbar alert handling
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container maxWidth={'xl'}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{
            padding: '1em',
          }}
        >
          {isSuperAdmin(roleId) ? (
            <>
              {/* <Grid item xs={12} md={4}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <AllClients />
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid item xs={12}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <ClientSelect />
                  </Grid>

                  <Grid item xs={12}>
                    <CreateClient />
                  </Grid>

                  <Grid item xs={12}>
                    <TemplateLibraryDashboard />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Component>
                <Typography>
                  You do not have sufficient permissions to access admin
                  features.
                </Typography>
              </Component>
            </Grid>
          )}
        </Grid>
      </Container>
      <Box p={16}></Box>
    </div>
  )
}
