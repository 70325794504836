import React, { useEffect } from 'react'

interface MessageData {
  action: string
  url: string
}

const domain =
  process.env.HOMEPAGE_URL || 'https://info290304.wixsite.com/commerce'

export default function SplashPage({ path }: { path?: string }): JSX.Element {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== domain) {
        return // Ignore messages from other sources for security
      }
      const data = event.data as MessageData
      if (data.action === 'navigate') {
        window.location.href = data.url
      }
    }

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <iframe
        width="100%"
        height="100%"
        style={{
          border: 0,
        }}
        title="Commerce"
        src={`${domain}${path ? '/' + path : ''}`}
      ></iframe>
    </div>
  )
}
