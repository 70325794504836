import React, { useEffect, useRef, useState } from 'react'
import NavBar from '../components/common/NavBar'
import Container from '@mui/joy/Container'
import Component from '../components/common/Component'
import Grid from '@mui/material/Grid'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Typography from '@mui/joy/Typography'
import Add from '@mui/icons-material/Add'
import InputContainer from '../components/common/InputContainer'
import {
  getProductsOverview,
  GetProductTemplate,
  getProductTemplates,
  ProductsOverview,
} from '../api/product'
import { err, formatDate } from '../utils/functions'
import TableJoy from '@mui/joy/Table'
import { useHistory } from 'react-router-dom'
import OrdersTable from './OrdersTable'
import {
  getOrders,
  getOrdersByDay,
  getTemplateAnalytics,
  OrdersByDay,
  TemplateAnalytics,
} from '../api/order'
import { Order } from '../api/types'
import dayjs from 'dayjs'
import { BarChart } from '@mui/x-charts/BarChart'
import { LineChart } from '@mui/x-charts/LineChart'
import { IntegrationColors, IntegrationDisplayName } from '../api/integration'
import OpenInNew from '@mui/icons-material/OpenInNew'

export default function Dashboard(): JSX.Element {
  const history = useHistory()
  const [templates, setTemplates] = useState<GetProductTemplate[]>([])
  const [orders, setOrders] = useState<Order[]>([])

  const [productsData, setProductsData] = useState<TemplateAnalytics[]>([])
  const [ordersByDay, setOrdersByDay] = useState<OrdersByDay[]>([])
  const [productsOverview, setProductsOverview] = useState<
    ProductsOverview | undefined
  >()

  const isMounted = useRef(true)

  useEffect(() => {
    const query = {
      startDate: dayjs().subtract(30, 'day').toISOString(),
      endDate: dayjs().toISOString(),
    }

    getOrders(query)
      .then((res) => {
        if (res.success && res.data) {
          setOrders(res.data)
        }
      })
      .catch((e) => err(e))

    getTemplateAnalytics(query)
      .then((res) => {
        if (res.success && res.data) {
          setProductsData(res.data)
        }
      })
      .catch((e) => err(e))

    getOrdersByDay(query)
      .then((res) => {
        if (res.success && res.data) {
          setOrdersByDay(res.data)
        }
      })
      .catch((e) => err(e))

    getProductsOverview()
      .then((res) => {
        if (res.success && res.data) {
          setProductsOverview(res.data)
        }
      })
      .catch((e) => err(e))
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      getProductTemplates()
        .then((res) => setTemplates(res?.data || []))
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  })

  const handleTemplateClick = (templateId: number) => {
    history.push(`/template/${templateId}`)
  }

  return (
    <div
      id="bg"
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container maxWidth={'xl'} sx={{ marginTop: 4 }}>
        <Grid container spacing={6}>
          {/* <Grid item xs={12}>
            <Component title={'Dashboard'}></Component>
          </Grid> */}
          <Grid item xs={12}>
            <Component>
              <Grid
                container
                spacing={3}
                justifyContent="center"
                alignItems="flex-end"
              >
                <Grid item xs={12} md={4}>
                  <InputContainer>
                    <Box height={292}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                      >
                        <Grid item xs={12}>
                          <Typography level="title-lg" pt={3} pb={1}>
                            Add Inventory
                          </Typography>
                        </Grid>
                        <Grid item xs={12} p={2}>
                          <Button
                            sx={{
                              width: '200px',
                            }}
                            size="lg"
                            color="neutral"
                            variant="soft"
                            onClick={() => history.push('/addProduct')}
                            endDecorator={
                              <Add fontSize="medium" color="action" />
                            }
                          >
                            New Product
                          </Button>
                        </Grid>
                        <Grid item xs={12} p={2} mb={4}>
                          <Button
                            sx={{
                              width: '200px',
                            }}
                            size="lg"
                            color="neutral"
                            variant="soft"
                            onClick={() => history.push('/createTemplate')}
                            endDecorator={
                              <Add fontSize="medium" color="action" />
                            }
                          >
                            New Template
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </InputContainer>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputContainer>
                    <Box height={285}>
                      <Grid container spacing={2} p={2}>
                        <Grid item xs={12}>
                          <Typography level="title-sm" textAlign="left">
                            <strong>Products Overview</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography textAlign="left" level="title-sm" ml={1}>
                            {productsOverview?.count || 0} Products
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography textAlign="left" level="title-sm" ml={1}>
                            {productsOverview?.listed || 0} Listed Products
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography textAlign="left" level="title-sm" ml={1}>
                            {productsOverview?.unlisted || 0} Unlisted Products
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography textAlign="left" level="title-sm" ml={1}>
                            {productsOverview?.sold || 0} Sold Products
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography textAlign="left" level="title-sm" ml={1}>
                            {productsOverview?.incomplete || 0} Incomplete
                            Products
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            sx={{ float: 'right' }}
                            size="sm"
                            color="neutral"
                            variant="plain"
                            onClick={() => history.push('/products')}
                            endDecorator={
                              <OpenInNew
                                fontSize="small"
                                color="disabled"
                                sx={{
                                  fontSize: 14,
                                }}
                              />
                            }
                          >
                            <Typography level="body-sm">
                              <strong>Products</strong>
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </InputContainer>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ position: 'relative' }}>
                      <InputContainer>
                        <Box
                          height={270}
                          sx={{
                            overflowY: 'scroll',
                          }}
                        >
                          <TableJoy
                            size="md"
                            sx={{
                              '& tr > *': {
                                textAlign: 'left',
                                cursor: 'pointer',
                                overflow: 'clip',
                                wordBreak: 'keep-all',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                fontSize: '12px',
                              },
                              '& thead th:nth-child(1)': {
                                width: '70%',
                              },
                            }}
                            variant={'plain'}
                            color={'neutral'}
                          >
                            <thead>
                              <tr>
                                {['Templates', 'Products'].map((header) => (
                                  <th
                                    key={header}
                                    style={{ cursor: 'default' }}
                                  >
                                    <Typography level="title-sm">
                                      <strong>{header}</strong>
                                    </Typography>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {templates.map((template) => (
                                <tr
                                  key={template.template.id}
                                  onClick={() =>
                                    handleTemplateClick(template.template.id)
                                  }
                                >
                                  <td>{template.template.name}</td>

                                  <td>{template.template.products}</td>
                                </tr>
                              ))}
                            </tbody>
                          </TableJoy>
                        </Box>
                      </InputContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Component>
          </Grid>
          <Grid item xs={12}>
            <Component>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Typography level="h3" mt={3}>
                    Orders
                  </Typography>
                  <Typography level="body-sm" p={1}>
                    Orders from the last month
                  </Typography>
                  <Button
                    size="sm"
                    color="neutral"
                    variant="plain"
                    onClick={() => history.push('/orders')}
                    endDecorator={
                      <OpenInNew
                        fontSize="small"
                        color="disabled"
                        sx={{
                          fontSize: 14,
                        }}
                      />
                    }
                  >
                    <Typography level="body-sm">
                      <strong>All Orders</strong>
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} md={9}>
                  <InputContainer>
                    <OrdersTable orders={orders} pageSize={10}></OrdersTable>
                  </InputContainer>
                </Grid>
              </Grid>
            </Component>
          </Grid>

          <Grid item xs={12}>
            <Component>
              <Grid container spacing={3} justifyContent="space-between">
                <Grid item xs={12} md={3}>
                  <Typography level="h3" mt={3}>
                    Analytics
                  </Typography>
                  <Typography level="body-sm" p={1}>
                    Data from the last month
                  </Typography>
                  <Button
                    size="sm"
                    color="neutral"
                    variant="plain"
                    onClick={() => history.push('/analytics')}
                    endDecorator={
                      <OpenInNew
                        fontSize="small"
                        color="disabled"
                        sx={{
                          fontSize: 14,
                        }}
                      />
                    }
                  >
                    <Typography level="body-sm">
                      <strong>All Analytics</strong>
                    </Typography>
                  </Button>
                </Grid>

                <Grid item xs={12} md={5}>
                  <InputContainer>
                    <Box pb={3} px={1}>
                      <Typography level="title-lg" mb={3}>
                        <strong>Daily Orders</strong>
                      </Typography>
                      {ordersByDay.length ? (
                        <LineChart
                          slotProps={{
                            legend: {
                              itemMarkWidth: 12,
                              itemMarkHeight: 12,
                              labelStyle: {
                                fontSize: 12,
                                fillOpacity: 0.8,
                              },
                            },
                          }}
                          yAxis={[
                            {
                              label: '# of Orders',
                              min: 0,
                            },
                          ]}
                          xAxis={[
                            {
                              id: 'Channel Sales',
                              data: ordersByDay.map((d) => formatDate(d.day)),
                              scaleType: 'band',
                              // categoryGapRatio: 0.4,
                              // barGapRatio: 0.8,
                            },
                          ]}
                          // series={[
                          //   {
                          //     data: ordersByDay.map((g) => g.count || 0),
                          //     color:
                          //       'var(--my-custom-gradient, #b0f7ee, #02ad97)',
                          //   },
                          // ]}
                          series={[
                            'ebay',
                            'etsy',
                            'square',
                            'shopify',
                            'bigcommerce',
                          ].map((key) => ({
                            id: key,
                            dataKey: key,
                            label: IntegrationDisplayName[key],
                            showMark: false,
                            stack: 'total',
                            area: true,
                            color: IntegrationColors[key],
                            stackOffset: 'none', // To stack 0 on top of others
                          }))}
                          dataset={ordersByDay}
                          margin={{
                            top: 70,
                            bottom: 25,
                            left: 40,
                            right: 0,
                          }}
                          height={300}
                          sx={{
                            '& .MuiAreaElement-root': {
                              // fill: 'rgba(0, 207, 180, 1)',
                              // fill: 'url(#ebayGradient)',

                              // stroke: '#02ad97',
                              fillOpacity: '0.5',
                              strokeOpacity: '1',
                            },
                            '& .MuiAreaElement-series-ebay': {
                              fill: 'url(#ebayGradient)',
                              fillOpacity: '0.6',
                            },
                            '& .MuiAreaElement-series-etsy': {
                              fill: 'url(#etsyGradient)',
                              fillOpacity: '0.6',
                            },
                            '& .MuiAreaElement-series-square': {
                              fill: 'url(#squareGradient)',
                              fillOpacity: '0.6',
                            },
                            '& .MuiAreaElement-series-shopify': {
                              fill: 'url(#shopifyGradient)',
                              fillOpacity: '0.6',
                            },
                            '& .MuiAreaElement-series-bigcommerce': {
                              fill: 'url(#bigcommerceGradient)',
                              fillOpacity: '0.6',
                            },
                          }}
                        >
                          <linearGradient
                            id="ebayGradient"
                            y1="100%"
                            x2="0%"
                            y2="0%"
                          >
                            <stop offset="0%" stopColor="#fff" />
                            <stop
                              offset="100%"
                              stopColor={IntegrationColors['ebay']}
                            />
                          </linearGradient>
                          <linearGradient
                            id="etsyGradient"
                            y1="100%"
                            x2="0%"
                            y2="0%"
                          >
                            <stop offset="0%" stopColor="#fff" />
                            <stop
                              offset="100%"
                              stopColor={IntegrationColors['etsy']}
                            />
                          </linearGradient>
                          <linearGradient
                            id="squareGradient"
                            y1="100%"
                            x2="0%"
                            y2="0%"
                          >
                            <stop offset="0%" stopColor="#fff" />
                            <stop
                              offset="100%"
                              stopColor={IntegrationColors['square']}
                            />
                          </linearGradient>
                          <linearGradient
                            id="shopifyGradient"
                            y1="100%"
                            x2="0%"
                            y2="0%"
                          >
                            <stop offset="0%" stopColor="#fff" />
                            <stop
                              offset="100%"
                              stopColor={IntegrationColors['shopify']}
                            />
                          </linearGradient>
                          <linearGradient
                            id="bigcommerceGradient"
                            y1="100%"
                            x2="0%"
                            y2="0%"
                          >
                            <stop offset="0%" stopColor="#fff" />
                            <stop
                              offset="100%"
                              stopColor={IntegrationColors['bigcommerce']}
                            />
                          </linearGradient>
                        </LineChart>
                      ) : (
                        <Box py={'150px'}>
                          <Typography level="title-md">
                            <strong>No Data</strong>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </InputContainer>
                </Grid>

                <Grid item xs={12} md={4}>
                  <InputContainer>
                    <Box pb={3} px={0}>
                      <Typography level="title-lg" mb={3}>
                        <strong>Sales By Template</strong>
                      </Typography>
                      {productsData.length ? (
                        <BarChart
                          skipAnimation={false}
                          yAxis={[{ label: '# Sold' }]}
                          xAxis={[
                            {
                              id: 'Template Sales',
                              data: productsData.map((g) => g.templateName),
                              scaleType: 'band',
                            },
                          ]}
                          series={[
                            {
                              data: productsData.map(
                                (g) => parseInt(`${g.quantity}`) || 0,
                              ),
                              color:
                                'var(--my-custom-gradient, #b0f7ee, #02ad97)',
                            },
                          ]}
                          margin={{
                            top: 25,
                            bottom: 25,
                            left: 40,
                            right: 0,
                          }}
                          colors={['#02ad97']}
                          height={300}
                          sx={{
                            '--my-custom-gradient': 'url(#GlobalGradient)',
                            '.MuiBarElement-root': {
                              ry: '3',
                              rx: '3',
                              // stroke: '#02ad97',
                              // fill: '#02ad97',
                              // fillOpacity: '0.6',
                              // strokeOpacity: '0.9',
                            },
                          }}
                        >
                          <linearGradient
                            id="GlobalGradient"
                            x1="0%"
                            y1="100%"
                            x2="0%"
                            y2="0%"
                          >
                            <stop
                              offset="0%"
                              stopColor="rgba(0, 207, 180, 0.5)"
                            />
                            <stop
                              offset="2%"
                              stopColor="rgba(0, 207, 180, 0.3)"
                            />
                            <stop
                              offset="99%"
                              stopColor="rgba(0, 207, 180, 0.75)"
                            />
                            <stop
                              offset="100%"
                              stopColor="rgba(0, 207, 180, 0.1)"
                            />
                          </linearGradient>
                        </BarChart>
                      ) : (
                        <Box py={'150px'}>
                          <Typography level="title-md">
                            <strong>No Data</strong>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </InputContainer>
                </Grid>
              </Grid>
            </Component>
          </Grid>

          <Grid item xs={12} mt={40}></Grid>
        </Grid>
      </Container>
    </div>
  )
}
