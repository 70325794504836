import React, { useContext, useEffect, useState, useRef } from 'react'
import Typography from '@mui/joy/Typography'
import NavBar from '../../components/common/NavBar'
import { getClient } from '../../api/admin'
import { cloudinaryUploadImage, err, log } from '../../utils/functions'
import AppContext from '../../app/AppContext'
import { Client } from '../../api/types'

import Container from '@mui/material/Container'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/joy/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import Component from '../../components/common/Component'
import { CloudinarySignature, getCloudinarySignature } from '../../api/product'
import {
  acceptRole,
  declineRole,
  getUser,
  getUserRoles,
  RoleClient,
  setUser,
} from '../../api/account'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'
import { ROLE_MAP } from '../../types'
import LabelInput from '../../components/common/LabelInput'
import LabelTextarea from '../../components/common/LabelTextarea'

export default function Account(): JSX.Element {
  const { appState } = useContext(AppContext)
  const { appReducer } = React.useContext(AppContext)

  const isMounted = useRef(true)
  // const roleId = appState.user.roleId
  const clientId = appState.client.id
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [client, setClient] = useState<Client>()

  const [email, setEmail] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [profileImage, setProfileImage] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [roles, setRoles] = useState<RoleClient[]>([])

  const [cloudinarySignature, setCloudinarySignature] = useState<
    CloudinarySignature | undefined
  >()

  useEffect(() => {
    const userId = appState.user.id
    if (!userId) return
    getUser(userId)
      .then((res) => {
        if (res.success && res.data) {
          setEmail(res.data.email || '')
          setName(res.data.name || '')
          setDescription(res.data.description || '')
          setProfileImage(res.data.image || '')
        }
      })
      .catch((e) => err(e))
  }, [appState.user.id])

  useEffect(() => {
    const userId = appState.user.id
    if (!userId) return
    getUserRoles(userId)
      .then((res) => {
        if (res.success && res.data?.length) {
          setRoles(res.data)
        }
      })
      .catch((e) => err(e))
  }, [appState.user.id])

  useEffect(() => {
    getCloudinarySignature()
      .then((res) => {
        if (res.success && res.data) {
          setCloudinarySignature(res.data)
        } else {
          log(res.message)
        }
      })
      .catch((e) => err(e))
  }, [])

  const handleSelectImage = async (files: File[]) => {
    if (!appState.user.id) return
    const imageFile = files[0]
    if (!imageFile) return
    if (!cloudinarySignature) return
    const uploadedImageUrl = await cloudinaryUploadImage(
      cloudinarySignature,
      imageFile,
    )

    if (!uploadedImageUrl) {
      setAlert({
        open: true,
        message: 'Failed to upload profile image.',
        severity: 'error',
      })
      return
    }

    setProfileImage(uploadedImageUrl)
    // Update user
    const res = await setUser({
      id: appState.user.id,
      name: name,
      description: description,
      image: uploadedImageUrl,
    })

    if (res.success && res.data) {
      handleAlert(setAlert, res, 'Successfully updated profile image.')
      appReducer({
        type: 'user',
        payload: {
          ...appState.user,
          image: res.data.image || '',
        },
      })
    } else {
      setAlert({
        open: true,
        message: 'Failed to update profile image.',
        severity: 'error',
      })
    }
  }

  const handleDeclinePendingRole = (userId: number, clientId: number) => {
    declineRole(userId, clientId)
      .then((res) => {
        handleAlert(setAlert, res, 'Role Declined.')

        const delinedIndex = roles.findIndex(
          (role) => role.clientId === clientId,
        )
        if (delinedIndex !== -1 && res.data) {
          const newRoles = roles
          newRoles.splice(delinedIndex, 1)
          setRoles(newRoles)
        }
      })
      .catch((e) => err(e))
  }
  const handleAcceptPendingRole = (userId: number, clientId: number) => {
    acceptRole(userId, clientId)
      .then((res) => {
        handleAlert(
          setAlert,
          res,
          'Accepted Role. You must re-login for change to take affect.',
        )

        const acceptedRoleIndex = roles.findIndex(
          (role) => role.clientId === clientId,
        )
        if (acceptedRoleIndex !== -1 && res.data) {
          const newRoles = roles
          if (!newRoles[acceptedRoleIndex]) return
          const currentRole = newRoles[acceptedRoleIndex]
          if (!currentRole) return
          currentRole.status = 'accepted'
          newRoles[acceptedRoleIndex] = currentRole
          setRoles(newRoles)
        }
      })
      .catch((e) => err(e))
  }

  const handleSave = () => {
    if (!appState.user.id) return

    const updateUser = {
      id: appState.user.id,
      name,
      description,
      image: profileImage,
    }
    setUser(updateUser)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully updated.')
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    if (isMounted.current) {
      if (clientId) {
        getClient({ id: clientId })
          .then((res) => {
            if (res?.data) {
              setClient(res.data)
            }
          })
          .catch((e) => err(e))
      }
      return () => {
        isMounted.current = false
      }
    }
  }, [clientId])

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container maxWidth={'xl'}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{
            padding: '1em',
          }}
        >
          <Grid item xs={12}>
            <Component title="Account Details">
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      width: '150px',
                      height: '150px',
                      m: 'auto',
                    }}
                  >
                    <>
                      <input
                        hidden
                        id={'profile-image-upload'}
                        type="file"
                        name="myImage"
                        accept=".png,.jpeg,.jpg"
                        onChange={(e) => {
                          void handleSelectImage(
                            Array.from(e.target.files || []),
                          )
                        }}
                      />
                      <label htmlFor={'profile-image-upload'}>
                        <Avatar
                          alt={name || 'Profile Image'}
                          src={profileImage}
                          sx={{
                            width: '150px',
                            height: '150px',
                            m: 'auto',
                            cursor: 'pointer',
                          }}
                        />
                      </label>
                    </>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <LabelInput label="Email" fullWidth value={email} disabled />
                </Grid>
                <Grid item xs={12}>
                  <LabelInput
                    label="Name"
                    fullWidth
                    value={name}
                    onChange={setName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LabelTextarea
                    label="Biography"
                    value={description}
                    minRows={4}
                    onChange={setDescription}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={handleSave}
                    sx={{ float: 'right' }}
                    variant="solid"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Component>
          </Grid>

          <Grid item xs={12}>
            <Component title="Organizations">
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <List dense={true}>
                    {roles.length ? (
                      roles.map((role) => {
                        return (
                          <ListItem
                            key={role.id}
                            secondaryAction={
                              role.status === 'pending' ? (
                                <>
                                  <Grid
                                    container
                                    justifyContent="space-between"
                                    spacing={2}
                                  >
                                    <Grid item>
                                      <Button
                                        onClick={() =>
                                          handleDeclinePendingRole(
                                            role.userId,
                                            role.clientId,
                                          )
                                        }
                                        color="danger"
                                        variant="soft"
                                      >
                                        Decline Invite
                                      </Button>
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        onClick={() =>
                                          handleAcceptPendingRole(
                                            role.userId,
                                            role.clientId,
                                          )
                                        }
                                      >
                                        Accept Invite
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </>
                              ) : null
                            }
                          >
                            <ListItemText
                              primary={
                                <>
                                  <Typography component="span" level="body-md">
                                    {role.displayName}
                                  </Typography>{' '}
                                  <Typography
                                    component="span"
                                    level="body-xs"
                                    sx={{ color: '#666' }}
                                  >
                                    {role.name}
                                  </Typography>
                                </>
                              }
                              secondary={
                                <Typography component="span" level="body-md">
                                  Role: {ROLE_MAP[role.roleId]}
                                </Typography>
                              }
                            />
                          </ListItem>
                        )
                      })
                    ) : (
                      <Typography>No client invites</Typography>
                    )}
                  </List>
                </Grid>
              </Grid>
            </Component>
          </Grid>
          <Grid item xs={12}>
            <Box p={8}></Box>
          </Grid>
        </Grid>
      </Container>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}
