import React from 'react'
import Component from '../../components/common/Component'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'

export default function TemplateLibraryDashboard(): JSX.Element {
  return (
    <Component title="Template Library">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>Template Library</Typography>
        </Grid>
      </Grid>
    </Component>
  )
}
