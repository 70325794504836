import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { useHistory } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { GetProductTemplate, getProductTemplates } from '../../api/product'
import Component from '../../components/common/Component'
import TableJoy from '@mui/joy/Table'
// import Table from '../../components/common/JoyTable'
import NavBar from '../../components/common/NavBar'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { err } from '../../utils/functions'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import Box from '@mui/joy/Box'

export default function ProductTemplates(): JSX.Element {
  const history = useHistory()
  const isMounted = useRef(true)
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  const [templates, setProductTemplates] = useState<GetProductTemplate[]>([])

  useEffect(() => {
    if (isMounted.current) {
      getProductTemplates()
        .then((res) => setProductTemplates(res.data || []))
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  })

  const handleAddType = () => {
    history.push('/createTemplate')
  }

  const handleRowClick = (templateId: number) => {
    history.push(`/template/${templateId}`)
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container maxWidth={'xl'}>
        <Grid container sx={{ mt: 1 }} spacing={3}>
          <Grid item xs={12}>
            <Component>
              <Grid
                container
                spacing={3}
                sx={{
                  px: md ? 2 : 0,
                }}
              >
                <Grid item xs={12} sx={{ position: 'relative' }}>
                  <Typography level="h3">
                    Templates
                    <Fab
                      color="primary"
                      size="small"
                      sx={{ position: 'absolute', right: 0 }}
                      onClick={handleAddType}
                    >
                      <AddIcon />
                    </Fab>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box py={4} px={2}>
                    <TableJoy
                      borderAxis="both"
                      size="lg"
                      sx={{
                        '& tr > *': {
                          textAlign: 'left',
                          cursor: 'pointer',
                          overflow: 'clip',
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        },
                      }}
                      aria-label="table variants"
                      variant={'plain'}
                      color={'neutral'}
                    >
                      <thead>
                        <tr>
                          {['Name', 'Description', 'Products'].map((header) => (
                            <th key={header} style={{ cursor: 'default' }}>
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {templates.map((template) => (
                          <tr
                            key={template.template.id}
                            onClick={() => handleRowClick(template.template.id)}
                          >
                            <td>{template.template.name}</td>
                            <td title={template.template.typeDescription}>
                              {template.template.typeDescription?.slice(0, 80)}
                            </td>
                            <td>{template.template.products}</td>
                          </tr>
                        ))}
                      </tbody>
                    </TableJoy>
                  </Box>
                </Grid>
              </Grid>
            </Component>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
