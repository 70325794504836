import React, { ReactNode, useState } from 'react'
import Autocomplete, { createFilterOptions } from '@mui/joy/Autocomplete'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import Typography from '@mui/joy/Typography'
import FormHelperText from '@mui/joy/FormHelperText'
import AutocompleteOption from '@mui/joy/AutocompleteOption'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import Add from '@mui/icons-material/Add'
import { InnerInput } from './FloatLabelInput'
import Box from '@mui/joy/Box'

interface SingleSelectProps {
  id?: string
  label?: string | JSX.Element
  floatLabel?: string
  placeholder?: string
  value: string
  options: string[]
  freeSolo?: boolean
  addValues?: boolean
  required?: boolean
  disabled?: boolean
  defaultOption?: boolean
  errorText?: string
  helperText?: string | ReactNode
  warningText?: string
  endDecorator?: React.ReactNode
  button?: React.ReactNode
  toolTip?: string
  disableClearIndicator?: boolean
  renderOption?: (option: string) => string | React.ReactNode
  onChange?: (value: string | undefined) => void
  onAddValue?: (value: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
}

const filter = createFilterOptions<string>()
export default function SingleSelect({
  id,
  label,
  floatLabel,
  placeholder,
  value: defaultValue,
  options,
  freeSolo,
  addValues,
  required,
  disabled,
  defaultOption,
  errorText,
  warningText,
  helperText,
  endDecorator,
  button,
  toolTip,
  disableClearIndicator,
  onChange,
  onAddValue,
  onKeyDown,
  renderOption,
}: SingleSelectProps): JSX.Element {
  const [localValue, setLocalValue] = useState<string>(defaultValue)

  const handleChange = (newValue: string) => {
    setLocalValue(newValue)
    onChange?.(newValue)
  }

  const allOptions = defaultOption !== false ? [''].concat(options) : options

  const AutocompleteElement = (
    <Autocomplete
      id={id}
      value={defaultValue}
      inputValue={localValue}
      onInputChange={(e, v) => setLocalValue(v)}
      options={allOptions}
      freeSolo={freeSolo}
      disabled={disabled}
      autoHighlight={true}
      required={required}
      placeholder={placeholder}
      slots={{
        ...(floatLabel
          ? {
              input: InnerInput,
            }
          : {}),
        clearIndicator: disableClearIndicator ? () => <></> : undefined,
        popupIndicator: () => <></>,
      }}
      slotProps={{
        input: {
          label: floatLabel,
          placeholder: placeholder,
        },
      }}
      // onChange={(e, value) => {
      //   if (value && value.startsWith('Add "')) {
      //     setLocalValue('')
      //     const addValue = new RegExp(/Add "(.*)"/gim).exec(value)?.[1]
      //     if (!addValue) return
      //     onAddValue?.(addValue)
      //   } else {
      //     //
      //     handleChange(value || '')
      //   }
      // }}
      onChange={(e, value) => {
        if (addValues) {
          if (value && value.startsWith('Add "')) {
            const addValue = new RegExp(/Add "(.*)"/gim).exec(value)?.[1]
            if (!addValue) return
            onAddValue?.(addValue)
            handleChange(addValue)
          } else {
            //
            handleChange(value || '')
          }
        } else {
          handleChange(value || '')
        }
      }}
      filterOptions={
        addValues
          ? (options, params) => {
              const filtered = filter(options, params)
              if (params.inputValue !== '' && filtered.length === 0) {
                filtered.push(`Add "${params.inputValue}"`)
              }
              return filtered
            }
          : undefined
      }
      onSelect={(e) => {
        const value = (e.target as HTMLInputElement).value
        if (value.startsWith('Add "')) {
          const addValue = new RegExp(/Add "(.*)"/gim).exec(value)?.[1]
          if (!addValue) return
          setLocalValue(addValue)
        }
      }}
      renderOption={
        addValues
          ? (props, option, state) => (
              <AutocompleteOption
                {...props}
                onClick={(e) => {
                  console.log('Option click')
                  if (option?.startsWith('Add "')) {
                    e.stopPropagation()
                    if (state.inputValue) {
                      onAddValue?.(state.inputValue)
                    }
                  } else {
                    handleChange(option)
                  }
                }}
              >
                {option?.startsWith('Add "') && (
                  <ListItemDecorator>
                    <Add />
                  </ListItemDecorator>
                )}
                {renderOption ? renderOption?.(option) : option}
              </AutocompleteOption>
            )
          : (props, option, state) => (
              <AutocompleteOption {...props}>
                {renderOption ? renderOption?.(option) : option}
              </AutocompleteOption>
            )
      }
      autoComplete
      openOnFocus
      clearOnEscape
      multiple={false}
      error={!!errorText}
      color={warningText ? 'warning' : undefined}
      isOptionEqualToValue={(option, value) =>
        option === value || option === `Add "${value}"`
      }
      onKeyDown={(e) => onKeyDown?.(e)}
      endDecorator={endDecorator}
    ></Autocomplete>
  )

  return (
    <FormControl>
      {label && typeof label === 'string' ? (
        <FormLabel sx={{ width: '100%' }}>
          <Typography level="title-sm">
            <strong>{label}</strong>
          </Typography>
        </FormLabel>
      ) : null}
      {label && typeof label !== 'string' ? (
        <FormLabel sx={{ width: '100%' }}>{label}</FormLabel>
      ) : null}

      {button ? (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>{AutocompleteElement}</Box>
          <Box sx={{ ml: 1 }}>{button}</Box>
        </Box>
      ) : (
        AutocompleteElement
      )}

      {errorText ? (
        <FormHelperText sx={{ mt: 0.1 }}>
          <Typography level="body-sm">{errorText}</Typography>
        </FormHelperText>
      ) : null}
      {warningText && !errorText ? (
        <FormHelperText sx={{ mt: 0.1 }}>
          <Typography color="warning" level="body-sm">
            {warningText}
          </Typography>
        </FormHelperText>
      ) : null}
      {helperText &&
      !errorText &&
      !warningText &&
      typeof helperText === 'string' ? (
        <FormHelperText sx={{ mt: 0.1 }}>
          <Typography level="body-sm">{helperText}</Typography>
        </FormHelperText>
      ) : null}

      {helperText &&
      !errorText &&
      !warningText &&
      typeof helperText !== 'string' ? (
        <FormHelperText sx={{ mt: 0.2, ml: 0.4 }}>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  )
}
