import React, { useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/joy/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { useHistory } from 'react-router-dom'
import AppContext from '../../app/AppContext'
import { getUser, logout } from '../../api/account'
import Cookies from 'js-cookie'
import { err, isSuperAdmin, log } from '../../utils/functions'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import IconButton from '@mui/joy/IconButton'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import ModalClose from '@mui/joy/ModalClose'

const pages = [
  'Dashboard',
  'Templates',
  'Products',
  'Orders',
  'Analytics',
  'Integrations',
]
const settings = ['Organization', 'Account', 'Logout']

export default function NavBar(): JSX.Element {
  const history = useHistory()
  const { appReducer } = React.useContext(AppContext)
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  )

  const [supportModalOpen, setSupportModalOpen] = React.useState<boolean>(false)

  const { appState } = React.useContext(AppContext)

  useEffect(() => {
    if (!appState.user.id || !appState.user.roleId) return

    if (isSuperAdmin(appState.user.roleId)) {
      settings.splice(0, 0, 'Admin')
    }
  }, [appState.user])

  useEffect(() => {
    if (!appState.user.id || appState.user.image !== undefined) return
    getUser(appState.user.id)
      .then((res) => {
        log('Fetching user profile image.')

        if (res.success && res.data) {
          appReducer({
            type: 'user',
            payload: {
              ...appState.user,
              image: res.data.image || '',
            },
          })
        }
      })
      .catch((e) => err(e))
  }, [appReducer, appState.user, appState.user.id])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleNavClick = (name: string) => {
    switch (name) {
      case 'Templates':
        history.push('/templates')
        break
      case 'Products':
        history.push('/products')
        break
      case 'Orders':
        history.push('/orders')
        break
      case 'Analytics':
        history.push('/analytics')
        break
      case 'Listings':
        history.push('/listings')
        break
      case 'Integrations':
        history.push('/integrations')
        break
      case 'Account':
        history.push('/account')
        break
      case 'Organization':
        history.push('/admin')
        break
      case 'Dashboard':
        history.push('/dashboard')
        break
      case 'Logout':
        void handleLogout()
        break
      case 'Admin':
        history.push('/superAdmin')
        break
      default:
        break
    }
  }

  const handleLogout = async () => {
    const result = await logout()
    if (result.success) {
      appReducer({ type: 'jwt', payload: undefined })
      appReducer({ type: 'user', payload: undefined })
      Cookies.remove('jwt', { path: '' })
      history.push('/login')
    }
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar
      position="static"
      elevation={1}
      sx={{ height: '55px', backgroundColor: 'white', color: 'black' }}
    >
      <Box sx={{ pl: 3, pr: 3 }}>
        <Toolbar disableGutters variant="dense" sx={{ height: '55px' }}>
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'none', md: 'flex' }, mr: 0 }}
          >
            <div
              style={{
                width: 35,
                height: 35,
                borderRadius: 35,
                overflow: 'hidden',
                alignContent: 'center',
                display: 'flex',
                position: 'relative',
              }}
            >
              <img
                src="https://i.pinimg.com/originals/0c/67/5a/0c675a8e1061478d2b7b21b330093444.gif"
                height={35}
                width={48}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '50%',
                  transform: 'translate(50%,-50%)',
                }}
                alt={''}
              ></img>
            </div>
          </IconButton> */}
          <Typography
            component="a"
            href="/"
            level="h3"
            sx={{
              mr: 0.8,
              ml: -1,
              display: { xs: 'none', md: 'flex' },
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Sellmotto
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="lg"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => handleNavClick(page)}>
                  <Typography textAlign="center" level="title-md">
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            noWrap
            component="a"
            href="/"
            level="h3"
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              textDecoration: 'none',
            }}
          >
            Sellmotto
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Typography
                level="title-md"
                key={page}
                onClick={() => handleNavClick(page)}
                sx={{
                  color: 'black',
                  display: 'block',
                  cursor: 'pointer',
                  px: 2,
                  py: 0,
                }}
              >
                {page}
              </Typography>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, px: 2 }}>
            <Tooltip title="Contact Support">
              <IconButton onClick={() => setSupportModalOpen(true)}>
                <SupportAgentIcon sx={{ opacity: 0.7 }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open Menu">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  src={appState.user.image || ''}
                  alt=""
                  sx={{ height: 35, width: 35, border: '1px solid #ddd' }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={() => {
                handleCloseUserMenu()
              }}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => {
                    handleNavClick(setting)
                    handleCloseUserMenu()
                  }}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Box>
      <Modal open={supportModalOpen} onClose={() => setSupportModalOpen(false)}>
        <ModalDialog
          sx={(theme) => ({
            [theme.breakpoints.only('xs')]: {
              top: 'unset',
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: 'none',
              maxWidth: 'unset',
            },
          })}
        >
          <ModalClose />
          <Typography level="h4">Support</Typography>
          <Box p={2}>
            <Typography>
              If you have any questions or need any assistance please reach out
              to our support team at{' '}
              <a href="mailto:info@sellmotto.com">info@sellmotto.com</a> and we
              will assist you as soon as possible.
            </Typography>
          </Box>
        </ModalDialog>
      </Modal>
    </AppBar>
  )
}
