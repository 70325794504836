import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Fab from '@mui/material/Fab'
import { useEffect, useRef, useState } from 'react'
import {
  applyTags,
  getAllAttributeValues,
  getAllSkus,
  getAllTags,
  getAllTemplates,
  getInventoryColumns,
  GetProduct,
  searchProducts,
  SearchProductsQuery,
  setInventoryColumns,
} from '../../api/product'
import Component from '../../components/common/Component'
import NavBar from '../../components/common/NavBar'
import AddIcon from '@mui/icons-material/Add'
import {
  err,
  formatDate,
  isDefined,
  log,
  parseBoolean,
  removeElements,
  split,
  unique,
} from '../../utils/functions'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Input from '@mui/joy/Input'
import Avatar from '@mui/material/Avatar'
import { PRODUCT_FIELD_NAMES } from '../../types'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandMore from '../../components/common/ExpandMore'
import Button from '@mui/joy/Button'
import Switch from '@mui/joy/Switch'
import AvatarGroup from '@mui/material/AvatarGroup'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import MultipleSelect from '../../components/common/MultipleSelect'
import SingleSelect from '../../components/common/SingleSelect'
import VerticalIconMenu from '../../components/common/VerticalIconMenu'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Modal from '../../components/common/Modal'
import PaginatedDataTable from '../../components/common/PaginatedDataTable'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'
import { GridRenderCellParams } from '@mui/x-data-grid'
import CircularProgress from '@mui/joy/CircularProgress'
import { getClientCSVSpreadsheetIntegrations } from '../../api/integrations/csv'
import { IntegrationName } from '../../api/integration'
import { parseDescription, parseTitle } from '../../classes/Parser'
import Add from '@mui/icons-material/Add'
import FloatLabelInput from '../../components/common/FloatLabelInput'

enum PRODUCT_COLUMNS_ENUM {
  ID = 'id',
  SKU = 'SKU',
  TITLE = 'Title',
  NOTES = 'Notes',
  TAGS = 'Tags',
  DESCRIPTION = 'Description',
  CONDITION = 'Condition',
  IMAGE = 'Image',
  QUANTITY = 'Quantity',
  SOLD = 'Sold',
  COST = 'Cost',
  PRICE = 'Price',
  CREATED = 'Created',
  CATEGORY = 'Category',
  // VENDOR = 'Vendor',
  // WHAREHOUSE = 'Wharehouse',
  // LOCATION = 'Location',
  BIN = 'Bin',
  WEIGHT = 'Weight',
  WEIGHT_UNIT = 'Weight Unit',
  WIDTH = 'Width',
  LENGTH = 'Length',
  HEIGHT = 'Height',
  SIZE_UNIT = 'Size Unit',
  ACTIVE = 'Active',
  TEMPLATE = 'Template',
  LISTINGS = 'Listings',
  READY = 'Status',
}

const defaultColumns = [
  'Image',
  'SKU',
  // 'Id',
  'Template',
  'Quantity',
  'Sold',
  'Cost',
  'Price',
  // 'Ready',
]

export default function Products(): JSX.Element {
  const history = useHistory()
  const isMounted = useRef(true)

  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const [loading, setLoading] = useState<boolean>(true)

  let querySearch: Record<string, string> | undefined = undefined
  let queryObject: SearchProductsQuery | undefined = undefined
  const urlSearch = new URLSearchParams(useLocation().search).toString()
  if (urlSearch) {
    const searchParams = new URLSearchParams(urlSearch)
    const queryObj: Record<string, string> = {}
    for (const [key, value] of searchParams) {
      if (value === '') continue
      queryObj[key] = value
    }
    querySearch = queryObj
    queryObject = queryObj as unknown as SearchProductsQuery
  }

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [products, setProducts] = useState<GetProduct[]>([])
  const [columns, setColumns] = useState<string[]>([])

  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState<boolean>(false)

  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(25)

  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [actionModalOpen, setActionModalOpen] = useState<boolean>(false)
  const [addTags, setAddTags] = useState<string[]>([])
  const [removeTags, setRemoveTags] = useState<string[]>([])

  // Sort By
  const [sortBy, setSortBy] = useState<string>(PRODUCT_COLUMNS_ENUM.CREATED)
  const [orderBy, setOrderBy] = useState<string>('Descending')

  // Price
  const [minPrice, setMinPrice] = useState<number | undefined>(
    queryObject?.minPrice ? parseFloat(queryObject?.minPrice) : undefined,
  )
  const [maxPrice, setMaxPrice] = useState<number | undefined>(
    queryObject?.maxPrice ? parseFloat(queryObject?.maxPrice) : undefined,
  )

  // Sold
  const [minSold] = useState<number | undefined>(
    queryObject?.minSold ? parseInt(queryObject?.minSold) : undefined,
  )
  const [maxSold] = useState<number | undefined>(
    queryObject?.maxSold ? parseInt(queryObject?.maxSold) : undefined,
  )

  // Quantity
  const [minQuantity, setMinQuantity] = useState<number | undefined>(
    queryObject?.minQuantity ? parseInt(queryObject?.minQuantity) : undefined,
  )
  const [maxQuantity, setMaxQuantity] = useState<number | undefined>(
    queryObject?.maxQuantity ? parseInt(queryObject?.maxQuantity) : undefined,
  )

  // Skus
  const [skus, setSkus] = useState<string[]>(
    queryObject?.skus ? queryObject.skus.split('+') : [],
  )
  const [availableSkus, setAvailableSkus] = useState<string[]>([])

  // Templates
  const [templates, setTemplates] = useState<string[]>()
  const [availableTemplates, setAvailableTemplates] = useState<
    { name: string; id: number }[]
  >([])

  const [search, setSearch] = useState<string>(
    queryObject?.search?.split('+').join(' ') || '',
  )
  // const [searchDescription] = useState<boolean>(
  //   // parseBoolean(queryObject?.searchDescription) || true,
  //   true,
  // )

  const [availableTags, setAvailableTags] = useState<string[]>([])
  const [hasTags, setHasTags] = useState<string[]>(
    queryObject?.hasTags ? queryObject.hasTags.split('+') : [],
  )
  const [missingTags, setMissingTags] = useState<string[]>(
    queryObject?.missingTags ? queryObject.missingTags.split('+') : [],
  )

  const [listedOn, setListedOn] = useState<string[]>(
    queryObject?.listedOn ? queryObject.listedOn.split('+') : [],
  )
  const [notListedOn, setNotListedOn] = useState<string[]>(
    queryObject?.notListedOn ? queryObject.notListedOn.split('+') : [],
  )

  // TODO: Get available listed on from reliable source
  const [availableListedOn] = useState<string[]>([
    'Ebay',
    'BigCommerce',
    'Shopify',
    'Square',
    'Clover',
    'CSV',
  ])

  const [matchAllAttributes, setMatchAllAttributes] = useState<boolean>(
    parseBoolean(queryObject?.matchAllAttributes),
  )
  const [matchAllListings, setMatchAllListings] = useState<boolean>(
    parseBoolean(queryObject?.matchAllListings),
  )

  const [editProducts] = useState<boolean>(false)

  const attibuteArray = queryObject?.attributes
    ? queryObject?.attributes.split('+')
    : []
  const attributeValuesFromStr = attibuteArray
    .map((a) => {
      const [name, action, valuesString] = a.split(';')
      if (!name) return undefined
      return {
        name,
        action,
        values: valuesString?.split(',') || [],
      }
    })
    .filter(isDefined)
  const [attributeValues, setAttributeValues] = useState<
    { name: string; action?: string; values: string[] }[]
  >(
    attributeValuesFromStr?.length
      ? attributeValuesFromStr
      : [
          {
            name: '',
            values: [],
          },
        ],
  )
  const [availableAttributes, setAvailableAttributes] = useState<string[]>([])
  const [availableAttributeValues, setAvailableAttributeValues] = useState<{
    [k: string]: string[]
  }>({})

  const [csvIntegrationIcons, setCSVIntegrationIcons] = useState<{
    [k: string]: string
  }>({})

  const selectableAttributes = availableAttributes
  // .filter(
  //   (availableAttribute) =>
  //     !attributeValues.some(
  //       (attributeValue) => attributeValue.name === availableAttribute,
  //     ),
  // )

  const handleActionClick = (action: string) => {
    if (action === 'Apply Tags') {
      setActionModalOpen(true)
    }
  }

  const handleSetTags = () => {
    if (!selectedIds.length) return
    applyTags({ productIds: selectedIds, addTags, removeTags })
      .then((res) => {
        setActionModalOpen(false)
        handleAlert(setAlert, res, 'Applied Tags')
      })
      .catch((e) => err(e))
    //
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
    handleSearchProducts(newPage, pageSize)
  }
  const handlePageSizeChange = (newPageSize: number) => {
    setPage(0)
    setPageSize(newPageSize)
    handleSearchProducts(0, newPageSize)
  }

  const handleAttributeMenuAction = (action: string, index: number) => {
    if (action === 'Delete') {
      const newAttributeValues = attributeValues.slice()
      newAttributeValues.splice(index, 1)
      setAttributeValues(newAttributeValues)
    } else if (action === 'Negate') {
      const newAttributeValue = attributeValues[index]
      if (!newAttributeValue) return
      const newAttributeValues = attributeValues.slice()
      newAttributeValue.action =
        newAttributeValue.action !== 'not in' ? 'not in' : 'in'
      newAttributeValues[index] = newAttributeValue
      setAttributeValues(newAttributeValues)
    }
  }

  const handleAttributeChange = (
    index: number,
    name: string | undefined,
    values?: string[],
  ) => {
    if (index < 0) return
    const newAttributeValues = attributeValues.slice()

    const existingAttributeValue = attributeValues[index]
    const newAttributeValue = {
      name: name || '',
      values: values || [],
      action: existingAttributeValue?.action || 'in',
    }
    newAttributeValues[index] = newAttributeValue

    setAttributeValues(newAttributeValues)
  }

  const handleAddAttribute = () => {
    const newAttributeValues = attributeValues.concat({
      name: '',
      values: [],
    })
    setAttributeValues(newAttributeValues)
  }

  const getSearchQuery = (q?: {
    offset?: number
    limit?: number
    sortBy?: string
    orderBy?: string
  }) => {
    // return filters in a way that can be sent to server
    const templateNameIds: { [k: string]: number } = {}
    availableTemplates.forEach((t) => (templateNameIds[t.name] = t.id))
    const templateIds =
      templates
        ?.map((templateName) => templateNameIds[templateName])
        .filter(isDefined) || []

    const attributes = attributeValues
      .filter((attributeValue) => attributeValue.name)
      .map((attribute) => {
        return `${attribute.name};${
          attribute.action || 'in'
        };${attribute.values.join(',')}`
      })

    const delim = '+'
    const query: Record<string, string> = {
      offset: `${q?.offset ?? page}`,
      limit: `${q?.limit || pageSize}`,
      sortBy: q?.sortBy || sortBy,
      orderBy: (q?.orderBy || orderBy) === 'Ascending' ? 'asc' : 'desc',
    }
    if (minPrice) query.minPrice = `${minPrice}`
    if (minPrice) query.minPrice = `${minPrice}`

    if (minPrice) query.minPrice = `${minPrice}`
    if (maxPrice) query.maxPrice = `${maxPrice}`
    if (minSold) query.minSold = `${minSold}`
    if (maxSold) query.maxSold = `${maxSold}`
    if (minQuantity) query.minQuantity = `${minQuantity}`
    if (maxQuantity) query.maxQuantity = `${maxQuantity}`

    if (skus.length) query.skus = skus.join(delim)
    if (templateIds.length) query.templateIds = templateIds.join(delim)
    if (search.length) query.search = search.split(' ').join(delim)
    if (hasTags.length) query.hasTags = hasTags.join(delim)
    if (missingTags.length) query.missingTags = missingTags.join(delim)
    if (attributes.length) query.attributes = attributes.join(delim)
    if (listedOn.length) query.listedOn = listedOn.join(delim)
    if (notListedOn.length) query.notListedOn = notListedOn.join(delim)

    // if (searchDescription) query.searchDescription = `${searchDescription}`
    if (matchAllAttributes) query.matchAllAttributes = `${matchAllAttributes}`
    if (matchAllListings) query.matchAllListings = `${matchAllListings}`

    return query
  }

  const handleClearFilter = () => {
    history.push({
      pathname: '/products',
    })
    setFiltersDrawerOpen(false)
    setPage(0)
    setSortBy('Updated')
    searchProducts({})
      .then((res) => {
        if (res.success && res.data) {
          setProducts(res.data.data)
          setCount(parseInt(`${res.data.items}`))
        }
      })
      .catch((e) => err(e))
  }

  const handleSearchProducts = (offset?: number, limit?: number) => {
    setPage(0)
    const query = getSearchQuery({ offset, limit })

    const queryString = new URLSearchParams(query).toString()
    history.push({
      pathname: '/products',
      search: queryString,
    })

    searchProducts(query)
      .then((res) => {
        if (res.success && res.data) {
          setProducts(res.data.data)
          setCount(parseInt(`${res.data.items}`))
        }
      })
      .catch((e) => err(e))
  }

  // Get Filter Values
  useEffect(() => {
    // Get attributes
    getAllSkus()
      .then((res) => {
        if (res.success && res.data) {
          setAvailableSkus(res.data)
        }
      })
      .catch((e) => err(e))
    // Get tags
    getAllTags()
      .then((res) => {
        if (res.success && res.data) {
          setAvailableTags(res.data)
        }
      })
      .catch((e) => err(e))
    // Get attribute values
    getAllAttributeValues()
      .then((res) => {
        if (res.success && res.data) {
          setAvailableAttributes(Object.keys(res.data))
          setAvailableAttributeValues(res.data)
        }
      })
      .catch((e) => err(e))

    // Get CSV Sheet Icons
    getClientCSVSpreadsheetIntegrations()
      .then((res) => {
        if (res.success && res.data) {
          const newCSVIntegrationIcons: { [k: string]: string } = {}
          res.data.forEach((csvIntegration) => {
            newCSVIntegrationIcons[csvIntegration.spreadsheetId] =
              csvIntegration.image
          })

          setCSVIntegrationIcons(newCSVIntegrationIcons)
        }
      })
      .catch((e) => err(e))
    // get channels
  }, [])

  useEffect(() => {
    // Get templates
    getAllTemplates()
      .then((res) => {
        if (res.success && res.data) {
          const availableTemplates = res.data
          const selectedTemplateIds = queryObject?.templateIds
            ? queryObject.templateIds.split('+')
            : []

          const selectedTemplates = availableTemplates.filter((t) =>
            selectedTemplateIds.includes(`${t.id}`),
          )
          const templateNames = selectedTemplates.map((t) => t.name)
          setTemplates(templateNames)
          setAvailableTemplates(availableTemplates)
        }
      })
      .catch((e) => err(e))
  }, [queryObject?.templateIds])

  /* Get Product Types */
  // useEffect(() => {
  //   if (isMounted.current) {
  //     getProducts()
  //       .then((res) => setProducts(res.data || []))
  //       .catch((e) => err(e))
  //     return () => {
  //       isMounted.current = false
  //     }
  //   }
  // })
  useEffect(() => {
    if (isMounted.current) {
      const query =
        querySearch || getSearchQuery({ offset: page, limit: pageSize })

      setLoading(true)
      searchProducts(query)
        .then((res) => {
          setLoading(false)

          if (res.success && res.data) {
            setProducts(res.data.data)
            setCount(parseInt(`${res.data.items}`))
          }
        })
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Get Inventory Columns */
  useEffect(() => {
    if (isMounted.current) {
      getInventoryColumns()
        .then((res) => {
          if (res.success && res.data) {
            setColumns(split(res.data.columns, ','))
          } else {
            log(res.message)
          }
        })
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  })

  const handleColumnsChange = async (values: string[]) => {
    setColumns(values)
    void handleSetInventoryColumns(values)
  }

  const handleRowSelection = (selectedIds: number[]) => {
    setSelectedIds(selectedIds)
  }

  const handleHeaderClick = (header: string, asc: boolean) => {
    const orderBy = asc ? 'Ascending' : 'Descending'
    setSortBy(header)
    setOrderBy(orderBy)
    const query = getSearchQuery({ sortBy: header, orderBy })
    searchProducts(query)
      .then((res) => {
        if (res.success && res.data) {
          setProducts(res.data.data)
          setCount(parseInt(`${res.data.items}`))
        }
      })
      .catch((e) => err(e))
  }

  const handleAddProduct = () => history.push('/addProduct')
  const handleProductClick = (productId: number) => {
    if (editProducts) return
    history.push(`/product/${productId}`)
  }
  const handleSetInventoryColumns = async (
    columns: string[],
  ): Promise<void> => {
    await setInventoryColumns(undefined, columns)
  }

  const trailingDefaultColumn = [
    PRODUCT_COLUMNS_ENUM.LISTINGS,
    PRODUCT_COLUMNS_ENUM.CREATED,
    PRODUCT_COLUMNS_ENUM.READY,
  ]
  const allColumns = unique(
    defaultColumns.concat(columns).concat(trailingDefaultColumn),
  )

  const sortableColumns = removeElements(allColumns, [
    'Status',
    PRODUCT_COLUMNS_ENUM.IMAGE,
  ])

  const handleProductUpdate = (
    product: GetProduct,
    column: string,
    value: string,
    isAttribute?: boolean,
  ) => {
    // no op
    // const newProducts = products.slice()
    // const productIndex = newProducts.findIndex(
    //   (p) => p.product.id === product.product.id,
    // )
    // if (productIndex === -1) return
    // const updateProduct = products[productIndex]
    // if (!updateProduct) return
    // if (!isAttribute) {
    //   // eslint-disable-next-line prettier/prettier
    //   (updateProduct.product as any)[column] = value
    // } else {
    //   const attrIndex = product.attributes.findIndex(
    //     (a) => a.templateAttribute.name === column,
    //   )
    //   if (attrIndex === -1) return
    //   const attr = product.attributes[attrIndex]
    //   if (!attr?.attribute) return
    //   attr.attribute.value = value
    //   updateProduct.attributes[attrIndex] = attr
    // }
    // // update array
    // newProducts[productIndex] = updateProduct
    // setProducts(newProducts)
    // console.log(column, value)
  }

  const getColumnValues = (
    getProduct: GetProduct,
  ): (JSX.Element | string)[] => {
    const { product, template, attributes, readiness, listedOn } = getProduct
    return allColumns.map((column) => {
      if (column === PRODUCT_COLUMNS_ENUM.READY) {
        if (!readiness) return ''
        if (readiness === 'ready') {
          return (
            <Tooltip
              title="Has Title, Description, and Required Attributes"
              key={`product-${product.id}-ready-icon`}
              sx={{ mt: 0.2 }}
            >
              <CheckCircleOutlineIcon
                color="success"
                sx={{ opacity: 0.8 }}
                fontSize="small"
              />
            </Tooltip>
          )
        } else if (readiness === 'partial') {
          return (
            <Tooltip
              title="Missing Recommended Attributes"
              key={`product-${product.id}-warn-icon`}
            >
              <InfoOutlinedIcon
                color="info"
                sx={{ opacity: 0.8 }}
                fontSize="small"
              />
            </Tooltip>
          )
        } else if (readiness === 'error') {
          return (
            <Tooltip
              title="Missing Title, Description, or Required Attributes"
              key={`product-${product.id}-err-icon`}
            >
              <InfoOutlinedIcon color="warning" fontSize="small" />
            </Tooltip>
          )
        }
      }

      if (column === PRODUCT_COLUMNS_ENUM.IMAGE) {
        const name =
          (getProduct.product.title || getProduct.template.name).replace(
            /\W/g,
            '',
          )?.[0] || '-'
        return (
          <Avatar
            key={getProduct.images?.[0]?.url}
            src={getProduct.images?.[0]?.url}
            alt="No Image"
            sx={{ width: 35, height: 35 }}
          >
            {name}
          </Avatar>
        )
      }
      if (column === PRODUCT_COLUMNS_ENUM.CREATED) {
        return formatDate(product[PRODUCT_FIELD_NAMES['Created'] || 'id'])
      }
      // column is product key
      const columnKey = Object.keys(PRODUCT_FIELD_NAMES).find(
        (key) => key === column,
      )
      if (columnKey && column === PRODUCT_COLUMNS_ENUM.TITLE) {
        const value = product[PRODUCT_FIELD_NAMES[columnKey] || 'id'] + ''
        return parseTitle(value, getProduct)
      }

      if (columnKey && column === PRODUCT_COLUMNS_ENUM.DESCRIPTION) {
        const value = product[PRODUCT_FIELD_NAMES[columnKey] || 'id'] + ''
        return parseDescription(value, getProduct)
      }

      if (column === PRODUCT_COLUMNS_ENUM.TEMPLATE) {
        return template.name
      }
      if (column === PRODUCT_COLUMNS_ENUM.ID) {
        return `${product.id}`
      }

      if (column === PRODUCT_COLUMNS_ENUM.TAGS) {
        return product.tags ? product.tags.split(',').join(', ') : '-'
      }

      if (column === PRODUCT_COLUMNS_ENUM.LISTINGS) {
        return listedOn?.join(', ') || ''
      }

      const productValue = columnKey
        ? `${product[PRODUCT_FIELD_NAMES[columnKey] || 'id']}`
        : undefined

      const hasAttribute = attributes.find(
        (attr) => attr.templateAttribute.name === column,
      )
      const attributeValue = hasAttribute?.attribute?.value
      // return product key or attribute value.

      if (columnKey && editProducts && productValue !== undefined) {
        return (
          <Input
            key={`edit-product-${product.id}-value-${columnKey}`}
            size="sm"
            defaultValue={productValue}
            onChange={(e) =>
              handleProductUpdate(getProduct, columnKey, e.target.value)
            }
          />
        )
      }

      if (column && editProducts && hasAttribute) {
        return (
          <Input
            key={`edit-product-${product.id}-attribute-${columnKey}`}
            defaultValue={attributeValue}
            onBlur={(e) =>
              handleProductUpdate(getProduct, column, e.target.value, true)
            }
          />
        )
      }

      return productValue || attributeValue || '-'
    })
  }

  const renderRow = (params: GridRenderCellParams) => {
    const column = params.field
    const value = params.value
    const id = params.id
    if (column === PRODUCT_COLUMNS_ENUM.READY) {
      if (!value) return ''
      if (value === 'ready') {
        return (
          <Grid container justifyContent="center">
            <Grid item sx={{ mt: 0.75 }}>
              <Tooltip title="Has Title, Description, and Required Attributes">
                <CheckCircleOutlineIcon
                  color="success"
                  sx={{ opacity: 0.8 }}
                  fontSize="small"
                />
              </Tooltip>
            </Grid>
          </Grid>
        )
      } else if (value === 'partial') {
        return (
          <Grid container justifyContent="center">
            <Grid item sx={{ mt: 0.75 }}>
              <Tooltip title="Missing Recommended Attributes">
                <InfoOutlinedIcon
                  color="info"
                  sx={{ opacity: 0.8 }}
                  fontSize="small"
                />
              </Tooltip>
            </Grid>
          </Grid>
        )
      } else if (value === 'error') {
        return (
          <Grid container justifyContent="center">
            <Grid item sx={{ mt: 0.75 }}>
              <Tooltip title="Missing Title, Description, or Required Attributes">
                <InfoOutlinedIcon color="disabled" fontSize="small" />
              </Tooltip>
            </Grid>
          </Grid>
        )
      }
    }

    if (column === PRODUCT_COLUMNS_ENUM.IMAGE) {
      if (value.match('http[s]?://')) {
        return (
          <Avatar
            src={value}
            alt="No Image"
            sx={{
              width: 35,
              height: 35,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            {value}
          </Avatar>
        )
      } else {
        return (
          <Avatar
            alt="No Image"
            sx={{
              width: 35,
              height: 35,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            {value}
          </Avatar>
        )
      }
    }
    if (column === PRODUCT_COLUMNS_ENUM.CREATED) {
      return formatDate(value)
    }
    // column is product key
    const columnKey = Object.keys(PRODUCT_FIELD_NAMES).find(
      (key) => key === column,
    )
    if (
      columnKey &&
      (column === PRODUCT_COLUMNS_ENUM.TITLE ||
        column === PRODUCT_COLUMNS_ENUM.DESCRIPTION)
    ) {
      return value
    }

    if (column === PRODUCT_COLUMNS_ENUM.SKU) {
      return <Link to={`/product/${id}`}>{value}</Link>
    }
    if (column === PRODUCT_COLUMNS_ENUM.TEMPLATE) {
      return value
    }
    if (column === PRODUCT_COLUMNS_ENUM.ID) {
      return value
    }

    if (column === PRODUCT_COLUMNS_ENUM.TAGS) {
      return value ? value.split(',').join(', ') : '-'
    }

    if (column === PRODUCT_COLUMNS_ENUM.LISTINGS) {
      const channels = split(value, ',')

      if (channels.length) {
        return (
          <Grid container justifyContent="center">
            <Grid item>
              <AvatarGroup max={8} spacing="medium">
                {channels.map((channel) => {
                  // channel icon, csv_spreadsheetId.png
                  // then render iconimg, fetch all csv icon images somwhere
                  const [channelName, externalId] = channel.split(/_(.*)/)

                  let iconSrc = `/channel_icons/${channelName?.toLowerCase()}.png`
                  if (
                    channelName === IntegrationName.CSV &&
                    externalId &&
                    csvIntegrationIcons[externalId]
                  ) {
                    iconSrc = csvIntegrationIcons[externalId] || iconSrc
                  }

                  return (
                    <Avatar
                      style={{
                        border: '1px solid lightgray',
                      }}
                      sx={{
                        width: 24,
                        height: 24,
                        backgroundColor: 'white',
                        mt: 1.5,
                      }}
                      key={channel}
                      alt={channel}
                      src={iconSrc}
                    />
                  )
                })}
              </AvatarGroup>
            </Grid>
          </Grid>
        )
      }
      return '-'
    }

    return value || '-'
  }

  const getColumnValueData = (
    getProduct: GetProduct,
  ): { [k: string]: string } => {
    const { product, template, attributes, readiness, listedOn } = getProduct

    const valuesObject: { [k: string]: string } = {}
    allColumns.forEach((column): { [k: string]: string } | undefined => {
      if (column === PRODUCT_COLUMNS_ENUM.READY) {
        valuesObject[PRODUCT_COLUMNS_ENUM.READY] = readiness || ''
      }

      if (column === PRODUCT_COLUMNS_ENUM.IMAGE) {
        const name =
          (getProduct.product.title || getProduct.template.name).replace(
            /\W/g,
            '',
          )?.[0] || '-'
        valuesObject[PRODUCT_COLUMNS_ENUM.IMAGE] =
          getProduct.images?.[0]?.url || name
      }
      if (column === PRODUCT_COLUMNS_ENUM.CREATED) {
        valuesObject[PRODUCT_COLUMNS_ENUM.CREATED] = formatDate(
          product[PRODUCT_FIELD_NAMES['Created'] || 'id'],
        )
      }
      // column is product key
      const columnKey = Object.keys(PRODUCT_FIELD_NAMES).find(
        (key) => key === column,
      )
      if (columnKey && column === PRODUCT_COLUMNS_ENUM.TITLE) {
        const value = product[PRODUCT_FIELD_NAMES[columnKey] || 'id'] + ''
        valuesObject[PRODUCT_COLUMNS_ENUM.TITLE] = parseTitle(value, getProduct)
      }

      if (columnKey && column === PRODUCT_COLUMNS_ENUM.DESCRIPTION) {
        const value = product[PRODUCT_FIELD_NAMES[columnKey] || 'id'] + ''
        valuesObject[PRODUCT_COLUMNS_ENUM.DESCRIPTION] = parseDescription(
          value,
          getProduct,
        )
      }

      if (column === PRODUCT_COLUMNS_ENUM.TEMPLATE) {
        valuesObject[PRODUCT_COLUMNS_ENUM.TEMPLATE] = template.name
      }
      if (column === PRODUCT_COLUMNS_ENUM.ID) {
        valuesObject[PRODUCT_COLUMNS_ENUM.ID] = `${product.id}`
      }

      if (column === PRODUCT_COLUMNS_ENUM.TAGS) {
        valuesObject[PRODUCT_COLUMNS_ENUM.TAGS] = product.tags
          ? product.tags.split(',').join(', ')
          : '-'
      }

      if (column === PRODUCT_COLUMNS_ENUM.LISTINGS) {
        valuesObject[PRODUCT_COLUMNS_ENUM.LISTINGS] = listedOn?.join(', ') || ''
      }

      const productValue = columnKey
        ? `${product[PRODUCT_FIELD_NAMES[columnKey] || 'id']}`
        : undefined

      const hasAttribute = attributes.find(
        (attr) => attr.templateAttribute.name === column,
      )
      const attributeValue = hasAttribute?.attribute?.value
      // return product key or attribute value.

      if (columnKey) {
        valuesObject[columnKey] = productValue || attributeValue || '-'
      }
      return undefined
    })

    if (!valuesObject.id) {
      valuesObject['id'] = `${product.id}`
    }
    return valuesObject
  }

  return (
    <div
      id="bg"
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container maxWidth={'xl'}>
        <Grid container sx={{ mt: 1 }} spacing={3}>
          <Grid item xs={12}>
            <Component>
              <Grid
                container
                spacing={1}
                sx={{
                  px: md ? 2 : 0,
                }}
              >
                <Grid item xs={12} sx={{ position: 'relative', mb: 2 }}>
                  <Typography level="h3">
                    <strong>Products</strong>
                    <Fab
                      color="primary"
                      size="small"
                      sx={{ position: 'absolute', right: 0 }}
                      onClick={handleAddProduct}
                    >
                      <AddIcon />
                    </Fab>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <MultipleSelect
                        placeholder="Display Columns"
                        value={columns}
                        freeSolo={true}
                        options={unique(
                          (
                            Object.values(PRODUCT_COLUMNS_ENUM) as string[]
                          ).concat(availableAttributes),
                        )}
                        onChange={(values) => handleColumnsChange(values)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={1}>
                    <Grid item xs={12}>
                      <FloatLabelInput
                        fullWidth={true}
                        label="Search"
                        placeholder="Search Keywords"
                        value={search}
                        onChange={(value) => setSearch(value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSearchProducts()
                          }
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={2}>
                      <Grid container justifyContent="flex-end">
                        <FormControlLabel
                          style={{ marginTop: '0.5em' }}
                          label={
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              Search Description
                            </Typography>
                          }
                          value="end"
                          control={
                            <Switch
                              size="small"
                              color="primary"
                              checked={searchDescription}
                              onChange={() =>
                                setSearchDescription(!searchDescription)
                              }
                            />
                          }
                          labelPlacement="end"
                        />
                      </Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <VerticalIconMenu
                            size="small"
                            options={['Apply Tags', 'Export CSV']}
                            disabledOptions={
                              selectedIds.length
                                ? ['Export CSV']
                                : ['Apply Tags', 'Export CSV']
                            }
                            onClick={handleActionClick}
                            p={0}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            level="title-md"
                            sx={{ cursor: 'pointer', pt: 0.75 }}
                            onClick={() =>
                              setFiltersDrawerOpen(!filtersDrawerOpen)
                            }
                          >
                            <strong>Filters</strong>
                            <ExpandMore expand={filtersDrawerOpen}>
                              <ExpandMoreIcon />
                            </ExpandMore>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                          <Button
                            size="md"
                            variant="plain"
                            onClick={handleClearFilter}
                          >
                            Clear
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="solid"
                            onClick={() => handleSearchProducts(0)}
                            size="md"
                          >
                            Search
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <FormControlLabel
                        label={
                          <Typography variant="subtitle2" color="textSecondary">
                            Edit
                          </Typography>
                        }
                        value="end"
                        control={
                          <Switch
                            size="small"
                            color="primary"
                            checked={editProducts}
                            onChange={() => setEditProducts(!editProducts)}
                          />
                        }
                        labelPlacement="end"
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
                <Grid item xs={12}>
                  <Collapse
                    orientation="vertical"
                    in={filtersDrawerOpen}
                    sx={{ pb: 2 }}
                  >
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={2}>
                            <SingleSelect
                              floatLabel={'Sort By'}
                              key={`select-sortby-${sortBy}`}
                              placeholder="Sort By"
                              freeSolo={false}
                              defaultOption={false}
                              value={sortBy || PRODUCT_COLUMNS_ENUM.CREATED}
                              options={sortableColumns}
                              onChange={(sortBy) =>
                                setSortBy(
                                  sortBy || PRODUCT_COLUMNS_ENUM.CREATED,
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <SingleSelect
                              floatLabel={'Order By'}
                              placeholder="Order By"
                              freeSolo={false}
                              defaultOption={false}
                              value={orderBy || 'Descending'}
                              options={['Ascending', 'Descending']}
                              onChange={(orderBy) =>
                                setOrderBy(orderBy || 'Descending')
                              }
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FloatLabelInput
                              label="Min Price"
                              placeholder="Min Price"
                              type="number"
                              fullWidth={true}
                              value={minPrice ?? ''}
                              onChange={(v) => setMinPrice(parseFloat(v))}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FloatLabelInput
                              label="Max Price"
                              placeholder="Max Price"
                              type="number"
                              fullWidth={true}
                              value={maxPrice ?? ''}
                              onChange={(v) => setMaxPrice(parseFloat(v))}
                            />
                          </Grid>
                          {/* <Grid item sm={4} xs={12}>
                            <Grid container justifyContent="center" spacing={1}>
                              <Grid item xs={12}>
                                <Typography>
                                  <strong>Sold</strong>
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Input
                                  label="Min"
                                  size="small"
                                  type="number"
                                  value={minSold ?? ''}
                                  onChange={(e) =>
                                    setMinSold(parseFloat(e.target.value))
                                  }
                                ></Input>
                              </Grid>
                              <Grid item xs={6}>
                                <Input
                                  label="Max"
                                  size="small"
                                  type="number"
                                  value={maxSold ?? ''}
                                  onChange={(e) =>
                                    setMaxSold(parseFloat(e.target.value))
                                  }
                                ></Input>
                              </Grid>
                            </Grid>
                          </Grid> */}
                          <Grid item xs={2}>
                            <FloatLabelInput
                              label="Min Quantity"
                              placeholder="Min Quantity"
                              type="number"
                              fullWidth={true}
                              value={minQuantity ?? ''}
                              onChange={(v) => setMinQuantity(parseFloat(v))}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FloatLabelInput
                              label="Max Quantity"
                              placeholder="Max Quantity"
                              type="number"
                              fullWidth={true}
                              value={maxQuantity ?? ''}
                              onChange={(v) => setMaxQuantity(parseFloat(v))}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <MultipleSelect
                          value={skus || []}
                          placeholder="SKU"
                          options={availableSkus}
                          onChange={setSkus}
                          noSpacedInputs={true}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MultipleSelect
                          value={templates || []}
                          placeholder="Template"
                          options={availableTemplates.map((t) => t.name)}
                          onChange={setTemplates}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MultipleSelect
                          value={hasTags || []}
                          placeholder="Has Tags"
                          options={availableTags}
                          onChange={setHasTags}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MultipleSelect
                          value={missingTags || []}
                          placeholder="Missing Tags"
                          options={availableTags}
                          onChange={setMissingTags}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <MultipleSelect
                              value={listedOn || []}
                              placeholder="Listed On"
                              options={availableListedOn}
                              onChange={setListedOn}
                              limitTags={4}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <MultipleSelect
                              value={notListedOn || []}
                              placeholder="Not Listed On"
                              options={availableListedOn}
                              onChange={setNotListedOn}
                              limitTags={4}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ marginTop: '-12px' }}>
                            <Grid container justifyContent="flex-end">
                              <Typography
                                level="title-sm"
                                component="label"
                                endDecorator={
                                  <Switch
                                    checked={matchAllListings}
                                    onChange={() =>
                                      setMatchAllListings(!matchAllListings)
                                    }
                                  />
                                }
                              >
                                <strong>Match All</strong>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1}>
                          {attributeValues.map((attributeValue, index) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                key={attributeValue.name + index}
                              >
                                <Grid
                                  container
                                  justifyContent="center"
                                  spacing={2}
                                >
                                  <Grid item sm={6} xs={12}>
                                    <SingleSelect
                                      placeholder="Attribute"
                                      freeSolo={false}
                                      value={attributeValue.name}
                                      options={selectableAttributes}
                                      onChange={(value) =>
                                        handleAttributeChange(index, value)
                                      }
                                    />
                                  </Grid>
                                  <Grid item sm={6} xs={12}>
                                    <Grid container justifyContent="center">
                                      <Grid item xs={12}>
                                        <MultipleSelect
                                          freeSolo={true}
                                          placeholder={
                                            attributeValue.action !== 'not in'
                                              ? 'Attribute Values'
                                              : 'Not Attribute Values'
                                          }
                                          value={attributeValue.values}
                                          options={
                                            availableAttributeValues[
                                              attributeValue.name
                                            ]
                                          }
                                          onChange={(values) =>
                                            handleAttributeChange(
                                              index,
                                              attributeValue.name,
                                              values,
                                            )
                                          }
                                          endDecorator={
                                            <VerticalIconMenu
                                              size="small"
                                              onClick={(action) =>
                                                handleAttributeMenuAction(
                                                  action,
                                                  index,
                                                )
                                              }
                                              options={['Delete', 'Negate']}
                                            />
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          })}
                          <Grid item xs={12} sx={{ marginTop: '-4px' }}>
                            <Grid
                              container
                              justifyContent="flex-end"
                              alignContent="center"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography
                                  level="title-sm"
                                  component="label"
                                  endDecorator={
                                    <Switch
                                      checked={matchAllAttributes}
                                      onChange={() =>
                                        setMatchAllAttributes(
                                          !matchAllAttributes,
                                        )
                                      }
                                    />
                                  }
                                >
                                  <strong>Match All</strong>
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={handleAddAttribute}
                                  variant="soft"
                                  size="sm"
                                  color="neutral"
                                  endDecorator={<Add fontSize="small" />}
                                >
                                  Add Attribute
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  {/* <PaginatedTable
                    dataGrid={true}
                    key={`paginated-table-page-${page}-${sortBy}-${orderBy}`}
                    data={products}
                    head={allColumns}
                    sortable={true}
                    sortableHeaders={sortableColumns}
                    sortBy={sortBy}
                    orderBy={orderBy === 'Ascending' ? 'asc' : 'desc'}
                    row={(product) => getColumnValues(product)}
                    rowData={(product) => getColumnValueData(product)}
                    renderRow={renderRow}
                    onRowClick={(row) => handleProductClick(row.product.id)}
                    onHeaderClick={handleHeaderClick}
                    handleRowSelection={handleRowSelection}
                    count={count}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                  ></PaginatedTable> */}

                  {loading ? (
                    // <Skeleton
                    //   variant="rectangular"
                    //   width={'100%'}
                    //   height={'250px'}
                    // />
                    <Box
                      height={'450px'}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center' /* Horizontal centering */,
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress size="lg" />
                      {/* <LinearProgress /> */}
                    </Box>
                  ) : (
                    <PaginatedDataTable
                      data={products}
                      head={allColumns}
                      sortable={true}
                      sortableHeaders={sortableColumns}
                      sortBy={sortBy}
                      orderBy={orderBy === 'Ascending' ? 'asc' : 'desc'}
                      row={(product) => getColumnValues(product)}
                      rowData={(product) => getColumnValueData(product)}
                      renderRow={renderRow}
                      onRowClick={(rowId) => {
                        handleProductClick(rowId)
                      }}
                      onHeaderClick={handleHeaderClick}
                      handleRowSelection={handleRowSelection}
                      count={count}
                      page={page}
                      pageSize={pageSize}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                    ></PaginatedDataTable>
                  )}
                </Grid>
              </Grid>
            </Component>
          </Grid>
        </Grid>
        <Box m={50}></Box>
        <Modal
          open={actionModalOpen}
          title="Apply Tags"
          onClose={() => setActionModalOpen(false)}
          onSubmit={() => handleSetTags()}
        >
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
              <MultipleSelect
                value={addTags}
                onChange={setAddTags}
                label="Add Tags"
                options={availableTags}
                freeSolo={true}
              />
            </Grid>
            <Grid item xs={12}>
              <MultipleSelect
                label="Remove Tags"
                value={removeTags}
                onChange={setRemoveTags}
                options={availableTags}
                freeSolo={false}
              />
            </Grid>
          </Grid>
        </Modal>
        <Alert
          alert={alert}
          onClose={() => setAlert({ ...alert, open: false })}
        ></Alert>
      </Container>
    </div>
  )
}
