import React, { useEffect, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  AspectMetadata,
  CategoryTree,
  EbayAttributeAspectValues,
  EbayProductAspectIntegration,
  EbayProductIntegration,
  EbayTemplateAspectIntegration,
  EbayTemplateIntegration,
  EbayValueMapping,
  FulfillmentPolicy,
  InventoryLocation,
  OfferResponse,
  PaymentPolicy,
  ReturnPolicy,
  SetEbayProductAspectIntegration,
} from '../../../../types/Ebay.types'
import {
  arrayToRecord,
  err,
  getAttributeTag,
  isDefined,
  log,
} from '../../../../utils/functions'
import {
  GetProduct,
  getProductAspectsForCategory,
  GetProductTemplate,
} from '../../../../api/product'
import { DataFields } from '../../../../api/types'

import EbayCategories, { EbayCategory } from './EbayCategories'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import EbayIntegrationConditions from '../../ConditionsInput'
import ItemAspects from './ItemAspects'
import EbayProductAspects from './EbayProductAspects'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import NamedInput from '../../../common/NamedInput'
import AttributeSelect from '../../../common/AttributeSelect'
import Button from '@mui/joy/Button'
import Box from '@mui/joy/Box'
import ArrowBack from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/joy/IconButton'
import Tooltip from '@mui/joy/Tooltip'
import ListSyncComponent from '../common/ListSyncComponent'
import ValueMappingModal from '../common/ValueMappingModal'
import { getEtsyAttributePropertyValues } from '../../../../api/integrations/etsy'
import SingleSelect from '../../../common/SingleSelect'
import ListUnlistButtons from '../common/ListUnlistButtons'
import {
  IntegrationName,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import EbayListingConfigurationsComponent from './EbayListingConfigurationsComponent'

export type AspectIntegrationInput = Omit<
  DataFields<EbayTemplateAspectIntegration>,
  'ebayTemplateIntegrationId' | 'type'
>

interface EbayIntegrationConfigurationComponentProps {
  template: GetProductTemplate
  templateIntegration: EbayTemplateIntegration

  productIntegration?: EbayProductIntegration
  product?: GetProduct

  count?: number
  categories: CategoryTree
  locations: InventoryLocation[]
  paymentPolicies: PaymentPolicy[]
  returnPolicies: ReturnPolicy[]
  fulfillmentPolicies: FulfillmentPolicy[]
  onTemplateChange?: (
    name: string,
    templateIntegration: EbayTemplateIntegration | undefined,
  ) => void
  onProductChange?: (
    name: string,
    productIntegration: EbayProductIntegration | undefined,
  ) => void
  onUpdate?: () => void
}
export default function EbayIntegrationConfigurationComponent({
  template,
  templateIntegration,
  productIntegration,
  product,
  categories,
  locations,
  paymentPolicies,
  returnPolicies,
  fulfillmentPolicies,
  count,
  onTemplateChange,
  onProductChange,
  onUpdate,
}: EbayIntegrationConfigurationComponentProps): JSX.Element {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [category, setCategory] = useState<EbayCategory | undefined>(
    templateIntegration.ebayCategoryId && templateIntegration.ebayCategoryName
      ? {
          id: templateIntegration.ebayCategoryId,
          name: templateIntegration.ebayCategoryName,
        }
      : undefined,
  )
  const [categoryVersion, setCategoryVersion] = useState<number>(
    templateIntegration.ebayCategoryVersion || 0,
  )
  const [productAspects, setProductAspects] = useState<
    AspectMetadata | undefined
  >()
  const [ebayTemplateAspectIntegrations, setEbayTemplateAspectIntegrations] =
    useState<EbayTemplateAspectIntegration[]>(templateIntegration.aspects ?? [])

  const [ebayProductAspectIntegrations, setEbayProductAspectIntegrations] =
    useState<DataFields<SetEbayProductAspectIntegration>[]>(
      productIntegration?.aspects?.length
        ? productIntegration?.aspects
        : templateIntegration.aspects ?? [],
    )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [title, setTitle] = useState<string | undefined>(
    productIntegration?.title ||
      product?.product?.title ||
      templateIntegration.title ||
      template.template.title ||
      '',
  )
  const [subtitle] = useState<string>(templateIntegration.subtitle || '')
  const [description, setDescription] = useState<string | undefined>(
    productIntegration?.description ||
      product?.product?.description ||
      templateIntegration.description ||
      template.template.description ||
      '',
  )
  const [brand, setBrand] = useState<string>(templateIntegration.brand)
  const [sku] = useState<string>(product?.product.sku || '')
  const [conditionPrimary, setConditionPrimary] = useState<string>(
    product?.product.condition || 'New',
  )
  const [conditionSecondary, setConditionSecondary] = useState<
    string | undefined
  >()
  const [conditionDescription, setConditionDescription] = useState<string>(
    product?.attributes?.find(
      (a) => a.templateAttribute.name === 'Condition Description',
    )?.attribute?.value || '',
  )
  const [price] = useState<number>(product?.product.price ?? 0)
  const [quantity] = useState<number>(product?.product.quantity ?? 0)

  const [list, setList] = useState<boolean>(templateIntegration?.list ?? false)
  const [sync, setSync] = useState<boolean>(templateIntegration?.sync ?? false)
  const [syncQuantity, setSyncQuantity] = useState<boolean>(
    templateIntegration?.syncQuantity ?? false,
  )

  const [detailUpdate, setDetailUpdate] = useState<number>(0)
  const templateAttributes = template?.attributes || []

  const [selectedLocation, setSelectedLocation] = useState<
    InventoryLocation | undefined
  >(
    productIntegration?.locationId
      ? locations.find(
          (l) => l.merchantLocationKey === productIntegration?.locationId,
        )
      : templateIntegration?.locationId
      ? locations.find(
          (l) => l.merchantLocationKey === templateIntegration?.locationId,
        )
      : undefined,
  )
  const [selectedPaymentPolicy, setSelectedPaymentPolicy] = useState<
    PaymentPolicy | undefined
  >(
    productIntegration?.paymentPolicyId
      ? paymentPolicies.find(
          (l) => l.paymentPolicyId === productIntegration?.paymentPolicyId,
        )
      : templateIntegration?.paymentPolicyId
      ? paymentPolicies.find(
          (l) => l.paymentPolicyId === templateIntegration?.paymentPolicyId,
        )
      : undefined,
  )
  const [selectedReturnPolicy, setSelectedReturnPolicy] = useState<
    ReturnPolicy | undefined
  >(
    productIntegration?.returnPolicyId
      ? returnPolicies.find(
          (l) => l.returnPolicyId === productIntegration?.returnPolicyId,
        )
      : templateIntegration?.returnPolicyId
      ? returnPolicies.find(
          (l) => l.returnPolicyId === templateIntegration?.returnPolicyId,
        )
      : undefined,
  )
  const [selectedFulfillmentPolicy, setSelectedFulfillmentPolicy] = useState<
    FulfillmentPolicy | undefined
  >(
    productIntegration?.fulfillmentPolicyId
      ? fulfillmentPolicies.find(
          (l) =>
            l.fulfillmentPolicyId === productIntegration?.fulfillmentPolicyId,
        )
      : templateIntegration?.fulfillmentPolicyId
      ? fulfillmentPolicies.find(
          (l) =>
            l.fulfillmentPolicyId === templateIntegration?.fulfillmentPolicyId,
        )
      : undefined,
  )

  // Ebay Template Value Mapping propertyName => { ebayValue: mapValues[] }
  const [ebayTemplateAspectValueMapping, setEbayTemplateAspectValueMapping] =
    useState<Record<string, Record<string, string[]>>>({})

  // Ebay Attribute Value Mapping applyTo: => { value: mapValues[] }
  const [ebayAttributeValueMapping, setEbayAttributeValueMapping] = useState<
    Record<string, DataFields<EbayValueMapping>[]>
  >({})

  const ebayTemplateAspectIntegrationsRecord: Record<
    string,
    EbayTemplateAspectIntegration
  > = useMemo(() => {
    return arrayToRecord(templateIntegration?.aspects, (e) => e.aspectName)
  }, [templateIntegration])

  const valueMapRecordToArray = (
    ebayTemplateAspectValueMapping: Record<string, Record<string, string[]>>,
  ): DataFields<EbayValueMapping>[] => {
    if (!templateIntegration?.integrationId) return []
    const templatePropertyValueMappings = Object.entries(
      ebayTemplateAspectValueMapping,
    )
      .map(([aspectName, valueMap]) => {
        if (valueMap === undefined) return undefined

        const templateAspectIntegration =
          ebayTemplateAspectIntegrationsRecord[aspectName]

        const templateAspectIntegrationId = templateAspectIntegration?.id
        if (!templateAspectIntegrationId || !templateIntegration?.integrationId)
          return undefined
        const valueMapping: DataFields<EbayValueMapping>[] = Object.entries(
          valueMap,
        ).map(([valueName, mapValues], i) => {
          return {
            integrationId: templateIntegration?.integrationId || 0,
            templateIntegrationId: templateIntegration.id,
            templateAspectIntegrationId: templateAspectIntegrationId,
            value: valueName,
            index: i,
            values: mapValues.map((v) => ({ value: v })),
          }
        })
        return valueMapping
      })
      .flat()
      .filter(isDefined)

    return templatePropertyValueMappings
  }

  const [attributePropertyValues, setAttributePropertyValues] = useState<
    EbayAttributeAspectValues | undefined
  >()

  useEffect(() => {
    const templateId = template.template.id
    const productId = product?.product.id
    if (!templateId) return

    getEtsyAttributePropertyValues(templateId, productId)
      .then((res) => {
        if (res.success && res.data) {
          setAttributePropertyValues(res.data)
        }
      })
      .catch((e) => err(e))
  }, [template.template.id, product?.product.id])

  useEffect(() => {
    const ebayValueMapping: DataFields<EbayValueMapping>[] | undefined =
      templateIntegration?.valueMappings
    if (!ebayValueMapping) return
    const templateAspectRecord: Record<string, Record<string, string[]>> = {}
    const attributeRecord: Record<string, DataFields<EbayValueMapping>[]> = {}

    ebayValueMapping.forEach((valueMapping) => {
      const templateIntegrationId = valueMapping.templateAspectIntegrationId
      const templatePropertyIntegration = templateIntegration?.aspects?.find(
        (p) => p.id === templateIntegrationId,
      )

      const mapValue = valueMapping.value
      const applyTo = valueMapping.applyTo
      const attributeId = valueMapping.attributeId
      const mapValues = valueMapping.values?.map((v) => v.value) || []

      // template property
      if (templatePropertyIntegration) {
        templateAspectRecord[templatePropertyIntegration.aspectName] = {
          [mapValue]: mapValues,
        }

        // global ebay property
      } else if (applyTo && attributeId) {
        attributeRecord[applyTo] = (
          (attributeRecord[applyTo] || []) as DataFields<EbayValueMapping>[]
        ).concat(valueMapping)
      }
    })

    setEbayTemplateAspectValueMapping(templateAspectRecord)
    setEbayAttributeValueMapping(attributeRecord)
    setDetailUpdate(detailUpdate + 1)
    // return templatePropertyRecord
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateIntegration?.aspects, templateIntegration?.valueMappings])

  const [selectedApplyTo, setSelectedApplyTo] = useState<string>('')

  const templateAttributesRecord = useMemo(() => {
    return arrayToRecord(template?.attributes, (e) => e.attribute.name)
  }, [template?.attributes])

  useEffect(() => {
    const templateAspectIntegrations = ebayTemplateAspectIntegrations.slice()
    productAspects?.aspects.forEach((aspect) => {
      const aspectIntegration =
        ebayTemplateAspectIntegrationsRecord[aspect.localizedAspectName]
      const attribute = templateAttributesRecord[aspect.localizedAspectName]
      if (!aspectIntegration && attribute?.attribute.name) {
        templateAspectIntegrations.push({
          aspectName: aspect.localizedAspectName,
          value: getAttributeTag(attribute?.attribute.name),
          dataType: aspect.aspectConstraint.aspectDataType,
          required: aspect.aspectConstraint.aspectRequired,
        })
      }
    })

    setEbayTemplateAspectIntegrations(templateAspectIntegrations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productAspects?.aspects])

  const handleChange = () => {
    const locationId = selectedLocation?.merchantLocationKey
    const paymentPolicyId = selectedPaymentPolicy?.paymentPolicyId
    const returnPolicyId = selectedReturnPolicy?.returnPolicyId
    const fulfillmentPolicyId = selectedFulfillmentPolicy?.fulfillmentPolicyId

    if (product && templateIntegration?.id) {
      const newProductIntegration: DataFields<EbayProductIntegration> = {
        name: templateIntegration.name,
        index: templateIntegration.index,
        productId: product.product.id,
        templateIntegrationId: templateIntegration.id,
        ebayCategoryId: category?.id || 0,
        ebayCategoryName: category?.name || '',
        ebayCategoryVersion: categoryVersion,
        title: title || '',
        subtitle,
        description: description || '',
        condition: conditionPrimary,
        conditionDescription,
        brand: brand,
        list: list,
        sync: sync,
        syncQuantity: syncQuantity,

        locationId,
        paymentPolicyId,
        returnPolicyId,
        fulfillmentPolicyId,

        aspects: ebayProductAspectIntegrations,
        selected: false,
      }

      onProductChange?.(templateIntegration.name, newProductIntegration)
    }

    if (!product) {
      const newTemplateIntegration: EbayTemplateIntegration = {
        name: templateIntegration.name,
        index: templateIntegration.index,
        integrationId: templateIntegration.integrationId,
        templateId: templateIntegration.templateId,
        ebayCategoryId: category?.id || 0,
        ebayCategoryName: category?.name || '',
        ebayCategoryVersion: categoryVersion,
        title: title || '',
        subtitle: '',
        description: description || '',
        condition: conditionPrimary,
        conditionDescription: conditionDescription,
        brand: brand,
        list: list,
        sync: sync,
        syncQuantity: syncQuantity,
        // Only update when not updating product
        locationId: locationId,
        paymentPolicyId: paymentPolicyId,
        returnPolicyId: returnPolicyId,
        fulfillmentPolicyId: fulfillmentPolicyId,
        aspects: ebayTemplateAspectIntegrations,
        valueMappings: valueMapRecordToArray(
          ebayTemplateAspectValueMapping,
        ).concat(Object.values(ebayAttributeValueMapping).flat()),
      }

      onTemplateChange?.(templateIntegration.name, newTemplateIntegration)
    } else {
      const newTemplateIntegration: EbayTemplateIntegration = {
        ...templateIntegration,
        integrationId: templateIntegration.integrationId,
        templateId: templateIntegration.templateId,

        aspects: ebayTemplateAspectIntegrations,
        valueMappings: valueMapRecordToArray(
          ebayTemplateAspectValueMapping,
        ).concat(Object.values(ebayAttributeValueMapping).flat()),
      }

      onTemplateChange?.(templateIntegration.name, newTemplateIntegration)
    }
  }

  const handleDelete = () => {
    onTemplateChange?.(templateIntegration.name, undefined)
  }

  const handleIndexChange = (newIndex: number) => {
    const newTemplateIntegration = templateIntegration
    newTemplateIntegration.index = newIndex
    onTemplateChange?.(templateIntegration.name, newTemplateIntegration)
  }

  useEffect(() => {
    handleChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    category,
    title,
    description,
    conditionPrimary,
    brand,
    list,
    sync,
    syncQuantity,
    selectedLocation,
    selectedPaymentPolicy,
    selectedReturnPolicy,
    selectedFulfillmentPolicy,
    ebayTemplateAspectIntegrations,
    ebayProductAspectIntegrations,
    ebayTemplateAspectValueMapping,
    ebayAttributeValueMapping,
  ])

  const handleSelectCategory = (
    categoryVersion: number,
    category: EbayCategory | undefined,
  ) => {
    setCategoryVersion(categoryVersion)
    setCategory(category)
  }

  const handleAspectChange = (aspect: AspectIntegrationInput) => {
    if (product) {
      // find index
      const newAspectIntegrations = ebayProductAspectIntegrations.slice()
      const indexOf = newAspectIntegrations.findIndex(
        (a) => a.aspectName === aspect.aspectName,
      )
      // if not found, insert at end
      if (indexOf === -1) {
        newAspectIntegrations.push(aspect as EbayProductAspectIntegration)
      } else {
        newAspectIntegrations[indexOf] = aspect as EbayProductAspectIntegration
      }

      // save
      console.log(newAspectIntegrations)
      setEbayProductAspectIntegrations(newAspectIntegrations)
      return
    }

    // find index
    const newAspectIntegrations = ebayTemplateAspectIntegrations.slice()
    const indexOf = newAspectIntegrations.findIndex(
      (a) => a.aspectName === aspect.aspectName,
    )
    // if not found, insert at end
    if (indexOf === -1) {
      newAspectIntegrations.push(aspect as EbayTemplateAspectIntegration)
    } else {
      newAspectIntegrations[indexOf] = aspect as EbayTemplateAspectIntegration
    }

    // save
    setEbayTemplateAspectIntegrations(newAspectIntegrations)
  }

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.EBAY, productId)
      .then((res) => {
        if (res.success) {
          setAlert({
            open: true,
            severity: 'success',
            message: 'Successfully listed product.',
          })
        } else {
          const message = res.data?.warnings?.map((e) => e.message).join(', ')
          setAlert({ open: true, severity: 'warning', message: message })
        }
      })
      .catch((e) => {
        console.log(e)
        const message = (e.response.data as OfferResponse)?.errors
          ?.map((e) => e.message)
          .join(', ')
        setAlert({
          open: true,
          severity: 'error',
          message: message || e.response.message,
        })
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!templateIntegration?.integrationId || !productId) return
    unlistProduct(
      IntegrationName.EBAY,
      productId,
      templateIntegration?.integrationId,
    )
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  useEffect(() => {
    category?.id &&
      getProductAspectsForCategory(category.id)
        .then((res) => setProductAspects(res?.data || ({} as AspectMetadata)))
        .catch((e) => log(e))
  }, [category])

  const attributeNames =
    template?.attributes.map((a) => a.attribute.name) ||
    product?.attributes.map((a) => a.templateAttribute.name)

  return (
    <Grid container justifyContent="center" spacing={6}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          {!product && (
            <Grid item xs={12}>
              <Tooltip
                title="Re-order left"
                size="sm"
                variant="soft"
                placement="right"
              >
                <IconButton
                  variant="plain"
                  size="sm"
                  sx={{ float: 'left' }}
                  disabled={!templateIntegration.index}
                  onClick={() =>
                    handleIndexChange(templateIntegration.index - 1)
                  }
                >
                  <ArrowBack fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Re-order right"
                size="sm"
                variant="soft"
                placement="left"
              >
                <IconButton
                  variant="plain"
                  size="sm"
                  sx={{ float: 'right' }}
                  disabled={
                    count !== undefined &&
                    templateIntegration.index !== undefined &&
                    templateIntegration.index >= count - 1
                  }
                  onClick={() =>
                    handleIndexChange(templateIntegration.index + 1)
                  }
                >
                  <ArrowBack fontSize="small" sx={{ rotate: '180deg' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          <Grid item xs={12}>
            <ListSyncComponent
              title={true}
              list={list}
              sync={sync}
              syncQuantity={syncQuantity}
              onListChange={setList}
              onSyncChange={setSync}
              onSyncQuantityChange={setSyncQuantity}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {/* Default Template Integration */}
        <Grid container justifyContent="center" spacing={2}>
          {/* Category */}
          <Grid item xs={12}>
            {template ? (
              <EbayCategories
                categories={categories}
                category={category}
                categoryVersion={categoryVersion}
                onChange={handleSelectCategory}
                label="Category"
              />
            ) : (
              <ProductAttributeInputText
                label="Category"
                value={
                  '' +
                  category?.name +
                  (category?.id ? ` - ${category.id}` : '')
                }
                disabled={true}
              />
            )}
          </Grid>

          {/* Title and Description */}
          <Grid item xs={12}>
            <ProductTitleDescription
              key={`product-title-description-${detailUpdate}`}
              template={template}
              product={product}
              title={title}
              description={description}
              required={!!product}
              onTitleChange={setTitle}
              onDescriptionChange={setDescription}
              disableUnparsedEdit={true}
              onAttributeClick={(attributeName, element) => {
                setSelectedApplyTo(element || '')
                setModalOpen(true)
              }}
              valueMapping={(ebayAttributeValueMapping['title'] || [])?.concat(
                ebayAttributeValueMapping['description'] || [],
              )}
            />
          </Grid>

          {/* Product Values */}
          {product && (
            <>
              <Grid item xs={12}>
                <ProductAttributeInputText
                  label="SKU"
                  value={sku}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12}>
                <NamedInput
                  name="Price"
                  nameContainer={true}
                  value={price}
                  disabled={true}
                  helperText={
                    <Typography level="body-xs">
                      Price may vary according to pricing configuration.
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <NamedInput
                  name="Quantity"
                  nameContainer={true}
                  value={quantity}
                  disabled={true}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <EbayIntegrationConditions
              conditionPrimary={conditionPrimary}
              conditionSecondary={conditionSecondary}
              onChange={(primary, secondary) => {
                setConditionPrimary(primary)
                setConditionSecondary(secondary)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AttributeSelect
              name="Condition Description"
              nameContainer={true}
              value={conditionDescription}
              attributeNames={attributeNames}
              multiple={false}
              onChange={(v) => setConditionDescription(v || '')}
            />
          </Grid>

          <Grid item xs={12}>
            <AttributeSelect
              name="Brand"
              nameContainer={true}
              value={brand}
              attributeNames={attributeNames}
              multiple={false}
              onChange={(v) => setBrand(v || '')}
            />
          </Grid>

          <Grid item xs={12}>
            <SingleSelect
              floatLabel="Location"
              value={
                selectedLocation?.name ||
                selectedLocation?.merchantLocationKey ||
                ''
              }
              options={locations.map(
                (l) => l.name || l.merchantLocationKey || 'No Name',
              )}
              onChange={(locationName) => {
                const location = locations.find(
                  (l) =>
                    l.name === locationName ||
                    l.merchantLocationKey === locationName,
                )
                setSelectedLocation(location)
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <SingleSelect
              floatLabel="Payment Policy"
              value={selectedPaymentPolicy?.name || ''}
              onChange={(policyName) => {
                const policy = paymentPolicies.find(
                  (p) => p.name === policyName,
                )
                setSelectedPaymentPolicy(policy)
              }}
              options={paymentPolicies.map((p) => p.name)}
            />
          </Grid>

          <Grid item xs={12}>
            <SingleSelect
              floatLabel="Return Policy"
              value={selectedReturnPolicy?.name || ''}
              onChange={(policyName) => {
                const policy = returnPolicies.find((p) => p.name === policyName)
                setSelectedReturnPolicy(policy)
              }}
              options={returnPolicies.map((p) => p.name)}
            />
          </Grid>

          <Grid item xs={12}>
            <SingleSelect
              floatLabel="Fulfillment Policy"
              value={selectedFulfillmentPolicy?.name || ''}
              onChange={(policyName) => {
                const policy = fulfillmentPolicies.find(
                  (p) => p.name === policyName,
                )
                setSelectedFulfillmentPolicy(policy)
              }}
              options={fulfillmentPolicies.map((p) => p.name)}
            />
          </Grid>

          {!product && template && (
            <Grid item xs={12} mt={8}>
              <ItemAspects
                templateIntegration={templateIntegration}
                aspects={productAspects?.aspects || []}
                aspectIntegrations={ebayTemplateAspectIntegrations}
                templateAttributes={templateAttributes}
                channelOptions={{
                  etsy: attributePropertyValues,
                }}
                onChange={handleAspectChange}
                onUpdate={onUpdate}
              />
            </Grid>
          )}

          {product && template && (
            <Grid item xs={12} mt={8}>
              <EbayProductAspects
                product={product}
                template={template}
                templateIntegration={templateIntegration}
                valueMapping={ebayTemplateAspectValueMapping}
                onValueMapChange={(valueMapping) => {
                  setEbayTemplateAspectValueMapping(valueMapping)
                }}
                aspects={productAspects?.aspects || []}
                aspectIntegrations={ebayProductAspectIntegrations}
                templateAttributes={templateAttributes}
                channelOptions={{
                  etsy: attributePropertyValues,
                }}
                disabled={true}
                onChange={handleAspectChange}
                onUpdate={onUpdate}
              />
            </Grid>
          )}

          <Grid item xs={12} mt={8}>
            <EbayListingConfigurationsComponent
              templateIntegrationId={templateIntegration?.id}
              productIntegrationId={productIntegration?.id}
              productId={product?.product?.id}
            />
          </Grid>

          <Grid item xs={12} mt={8}>
            <ListUnlistButtons
              title={true}
              list={list}
              productIntegration={productIntegration}
              product={product}
              onList={handleListProduct}
              onUnlist={handleUnlistProduct}
            />
          </Grid>

          <Grid item xs={12} mt={4}>
            <Box sx={{ display: 'flex' }}>
              <Button
                onClick={handleDelete}
                variant="soft"
                color="danger"
                sx={{ float: 'left' }}
              >
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <ValueMappingModal
        key={`value-map-modal-${selectedApplyTo}`}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        applyTo={{ title: 'Title', description: 'Description' }}
        selectedApplyTo={selectedApplyTo}
        valueMappingRecord={ebayAttributeValueMapping}
        onChange={(valueMapping) => {
          setEbayAttributeValueMapping(valueMapping)
        }}
        template={template}
        templateIntegration={templateIntegration}
      />

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
