/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import NavBar from '../components/common/NavBar'
import Container from '@mui/joy/Container'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Component from '../components/common/Component'
import Typography from '@mui/joy/Typography'
import {
  err,
  formatDate,
  formatDateTime,
  getFloat,
  isDefined,
  round,
  unique,
  wait,
} from '../utils/functions'
import {
  getIntegrations,
  GetIntegrationsResult,
  IntegrationName,
} from '../api/integration'
import { Order, OrderItem } from '../api/types'
import { getOrder, updateShipping } from '../api/order'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import ModalClose from '@mui/joy/ModalClose'
import Alert, { AlertInput, handleAlert } from '../components/common/Alert'
import Box from '@mui/joy/Box'
import { Link, useParams } from 'react-router-dom'
import { EbayOrder } from '../types/Ebay.types'
import EventIcon from '@mui/icons-material/Event'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import PaymentIcon from '@mui/icons-material/Payment'
import Chip from '@mui/joy/Chip'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemContent from '@mui/joy/ListItemContent'
import ListDivider from '@mui/joy/ListDivider'
import { ListItemAvatar, ListItemSecondaryAction } from '@mui/material'
import Avatar from '@mui/joy/Avatar'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import IconButton from '@mui/joy/IconButton'
import Tooltip from '@mui/joy/Tooltip'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import Dropdown from '@mui/joy/Dropdown'
import MenuButton from '@mui/joy/MenuButton'
import Menu from '@mui/joy/Menu'
import MenuItem from '@mui/joy/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { SquareOrder } from '../types/Square.types'
import { EtsyReceipt } from '../types/Etsy.types'
import { BigCommerceOrder } from '../types/BigCommerce.types'
import { ShopifyOrder } from '../types/Shopify.types'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import FloatLabelInput from '../components/common/FloatLabelInput'
import SingleSelect from '../components/common/SingleSelect'
import Button from '@mui/joy/Button'
import CircularProgress from '@mui/joy/CircularProgress'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import UploadIcon from '@mui/icons-material/Upload'
import { useReactToPrint } from 'react-to-print'
import ListItemDecorator from '@mui/joy/ListItemDecorator'

const markPaidChannels: string[] = [
  IntegrationName.BIG_COMMERCE,
  IntegrationName.SHOPIFY,
  IntegrationName.SHOPIFY,
]

export default function OrderPage({
  orderId: orderIdProp,
  integrations: defaultIntegrations,
  onLoaded,
  renderContent = false,
}: {
  orderId?: number
  integrations?: GetIntegrationsResult
  onLoaded?: () => void
  renderContent?: boolean
}): JSX.Element {
  const { orderId: orderIdParam } = useParams<{
    orderId?: string
  }>()

  const orderId =
    orderIdProp || (orderIdParam ? parseInt(orderIdParam) : undefined)
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  const [loading, setLoading] = useState<boolean>(true)

  const [shippingModalOpen, setShippingModalOpen] = useState<boolean>(false)

  const [printingMode, setPrintingMode] = useState<string | undefined>()

  // Shipping Address
  const [name, setName] = useState<string | undefined>()
  const [companyName, setCompanyName] = useState<string | undefined>()
  const [email, setEmail] = useState<string | undefined>()
  const [phone, setPhone] = useState<string | undefined>()
  const [addressLine1, setAddressLine1] = useState<string | undefined>()
  const [addressLine2, setAddressLine2] = useState<string | undefined>()
  const [city, setCity] = useState<string | undefined>()
  const [state, setState] = useState<string | undefined>()
  const [zip, setZip] = useState<string | undefined>()
  const [country, setCountry] = useState<string | undefined>()

  const [width, setWidth] = useState<number | undefined>()
  const [height, setHeight] = useState<number | undefined>()
  const [length, setLength] = useState<number | undefined>()
  const [weight, setWeight] = useState<number | undefined>()
  const [sizeUnit, setSizeUnit] = useState<string | undefined>()
  const [weightUnit, setWeightUnit] = useState<string | undefined>()

  // Shipping Method
  const [shippingServices, setShippingServices] = useState<string[]>([])
  const [shippingStatuses, setShippingStatuses] = useState<string[]>([])
  const [shippingService, setShippingService] = useState<string | undefined>()
  const [shippingServiceId, setShippingServiceId] = useState<
    string | undefined
  >()
  // const [shippingStatus, setShippingStatus] = useState<string>('')
  const [tracking, setTracking] = useState<string>('')
  const [expectedMin, setExpectedMin] = useState<string | undefined>()
  const [expectedMax, setExpectedMax] = useState<string | undefined>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [integrations, setIntegrations] = useState<
    GetIntegrationsResult | undefined
  >(defaultIntegrations)
  const [order, setOrder] = useState<Order | undefined>()
  const [channelOrder, setChannelOrder] = useState<unknown | undefined>()

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [isPrinting, setIsPrinting] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promiseResolveRef = useRef<any>(null)
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current()
    }
  }, [isPrinting])
  const contentRef = useRef<HTMLDivElement>(null)
  const reactToPrintFn = useReactToPrint({
    contentRef,
    documentTitle: order
      ? `order_${order.channel}_${order.externalOrderId}`
      : `order`,
  })

  const orderFulfilled = !!(
    (order?.shippingStatus &&
      ['fulfilled', 'shipped'].includes(order.shippingStatus.toLowerCase())) ||
    (order?.orderStatus &&
      ['completed'].includes(order.orderStatus.toLowerCase()))
  )

  useEffect(() => {
    const channel = order?.channel
    if (!channel) return
    if (!channelOrder) return
    if (!order) return

    setWeight(order.items?.[0]?.getProduct?.product.weight)
    setWeightUnit(order.items?.[0]?.getProduct?.product.weightUnit)
    setWidth(order.items?.[0]?.getProduct?.product.width)
    setHeight(order.items?.[0]?.getProduct?.product.height)
    setLength(order.items?.[0]?.getProduct?.product.length)
    setSizeUnit(order.items?.[0]?.getProduct?.product.sizeUnit)

    if (channel === IntegrationName.EBAY) {
      const ebayOrder = channelOrder as EbayOrder
      const address =
        ebayOrder.fulfillmentStartInstructions?.[0]?.shippingStep?.shipTo
          ?.contactAddress

      setShippingServices(
        unique(
          ebayOrder.shippingServices
            ?.map(
              (service) =>
                service.description +
                ': ' +
                (service.costGroupFlat || '') +
                ' ' +
                service.serviceType +
                (service.shippingTimeMin && service.shippingTimeMax
                  ? ` (${service.shippingTimeMin} - ${service.shippingTimeMax} days)`
                  : ''),
            )
            .filter(isDefined) || [],
        ),
      )
      setShippingStatuses(['FULFILLED', 'IN_PROGRESS', 'NOT_STARTED'])

      const selectedService =
        ebayOrder.fulfillmentStartInstructions?.[0]?.shippingStep
          .shippingServiceCode

      setShippingServiceId(selectedService)
      setShippingService(
        selectedService
          ? ebayOrder.shippingServices?.find((service) => {
              return service.shippingService === selectedService
            })?.description
          : undefined,
      )

      setExpectedMin(
        ebayOrder.fulfillmentStartInstructions?.[0]?.minEstimatedDeliveryDate
          ? ebayOrder.fulfillmentStartInstructions?.[0]
              ?.minEstimatedDeliveryDate
          : undefined,
      )

      setExpectedMax(
        ebayOrder.fulfillmentStartInstructions?.[0]?.maxEstimatedDeliveryDate
          ? ebayOrder.fulfillmentStartInstructions?.[0]
              ?.maxEstimatedDeliveryDate
          : undefined,
      )

      setName(ebayOrder.buyer.buyerRegistrationAddress.fullName)
      setEmail(ebayOrder.buyer.buyerRegistrationAddress.email)
      setPhone(
        ebayOrder.buyer.buyerRegistrationAddress.primaryPhone.phoneNumber,
      )

      setAddressLine1(address?.addressLine1)
      setAddressLine2(address?.addressLine2)
      setCity(address?.city)
      setZip(address?.postalCode)
      setState(address?.stateOrProvince)
      setCountry(address?.countryCode)
    } else if (channel === IntegrationName.SQUARE) {
      const squareOrder = channelOrder as SquareOrder

      setShippingStatuses([
        'RESERVED',
        'PREPARED',
        'COMPLETED',
        'CANCELED',
        'FAILED',
      ])

      // name = squareOrder.buyer.buyerRegistrationAddress.fullName
      // email = squareOrder.buyer.buyerRegistrationAddress.email
      // phone = squareOrder.buyer.buyerRegistrationAddress.primaryPhone.phoneNumber

      // const address =
      //   squareOrder.fulfillmentStartInstructions?.[0]?.shippingStep?.shipTo
      //     ?.contactAddress
      // addressLine1 = address?.addressLine1
      // addressLine2 = address?.addressLine2
      // city = address?.city
      // //  county = address?.county
      // zip = address?.postalCode
      // state = address?.stateOrProvince
      // country = address?.countryCode
    } else if (channel === IntegrationName.SHOPIFY) {
      const shopifyOrder = channelOrder as ShopifyOrder

      setShippingServices(
        shopifyOrder.shippingServices
          ?.map((service) => service.name)
          .filter(isDefined) || [],
      )

      setShippingStatuses([
        'FULFILLED',
        'IN_PROGRESS',
        'ON_HOLD',
        'OPEN',
        'PARTIALLY_FULFILLED',
        'PENDING_FULFILLMENT',
        'RESTOCKED',
        'SCHEDULED',
        'UNFULFILLED',
      ])

      setName(shopifyOrder.name)
      setEmail(shopifyOrder.email)
      setPhone(shopifyOrder.phone || undefined)

      setAddressLine1(shopifyOrder.shipping_address?.address1)
      setAddressLine2(shopifyOrder.shipping_address?.address2 || undefined)
      setCity(shopifyOrder.shipping_address?.city)
      setZip(shopifyOrder.shipping_address?.zip)
      setState(shopifyOrder.shipping_address?.province)
      setCountry(shopifyOrder.shipping_address?.country_code)
    } else if (channel === IntegrationName.BIG_COMMERCE) {
      const bigCommerceOrder = channelOrder as BigCommerceOrder

      setShippingServices(
        bigCommerceOrder.shippingMethods
          ?.map((method) => method.name)
          .filter(isDefined) || [],
      )

      setShippingStatuses([
        'Incomplete',
        'Pending',
        'Shipped',
        'Partially Shipped',
        'Refunded',
        'Cancelled',
        'Declined',
        'Awaiting Payment',
        'Awaiting Pickup',
        'Awaiting Shipment',
        'Completed',
        'Awaiting Fulfillment',
        'Manual',
        'Disputed',
        'Partially Refunded',
      ])
      const address = bigCommerceOrder.customer?.addresses?.[0]

      setName(
        bigCommerceOrder.customer?.first_name
          ? bigCommerceOrder.customer?.first_name +
              ' ' +
              (bigCommerceOrder.customer?.last_name || '')
          : '',
      )
      setEmail(bigCommerceOrder.customer?.email)
      setPhone(bigCommerceOrder.customer?.phone)
      setAddressLine1(address?.address1)
      setAddressLine2(address?.address2)
      setCity(address?.city)
      setZip(address?.postal_code)
      setState(address?.state_or_province)
      setCountry(address?.country_code)
    } else if (channel === IntegrationName.ETSY) {
      const etsyOrder = channelOrder as EtsyReceipt
      console.log(etsyOrder)

      setShippingServices(
        etsyOrder.shippingCarriers
          ?.map((carrier) => carrier.name)
          .filter(isDefined) || [],
      )

      setShippingStatuses([
        'paid',
        'completed',
        'open',
        'payment processing',
        'canceled',
        'fully refunded',
        'partially refunded',
      ])

      setName(etsyOrder.name)
      setEmail(etsyOrder.buyer_email)
      // phone = etsyOrder.buyer.buyerRegistrationAddress.primaryPhone.phoneNumber
      setAddressLine1(etsyOrder.first_line)
      setAddressLine2(etsyOrder.second_line)
      setCity(etsyOrder.city)
      setZip(etsyOrder.zip)
      setState(etsyOrder.state)
      setCountry(etsyOrder.country_iso)
    }
  }, [channelOrder, order])

  const getChannelOrderLink = (order?: Order) => {
    if (!order) return ''
    const channel = order.channel
    if (!channel) return ''
    switch (channel) {
      case IntegrationName.EBAY: {
        return `https://www.ebay.com/mesh/ord/details?orderid=${order.externalOrderId}`
      }
      case IntegrationName.ETSY: {
        return `https://www.etsy.com/your/orders/sold/${order.orderStatus?.toLocaleLowerCase()}?order_id=${
          order.externalOrderId
        }`
      }
      case IntegrationName.SQUARE: {
        return `https://app.squareup.com/dashboard/orders/overview/${order.externalOrderId}`
      }
      case IntegrationName.SHOPIFY: {
        const shop = integrations?.[IntegrationName.SHOPIFY]?.integration?.shop
        return `https://admin.shopify.com/store/${shop?.replace(
          '.myshopify.com',
          '',
        )}/orders/${order.externalOrderId}`
      }
      case IntegrationName.BIG_COMMERCE: {
        const shop =
          integrations?.[IntegrationName.BIG_COMMERCE]?.integration?.shop
        return `https://${shop?.replace(
          'stores/',
          'store-',
        )}.mybigcommerce.com/manage/orders/${order.externalOrderId}`
      }
      case IntegrationName.CLOVER: {
        return ''
      }
      default: {
        return ''
      }
    }
  }

  const handleMarkShipped = () => {
    setShippingModalOpen(true)
  }

  const handleMarkShippingComplete = (action?: string) => {
    const orderId = order?.externalOrderId
    const channel = order?.channel
    if (!orderId || !channel) return

    const address = {
      name,
      email,
      phone,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      country,
    }

    updateShipping(orderId, channel, action, shippingServiceId || '', address)
      .then((res) => {
        handleAlert(setAlert, res, 'Order Shipping Updated')
      })
      .catch((e) => err(e))

    setShippingModalOpen(false)
  }

  const handleShippingLabel = () => {
    const channel = order?.channel
    const orderId = order?.externalOrderId
    if (!channel || !orderId) return

    if (channel === IntegrationName.EBAY) {
      window
        ?.open(`https://www.ebay.com/ship/single/${orderId}`, '_blank')
        ?.focus()
    }
  }

  const handlePrintDocs = (mode: string) => {
    setPrintingMode(mode)
    reactToPrintFn()
    return
    // const channel = order?.channel
    // const orderId = order?.externalOrderId
    // if (!channel || !orderId) return
    // window?.open(getChannelOrderLink(order), '_blank')?.focus()
  }

  useEffect(() => {
    if (!orderId) return

    if (!integrations) {
      getIntegrations()
        .then((res) => {
          if (res.success && res.data) {
            setIntegrations(res.data)
          }
        })
        .catch((e) => err(e))
    }

    getOrder(orderId)
      .then((res) => {
        if (res.success && res.data) {
          setLoading(false)
          onLoaded?.()
          setOrder(res.data.order)
          setChannelOrder(res.data.channelOrder)
        }
      })
      .catch((e) => err(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  // const handleUpdateShipping = () => {

  // }

  const content = (
    <Grid
      container
      spacing={2}
      sx={{
        px: md ? 2 : 0,
      }}
      ref={contentRef}
    >
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography
          level="h3"
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
        >
          <strong>Order</strong>
        </Typography>
      </Grid>

      {loading ? (
        <Grid item xs={12}>
          <Box py={24}>
            <CircularProgress size="md" />
          </Box>
        </Grid>
      ) : null}

      {!order && !loading ? (
        <Grid item xs={12}>
          <Box py={20}>
            <Typography level="title-lg">
              No Order Data. <Link to="/orders">Orders</Link>
            </Typography>
          </Box>
        </Grid>
      ) : null}

      {order && !loading ? (
        <>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              {order?.channel ? (
                <Grid item>
                  <Avatar
                    sx={{
                      borderRadius: 0,
                      width: '28px',
                      height: '28px',
                      ml: 1,
                    }}
                    size="sm"
                    src={`/channel_icons/${order?.channel?.toLowerCase()}.png`}
                  ></Avatar>
                </Grid>
              ) : null}
              <Grid item>
                {order?.externalOrderId ? (
                  <Typography
                    display="inline"
                    component="div"
                    alignItems="center"
                  >
                    <Typography level="title-lg">
                      <strong>Order</strong>{' '}
                    </Typography>
                    <Typography level="title-md">
                      <a
                        href={getChannelOrderLink(order)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        #{order.externalOrderId}
                      </a>
                    </Typography>
                  </Typography>
                ) : null}
              </Grid>
              <Grid item>
                <Typography component="div">
                  {order?.orderStatus &&
                  order?.orderStatus !== order?.shippingStatus ? (
                    <Chip
                      variant="soft"
                      sx={{
                        textTransform: 'capitalize',
                        mr: 1,
                      }}
                      color={getStatusColor(order)}
                      startDecorator={<CheckIcon fontSize="small" />}
                    >
                      {order?.orderStatus?.toLowerCase()}
                    </Chip>
                  ) : null}
                  {order?.shippingStatus ? (
                    <Chip
                      variant="soft"
                      sx={{
                        textTransform: 'capitalize',
                        mr: 1,
                      }}
                      startDecorator={<LocalShippingIcon fontSize="small" />}
                      color={getShippingStatusColor(order)}
                    >
                      {order?.shippingStatus?.toLowerCase()}
                    </Chip>
                  ) : null}
                  {order?.paymentStatus ? (
                    <Chip
                      variant="soft"
                      sx={{
                        textTransform: 'capitalize',
                        mr: 1,
                      }}
                      startDecorator={<PaymentIcon fontSize="small" />}
                      color={getPaymentStatusColor(order)}
                    >
                      {order?.paymentStatus?.toLowerCase()}
                    </Chip>
                  ) : null}
                </Typography>
              </Grid>
              <Grid item>
                {order?.orderDate ? (
                  <Chip startDecorator={<EventIcon fontSize="small" />}>
                    {formatDateTime(order?.orderDate)}
                  </Chip>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              '@media print': {
                display: 'none',
              },
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Chip
                  variant="soft"
                  color="neutral"
                  onClick={() => handlePrintDocs('pick')}
                  startDecorator={<ReceiptLongIcon fontSize="small" />}
                >
                  Packing Slip
                </Chip>
              </Grid>
              {/* <Grid item>
            <Chip
              variant="soft"
              color="neutral"
              onClick={() => handlePrintDocs('invoice')}
              startDecorator={
                <ReceiptLongIcon fontSize="small" />
              }
            >
              Invoice
            </Chip>
          </Grid>
          <Grid item>
            <Chip
              variant="soft"
              color="neutral"
              onClick={() => handlePrintDocs('pack')}
              startDecorator={<ReceiptIcon fontSize="small" />}
            >
              Packing Slip
            </Chip>
          </Grid>
           */}
              <Grid item>
                <Chip
                  variant="soft"
                  color="neutral"
                  onClick={handleShippingLabel}
                  startDecorator={<LocalShippingIcon fontSize="small" />}
                  disabled={order.channel !== IntegrationName.EBAY}
                >
                  Shipping Label
                </Chip>
              </Grid>
              <Grid item>
                <Dropdown>
                  <MenuButton
                    endDecorator={<ArrowDropDownIcon fontSize="small" />}
                    variant="plain"
                    size="sm"
                  >
                    Actions
                  </MenuButton>
                  <Menu size="sm">
                    <MenuItem
                      onClick={handleMarkShipped}
                      disabled={orderFulfilled}
                    >
                      Mark Shipped
                    </MenuItem>
                    {/* <MenuItem
                      disabled={
                        !(
                          order.channel &&
                          markPaidChannels.includes(order.channel)
                        )
                      }
                    >
                      Mark Paid
                    </MenuItem>
                    <MenuItem disabled={true}>Cancel</MenuItem>
                    <MenuItem disabled={true}>Refund</MenuItem> */}
                  </Menu>
                </Dropdown>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={12}>
    <Stepper sx={{ width: '100%' }}>
      <Step
        orientation="vertical"
        indicator={
          <StepIndicator variant="solid" color="neutral">
            1
          </StepIndicator>
        }
      >
        Placed
      </Step>
      <Step
        orientation="vertical"
        indicator={<StepIndicator>2</StepIndicator>}
      >
        Paid
      </Step>
      <Step
        orientation="vertical"
        indicator={<StepIndicator>3</StepIndicator>}
      >
        Shipped
      </Step>
      <Step
        orientation="vertical"
        indicator={<StepIndicator>4</StepIndicator>}
      >
        Complete
      </Step>
    </Stepper>
  </Grid> */}
          <Grid item xs={12} md={8}>
            {order ? (
              <OrderDetails order={order} channelOrder={channelOrder} />
            ) : null}
            {order ? (
              <Box
                sx={{
                  '@media print': {
                    display: printingMode === 'pick' ? 'none' : undefined,
                  },
                }}
              >
                <OrderShippingDetails
                  order={order}
                  channelOrder={channelOrder}
                  shippingService={shippingService}
                  shippingDate={order.shippingDate}
                  expectedMin={expectedMin}
                  expectedMax={expectedMax}
                  name={name}
                  companyName={companyName}
                  email={email}
                  phone={phone}
                  addressLine1={addressLine1}
                  addressLine2={addressLine2}
                  city={city}
                  state={state}
                  zip={zip}
                  country={country}
                  onEdit={handleMarkShipped}
                />
              </Box>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              '@media print': {
                display: printingMode === 'pick' ? 'none' : undefined,
              },
            }}
          >
            <ChannelOrderCustomer
              name={name}
              email={email}
              phone={phone}
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              city={city}
              state={state}
              zip={zip}
              country={country}
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  )

  if (renderContent) {
    return content
  }

  return (
    <div
      id="bg"
      style={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <NavBar />
      </Box>
      <Container maxWidth={'xl'}>
        <Grid container sx={{ mt: 1 }} spacing={3}>
          <Grid item xs={12}>
            <Component>{content}</Component>
          </Grid>
        </Grid>

        <Box py={3} px={1}></Box>
      </Container>
      <Modal
        open={shippingModalOpen}
        onClose={() => setShippingModalOpen(false)}
      >
        <ModalDialog
          layout="center"
          sx={{ overflow: 'auto', p: 3 }}
          minWidth="lg"
        >
          <ModalClose />
          <Grid item xs={12}>
            <Typography level="h4">Shipping</Typography>
          </Grid>
          <Grid container spacing={4} pt={3} px={3} pb={1}>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography level="title-md">
                    <strong>Ship To</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FloatLabelInput
                    label={'Full Name'}
                    value={name}
                    onChange={(v) => setName(v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FloatLabelInput
                    label={'Company Name'}
                    onChange={(v) => setCompanyName(v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FloatLabelInput
                    label={'E-mail'}
                    value={email}
                    onChange={(v) => setEmail(v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FloatLabelInput
                    label={'Phone'}
                    value={phone}
                    onChange={(v) => setPhone(v)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FloatLabelInput
                    label={'Address Line 1'}
                    value={addressLine1}
                    onChange={(v) => setAddressLine1(v)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FloatLabelInput
                    label={'Address Line 2'}
                    value={addressLine2}
                    onChange={(v) => setAddressLine2(v)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FloatLabelInput
                    label={'City'}
                    value={city}
                    onChange={(v) => setCity(v)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FloatLabelInput
                    label={'State'}
                    value={state}
                    onChange={(v) => setState(v)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FloatLabelInput
                    label={'Zip'}
                    value={zip}
                    onChange={(v) => setZip(v)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FloatLabelInput
                    label={'Country'}
                    value={country}
                    onChange={(v) => setCountry(v)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography level="title-md">
                    <strong>Size and Weight</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FloatLabelInput
                        label={'L'}
                        value={length}
                        onChange={(v) => setLength(parseFloat(v))}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FloatLabelInput
                        label={'W'}
                        value={width}
                        onChange={(v) => setWidth(parseFloat(v))}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FloatLabelInput
                        label={'H'}
                        value={height}
                        onChange={(v) => setHeight(parseFloat(v))}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FloatLabelInput
                        label={'Size Unit'}
                        value={sizeUnit}
                        onChange={(v) => setSizeUnit(v)}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <FloatLabelInput
                        label={'Weight'}
                        value={weight}
                        onChange={(v) => setWeight(parseInt(v))}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FloatLabelInput
                        label={'Weight Unit'}
                        value={weightUnit}
                        onChange={(v) => setWeightUnit(v)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography level="title-md">
                    <strong>Shipping Service</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SingleSelect
                    freeSolo={true}
                    floatLabel="Service"
                    value={shippingService || ''}
                    options={shippingServices}
                    onChange={(v) => setShippingService(v || '')}
                    renderOption={(option) => {
                      const [name, rest] = option.split(':')

                      return (
                        <Typography key={option}>
                          <Typography level="title-sm">{name}</Typography>{' '}
                          <Typography level="body-xs">{rest}</Typography>
                        </Typography>
                      )
                    }}
                    helperText={
                      order?.channel === IntegrationName.EBAY &&
                      !shippingService?.includes('USPS') &&
                      !shippingService?.includes('eBay') ? (
                        <Typography
                          level="body-xs"
                          sx={{ mt: 0.33 }}
                          startDecorator={
                            <InfoOutlinedIcon
                              fontSize="small"
                              sx={{ height: '14px', width: '14px' }}
                            />
                          }
                        >
                          Ebay can only print labels for USPS services
                        </Typography>
                      ) : undefined
                    }
                    // button={
                    //   <Button
                    //     sx={{ float: 'right' }}
                    //     variant="soft"
                    //     startDecorator={<LocalShippingIcon fontSize="small" />}
                    //   >
                    //     Estimate
                    //   </Button>
                    // }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FloatLabelInput
                    label={'Tracking Number'}
                    value={tracking}
                    onChange={setTracking}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography level="title-md">
                    <strong>Shipping Status</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SingleSelect
                    floatLabel="Status"
                    value={shippingStatus}
                    options={shippingStatuses}
                    onChange={(v) => setShippingStatus(v || '')}
                  />
                </Grid>
              </Grid>
            </Grid> */}

            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  {' '}
                  <Button
                    sx={{ float: 'right' }}
                    onClick={() => handleMarkShippingComplete('ship')}
                    variant="soft"
                    startDecorator={<LocalShippingIcon fontSize="small" />}
                  >
                    Mark Shipped
                  </Button>
                </Grid>
                <Grid item>
                  {' '}
                  <Button
                    sx={{ float: 'right' }}
                    onClick={() => handleMarkShippingComplete()}
                    startDecorator={<UploadIcon fontSize="small" />}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}

function OrderDetails({
  order,
  channelOrder: rawChannelOrder,
}: {
  order: Order
  channelOrder?: unknown
}): JSX.Element {
  let lineItems: {
    id: string
    sku?: string
    quantity: number
    price: number
    orderItem?: OrderItem
    title: string
  }[] = []

  let channelOrder: {
    totalPrice?: number
    totalDiscount?: number
    discountText?: string
    taxAmount?: number
    totalAmount?: number
    shippingCost?: number
    paymentDate?: string
    paymentStatus?: string
  } = {}

  if (!rawChannelOrder)
    return (
      <List variant="outlined" sx={{ borderRadius: '6px' }}>
        <ListItem>
          <ListItemContent>
            <Typography level="title-md" p={1}>
              <strong>Products</strong>
            </Typography>
          </ListItemContent>
        </ListItem>
        <ListDivider />
        <ListItem>
          <ListItemContent>
            <Typography level="title-sm" p={2}>
              No Data
            </Typography>
          </ListItemContent>
        </ListItem>
      </List>
    )
  if (order.channel === IntegrationName.EBAY) {
    const ebayChannelOrder = rawChannelOrder as EbayOrder
    channelOrder = {
      totalPrice: ebayChannelOrder.pricingSummary.priceSubtotal.value
        ? parseFloat(ebayChannelOrder.pricingSummary.priceSubtotal.value)
        : 0,
      // totalDiscount: ebayChannelOrder.pricingSummary.total.value,
      discountText: ebayChannelOrder.pricingSummary.total.value,
      taxAmount: order.taxAmount,
      totalAmount: ebayChannelOrder.pricingSummary.total.value
        ? parseFloat(ebayChannelOrder.pricingSummary.total.value)
        : 0,
      shippingCost: ebayChannelOrder.pricingSummary.deliveryCost.shippingCost
        ?.value
        ? parseFloat(
            ebayChannelOrder.pricingSummary.deliveryCost.shippingCost?.value,
          )
        : 0,
      paymentDate: ebayChannelOrder.paymentSummary.payments?.[0]?.paymentDate,
      paymentStatus:
        ebayChannelOrder.paymentSummary.payments?.[0]?.paymentStatus,
    }

    lineItems = ebayChannelOrder?.lineItems?.map((item, i) => {
      const orderItem = order.items?.find(
        (orderItem) => orderItem.sku === item.sku,
      )

      return {
        id: item.lineItemId,
        sku: item.sku,
        title: item.title,
        quantity: item.quantity,
        price: parseFloat(item.total.value),
        orderItem: orderItem,
      }
    })
  } else if (order.channel === IntegrationName.SHOPIFY) {
    const shopifyChannelOrder = rawChannelOrder as ShopifyOrder

    const discountText = shopifyChannelOrder.discount_applications
      .map((discount) => discount.title)
      .join(', ')

    channelOrder = {
      totalPrice: order.totalPrice,
      totalDiscount: shopifyChannelOrder.total_discounts
        ? parseFloat(shopifyChannelOrder.total_discounts)
        : 0,
      taxAmount: order.taxAmount,
      totalAmount: shopifyChannelOrder.total_price
        ? parseFloat(shopifyChannelOrder.total_price)
        : 0,
      shippingCost: order.shippingCost,
      discountText,
    }

    lineItems = shopifyChannelOrder.line_items?.map((item, i) => {
      const orderItem = order.items?.find(
        (orderItem) => orderItem.sku === item.sku,
      )

      return {
        id: `${item.id}`,
        sku: item.sku,
        title: item.title,
        quantity: item.quantity,
        price: parseFloat(item.price),
        orderItem: orderItem,
      }
    })
  } else if (order.channel === IntegrationName.ETSY) {
    const etsyChannelOrder = rawChannelOrder as EtsyReceipt

    channelOrder = {
      totalPrice: etsyChannelOrder.subtotal.amount / 100,
      totalDiscount: etsyChannelOrder.discount_amt.amount / 100,
      taxAmount: (order.taxAmount || 0) / 100,
      totalAmount: etsyChannelOrder.total_price.amount / 100,
      shippingCost: etsyChannelOrder.total_shipping_cost.amount / 100,
    }

    lineItems = etsyChannelOrder.transactions?.map((item, i) => {
      const orderItem = order.items?.find(
        (orderItem) => orderItem.sku === item.sku,
      )

      return {
        ...item,
        price: round(item.price.amount / 100),
        quantity: item.quantity,
        sku: item.sku,
        id: `${item.transaction_id}`,
        orderItem: orderItem,
      }
    })
  } else if (order.channel === IntegrationName.BIG_COMMERCE) {
    const bigcommerceChannelOrder = rawChannelOrder as BigCommerceOrder

    channelOrder = {
      totalPrice: order.totalPrice,
      totalDiscount: getFloat(bigcommerceChannelOrder.discount_amount),
      taxAmount: getFloat(bigcommerceChannelOrder.total_tax),
      totalAmount: getFloat(bigcommerceChannelOrder.total_inc_tax),
      shippingCost: order.shippingCost,
    }

    lineItems =
      bigcommerceChannelOrder.orderProducts?.map((item) => {
        const orderItem = order.items?.find(
          (orderItem) => orderItem.sku === item.sku,
        )

        return {
          id: `${item.id}`,
          sku: item.sku,
          title: item.name,
          quantity: item.total_sold || 0,
          price: item.price || 0,
          orderItem: orderItem,
        }
      }) || []
  } else if (order.channel === IntegrationName.SQUARE) {
    const squareOrder = rawChannelOrder as SquareOrder

    channelOrder = {
      totalPrice: order.totalPrice,
      totalDiscount: getFloat(squareOrder.discounts?.[0]?.amountMoney?.amount),
      taxAmount: getFloat(squareOrder.taxes?.[0]?.appliedMoney?.amount),
      totalAmount: getFloat(squareOrder.totalMoney?.amount),
      shippingCost: order.shippingCost,
    }

    lineItems =
      squareOrder.lineItems?.map((item) => {
        const orderItem = order.items?.find(
          (orderItem) => orderItem.externalId === item.catalogObjectId,
        )

        return {
          id: `${item.uid}`,
          sku: orderItem?.sku,
          title: item.name || '',
          quantity: order.totalQuantity, // item.availability,
          price: getFloat(item.totalMoney?.amount),
          orderItem: orderItem,
        }
      }) || []
  }

  return (
    <>
      <List variant="outlined" sx={{ borderRadius: '6px' }}>
        <ListItem>
          <ListItemContent>
            <Typography level="title-md" p={1}>
              <strong>Products</strong>
            </Typography>
          </ListItemContent>
        </ListItem>
        <ListItem sx={{ mb: -1 }}>
          <ListItemContent>
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Typography level="body-xs" fontWeight={600}>
                  Title
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography level="body-xs" fontWeight={600}>
                  SKU
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography level="body-xs" fontWeight={600}>
                  Quantity
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography level="body-xs" fontWeight={600}>
                  Price
                </Typography>
              </Grid>
            </Grid>
          </ListItemContent>
        </ListItem>
        <ListDivider />
        {lineItems?.map((item) => (
          <ListItem key={item.id} sx={{ py: 1 }} nested={true}>
            <List size="sm">
              <ListItem>
                <ListItemDecorator>
                  <Avatar
                    alt={item.title.charAt(0)}
                    src={item.orderItem?.getProduct?.images?.[0]?.url}
                  ></Avatar>
                </ListItemDecorator>
                <ListItemContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography level="body-sm">{item.title}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography level="body-sm">
                            {item.orderItem?.productId ? (
                              <a
                                href={`/product/${item.orderItem?.productId}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.sku}
                              </a>
                            ) : (
                              item.sku
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography level="body-sm">
                            {item.quantity}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography level="body-sm" fontWeight={800}>
                            ${item.price.toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItemContent>
              </ListItem>
              {/* <ListItem>
                <ListItemContent>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography level="body-xs">
                        Location:{' '}
                        {
                          getAttributeByName(
                            item.orderItem?.product,
                            'Location',
                          )?.attribute?.value
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography level="body-xs">
                        Warehouse:{' '}
                        {
                          getAttributeByName(
                            item.orderItem?.product,
                            'Warehouse',
                          )?.attribute?.value
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography level="body-xs">
                        Bin:{' '}
                        {
                          getAttributeByName(item.orderItem?.product, 'Bin')
                            ?.attribute?.value
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemContent>
              </ListItem> */}
            </List>
          </ListItem>
        ))}
        <ListDivider />
        <ListItem sx={{ py: 2, px: 4 }}>
          <ListItemContent>
            <Grid container>
              <Grid item xs={8} md={10}>
                <Typography textAlign="right" level="title-sm">
                  <Typography display="block">
                    <Typography display="block">Subtotal:</Typography>
                    {channelOrder.totalDiscount ? (
                      <Typography display="block">Discounts:</Typography>
                    ) : null}
                    <Typography display="block">Shipping fee:</Typography>
                    <Typography display="block">Tax:</Typography>
                    <Typography display="block">Total:</Typography>
                    <Typography display="block">Amount paid:</Typography>
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography textAlign="right" level="title-sm" fontWeight={800}>
                  <Typography display="block">
                    <Typography display="block">
                      ${getDollarAmount(`${order.totalPrice}`)}
                    </Typography>

                    {channelOrder.totalDiscount ? (
                      <Typography display="block">
                        <Typography
                          display="block"
                          color="danger"
                          sx={{ cursor: 'pointer' }}
                        >
                          <Tooltip title={channelOrder.discountText} size="sm">
                            <span>
                              -$
                              {channelOrder.totalDiscount?.toFixed(2)}
                            </span>
                          </Tooltip>
                        </Typography>
                      </Typography>
                    ) : null}

                    <Typography display="block">
                      ${channelOrder.shippingCost?.toFixed(2)}
                    </Typography>
                    <Typography display="block">
                      ${channelOrder.taxAmount?.toFixed(2)}
                    </Typography>
                    <Typography display="block">
                      ${channelOrder.totalPrice?.toFixed(2)}
                    </Typography>
                    <Typography display="block">
                      ${channelOrder.totalPrice?.toFixed(2)}
                    </Typography>
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </ListItemContent>
        </ListItem>

        {channelOrder.paymentDate ? (
          <ListItem sx={{ px: 3 }}>
            <ListItemContent>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Chip startDecorator={<CreditScoreIcon fontSize="small" />}>
                    Paid {formatDateTime(channelOrder.paymentDate)}
                  </Chip>
                </Grid>
              </Grid>
            </ListItemContent>
          </ListItem>
        ) : null}
      </List>
    </>
  )
}

function OrderShippingDetails({
  order,
  channelOrder,
  shippingDate,
  shippingService,

  expectedMin,
  expectedMax,
  name,
  companyName,
  email,
  phone,
  addressLine1,
  addressLine2,
  city,
  zip,
  state,
  country,
  onEdit,
}: {
  order: Order
  channelOrder?: unknown
  shippingDate?: string
  expectedMin?: string
  expectedMax?: string
  shippingService?: string

  companyName?: string
  name?: string
  email?: string
  phone?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  zip?: string
  state?: string
  country?: string
  onEdit?: () => void
}): JSX.Element {
  return (
    <List variant="outlined" sx={{ borderRadius: '6px', mt: 2 }}>
      <ListItem>
        <ListItemSecondaryAction
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
        >
          <Tooltip title="Edit Shipping" size="sm">
            <IconButton onClick={onEdit}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>

        <ListItemContent>
          <Typography level="title-md" textAlign="center" p={1}>
            <strong>Shipping</strong>
          </Typography>
        </ListItemContent>
      </ListItem>
      <ListDivider />
      <ListItem nested={true}>
        <ListItemContent>
          <Grid container spacing={2}>
            {shippingDate ? (
              <Grid item xs={12}>
                <Typography component="div" level="title-sm" textAlign="left">
                  <strong>Date Shipped</strong>{' '}
                  <Typography display="block" mt={0.5} component="div">
                    <Chip
                      startDecorator={<LocalShippingIcon fontSize="small" />}
                    >
                      {formatDateTime(shippingDate)}
                    </Chip>
                  </Typography>
                </Typography>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={6}>
              <List>
                {/* <ListItem>
                  <ListItemContent>
                    <Typography textAlign="left">
                      <Typography level="title-sm">
                        <strong>Ship From</strong>
                      </Typography>
                    </Typography>
                    <Typography textAlign="left">
                      <Typography level="body-sm" display="block">
                        {companyName || name}
                      </Typography>
                      <Typography level="body-sm" display="block">
                        {addressLine1 || 'Unknown'}
                      </Typography>
                      <Typography level="body-sm" display="block">
                        {city}
                        {city ? ', ' : ''}
                        {state}
                        {zip}
                      </Typography>
                      <Typography level="body-sm" display="block">
                        {country}
                      </Typography>
                    </Typography>
                  </ListItemContent>
                </ListItem> */}
                <ListItem>
                  <ListItemContent>
                    <Typography textAlign="left">
                      <Typography level="title-sm">
                        <strong>Ship To</strong>
                      </Typography>
                    </Typography>
                    <Typography textAlign="left">
                      <Typography level="body-sm" display="block">
                        {companyName || name}
                      </Typography>
                      <Typography level="body-sm" display="block">
                        {addressLine1 || 'Unknown'}
                      </Typography>
                      <Typography level="body-sm" display="block">
                        {city}
                        {city ? ', ' : ''}
                        {state}
                        {zip}
                      </Typography>
                      <Typography level="body-sm" display="block">
                        {country}
                      </Typography>
                    </Typography>
                  </ListItemContent>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ListItemContent>
                <Typography textAlign="left">
                  <Typography level="title-sm">
                    <strong>Service</strong>
                  </Typography>
                </Typography>
              </ListItemContent>
              <List>
                <ListItem>
                  <ListItemContent>
                    <Typography textAlign="left" component="div">
                      <Typography
                        level="body-sm"
                        display="block"
                        component="div"
                      >
                        {shippingService || 'None Selected'}
                      </Typography>
                      <Typography level="body-sm" display="block">
                        Tracking: none
                      </Typography>
                      {expectedMin && expectedMax ? (
                        <Typography
                          level="title-sm"
                          display="block"
                          component="div"
                        >
                          Delivery Expected{': '}
                          <Chip size="sm">{formatDate(expectedMin)}</Chip>
                          {' - '}
                          <Chip size="sm">{formatDate(expectedMax)}</Chip>
                        </Typography>
                      ) : undefined}
                    </Typography>
                  </ListItemContent>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </ListItemContent>
      </ListItem>
    </List>
  )
}

function ChannelOrderCustomer({
  name,
  email,
  phone,
  addressLine1,
  addressLine2,
  city,
  zip,
  state,
  country,
}: {
  name?: string
  email?: string
  phone?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  zip?: string
  state?: string
  country?: string
}): JSX.Element {
  return (
    <List variant="outlined" sx={{ borderRadius: '6px' }}>
      <ListItem>
        <ListItemContent>
          <Typography level="title-md" p={1}>
            <strong>Customer</strong>
          </Typography>
        </ListItemContent>
      </ListItem>
      <ListDivider />
      <ListItem>
        <ListItemAvatar>
          <Avatar>{name?.charAt(0)}</Avatar>
        </ListItemAvatar>
        <ListItemContent>
          <Typography level="body-md" textAlign="left">
            {name || 'Unknown'}
          </Typography>
        </ListItemContent>
      </ListItem>
      <ListDivider />
      <ListItem nested={true} sx={{ overflow: 'clip' }}>
        <ListItem>
          <List>
            <ListItem>
              <ListItemContent>
                <Typography level="title-sm" textAlign="left">
                  <strong>Contact Info</strong>
                </Typography>
              </ListItemContent>
            </ListItem>
            <ListItem>
              <ListItemContent>
                <Typography
                  level="body-sm"
                  textAlign="left"
                  startDecorator={<AlternateEmailIcon fontSize="small" />}
                >
                  {email || 'No Email'}
                </Typography>
              </ListItemContent>
            </ListItem>
            <ListItem>
              <ListItemContent>
                <Typography
                  level="body-sm"
                  textAlign="left"
                  startDecorator={<PhoneIphoneIcon fontSize="small" />}
                >
                  {phone || 'No Phone'}
                </Typography>
              </ListItemContent>
            </ListItem>
          </List>
        </ListItem>
      </ListItem>
      <ListItem
        nested={true}
        sx={{
          overflow: 'clip',
          '@media print': {
            display: 'none',
          },
        }}
      >
        <List>
          <ListItem>
            <ListItemContent>
              <Typography level="title-sm" textAlign="left">
                <strong>Shipping address</strong>
              </Typography>
            </ListItemContent>
          </ListItem>
          <ListItem
            endAction={
              addressLine1 ? (
                <Tooltip title="Open in Maps" size="sm">
                  <IconButton
                    onClick={() => {
                      const q = [
                        addressLine1,
                        addressLine2,
                        state,
                        city,
                        zip,
                        country,
                      ]
                        .filter(isDefined)
                        .join(' ')
                      const url = `https://maps.google.com/?q=${q}`
                      window.open(url, '_blank')
                    }}
                  >
                    <LocationOnIcon />
                  </IconButton>
                </Tooltip>
              ) : null
            }
          >
            <ListItemContent>
              {addressLine1 ? (
                <Typography>
                  <Typography display="block">
                    <Typography
                      level="body-sm"
                      textAlign="left"
                      display="block"
                    >
                      {addressLine1}
                    </Typography>
                    <Typography
                      level="body-sm"
                      textAlign="left"
                      display="block"
                    >
                      {addressLine2}
                    </Typography>
                    <Typography
                      level="body-sm"
                      textAlign="left"
                      display="block"
                    >
                      {city}
                      {state ? `, ${state} ${zip}` : ''}
                    </Typography>
                    <Typography
                      level="body-sm"
                      textAlign="left"
                      display="block"
                    >
                      {country}
                    </Typography>
                  </Typography>
                </Typography>
              ) : (
                <Typography textAlign="left" level="body-sm">
                  No Address
                </Typography>
              )}
            </ListItemContent>
          </ListItem>
        </List>
      </ListItem>
    </List>
  )
}

type ColorTypes = 'primary' | 'neutral' | 'danger' | 'success' | 'warning'
function getShippingStatusColor(order?: Order): ColorTypes | undefined {
  const status = order?.shippingStatus?.toLocaleLowerCase()
  if (!status) return undefined
  if (['fulfilled', 'completed', 'shipped'].includes(status)) {
    return 'success'
  } else if (['awaiting fulfillment', 'open'].includes(status)) {
    return 'warning'
  } else {
    return undefined
  }
}

function getPaymentStatusColor(order?: Order): ColorTypes | undefined {
  const status = order?.paymentStatus?.toLocaleLowerCase()
  if (!status) return undefined
  if (['paid', 'completed'].includes(status)) {
    return 'success'
  } else if (['proposed', 'open'].includes(status)) {
    return 'neutral'
  } else {
    return undefined
  }
}

function getStatusColor(order?: Order): ColorTypes | undefined {
  const status = order?.orderStatus?.toLocaleLowerCase()
  if (!status) return undefined
  if (['completed', 'shipped'].includes(status)) {
    return 'success'
  } else if (['open'].includes(status)) {
    return 'neutral'
  } else {
    return undefined
  }
}

function getDollarAmount(amount?: string): string {
  return parseFloat(amount || '0').toFixed(2)
}
