import { APIResult } from '../../utils/types'
import instance from '..'
import { getResult } from '../../utils/functions'
import {
  BigCommerceCategory,
  BigCommerceLocation,
  BigCommerceOrder,
  BigCommerceProduct,
  BigCommerceProductIntegration,
  BigCommerceTemplateIntegration,
  WithId,
} from '../../types/BigCommerce.types'
import { DataFields, Integration } from '../types'

export async function bigcommerceBulkImport(params?: {
  ids?: number[]
  template?: string
  updateExistingProducts?: boolean
}): Promise<APIResult<void>> {
  const result = await instance
    .post('/api/integration/bigcommerce/bulkImport', params)
    .then((res) => getResult<void>(res))
  return result
}

export interface GetBigCommerceProducts {
  products: (BigCommerceProduct & WithId)[]
  count?: number
}

export async function getBigCommerceProducts(params: {
  page?: number
  limit?: number
}): Promise<APIResult<GetBigCommerceProducts>> {
  const result = await instance
    .get('/api/integration/bigcommerce/products', {
      params,
    })
    .then((res) => getResult<GetBigCommerceProducts>(res))
  return result
}

export type GetBigCommerceIntegrationsResult = {
  integration?: Integration
  templateIntegration?: BigCommerceTemplateIntegration
  productIntegration?: BigCommerceProductIntegration
}
export async function getBigCommerceIntegrations(
  integrationId: number,
  templateId?: number,
  productId?: number,
): Promise<APIResult<GetBigCommerceIntegrationsResult>> {
  const ebayTemplateIntegration = await instance
    .get('/api/integration/bigcommerce/integrations', {
      params: {
        integrationId,
        templateId,
        productId,
      },
    })
    .then((res) => getResult<GetBigCommerceIntegrationsResult>(res))
  return ebayTemplateIntegration
}

export async function setBigCommerceTemplateIntegration(
  templateIntegration?: DataFields<
    Omit<BigCommerceTemplateIntegration, 'clientId'>
  >,
): Promise<APIResult<BigCommerceTemplateIntegration>> {
  const setTemplateIntegration = await instance
    .post('/api/integration/bigcommerce/templateIntegration', {
      templateIntegration,
    })
    .then((res) => getResult<BigCommerceTemplateIntegration>(res))
  return setTemplateIntegration
}

export async function setBigCommerceProductIntegration(
  productIntegration?: DataFields<
    Omit<BigCommerceProductIntegration, 'clientId'>
  >,
): Promise<APIResult<BigCommerceProductIntegration>> {
  const setProductIntegration = await instance
    .post('/api/integration/bigcommerce/productIntegration', {
      productIntegration,
    })
    .then((res) => getResult<BigCommerceProductIntegration>(res))
  return setProductIntegration
}

export async function getBigCommerceLocations(): Promise<
  APIResult<BigCommerceLocation[]>
> {
  const locations = await instance
    .get('/api/integration/bigcommerce/locations')
    .then((res) => getResult<BigCommerceLocation[]>(res))
  return locations
}

export async function getBigCommerceCategories(): Promise<
  APIResult<BigCommerceCategory[]>
> {
  const categories = await instance
    .get('/api/integration/bigcommerce/categories')
    .then((res) => getResult<BigCommerceCategory[]>(res))
  return categories
}

export async function getBigCommerceOrders(): Promise<
  APIResult<BigCommerceOrder[]>
> {
  const result = await instance
    .get('/api/integration/bigcommerce/orders')
    .then((res) => getResult<BigCommerceOrder[]>(res))
  return result
}

export async function importBigCommerceOrders(): Promise<APIResult<void>> {
  const result = await instance
    .post('/api/integration/bigcommerce/importOrders')
    .then((res) => getResult<void>(res))
  return result
}
