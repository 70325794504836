import instance from '.'
import { getResult } from '../utils/functions'
import { APIResult } from '../utils/types'
import { Order } from './types'

export async function getOrders(query?: {
  startDate?: string
  endDate?: string
  channels?: string[]
  statuses?: string[]
  skus?: string[]
  page?: number
  pageSize?: number
}): Promise<APIResult<Order[]>> {
  const result = await instance
    .get('/api/order/orders', {
      params: query,
    })
    .then((res) => getResult<Order[]>(res))
  return result
}

export type OrderAnalytics = {
  channel: string
  totalAmount: number
  totalQuantity: number
  totalCost: number
  netAmount: number
  count: number
}

export async function getOrderAnalytics(query?: {
  startDate?: string
  endDate?: string
  channels?: string[]
  statuses?: string[]
  skus?: string[]
  page?: number
  pageSize?: number
}): Promise<APIResult<OrderAnalytics[]>> {
  const result = await instance
    .get('/api/order/analytics', {
      params: query,
    })
    .then((res) => getResult<OrderAnalytics[]>(res))
  return result
}

export type OrdersByDay = {
  day: string
  totalAmount: number
  totalQuantity: number
  totalCost: number
  netAmount: number
  count: number
  ebay: number
  etsy: number
  square: number
  shopify: number
  bigcommerce: number
}

export async function getOrdersByDay(query?: {
  startDate?: string
  endDate?: string
  channels?: string[]
  statuses?: string[]
  skus?: string[]
  page?: number
  pageSize?: number
}): Promise<APIResult<OrdersByDay[]>> {
  const result = await instance
    .get('/api/order/ordersByDay', {
      params: query,
    })
    .then((res) => getResult<OrdersByDay[]>(res))
  return result
}

export type SearchOrdersByProductQuery = {
  startDate?: string
  endDate?: string
  channels?: string[]
  templates?: string[]
  skus?: string[]
  attributes?: { name: string; values: string[] }[]
}

export type SearchOrdersByProduct = {
  productId: number
  sku: string
  price: number
  quantity: number
  cost: number
  template: string
  title: string
}

export async function searchOrdersByProduct(
  query?: SearchOrdersByProductQuery,
): Promise<APIResult<SearchOrdersByProduct[]>> {
  const result = await instance
    .get('/api/order/searchOrdersByProduct', {
      params: query,
    })
    .then((res) => getResult<SearchOrdersByProduct[]>(res))
  return result
}

export type TemplateAnalytics = {
  price: number | null
  productId: number | null
  quantity: number | null
  sku: number | null
  templateId: number | null
  templateName: number | null
}

export async function getTemplateAnalytics(query?: {
  startDate?: string
  endDate?: string
  channels?: string[]
  statuses?: string[]
  skus?: string[]
  page?: number
  pageSize?: number
}): Promise<APIResult<TemplateAnalytics[]>> {
  const result = await instance
    .get('/api/order/templateAnalytics', {
      params: query,
    })
    .then((res) => getResult<TemplateAnalytics[]>(res))
  return result
}

export async function getOrder(
  orderId: number,
): Promise<APIResult<{ order: Order; channelOrder?: unknown }>> {
  const result = await instance
    .get('/api/order/order', {
      params: {
        orderId,
      },
    })
    .then((res) => getResult<{ order: Order; channelOrder?: unknown }>(res))
  return result
}

export async function setOrder(order: Order): Promise<APIResult<Order>> {
  const result = await instance
    .post('/api/order/order', { order })
    .then((res) => getResult<Order>(res))
  return result
}

export async function updateShipping(
  orderId: string,
  channel: string,
  action: string | undefined,
  service: string,
  address?: {
    name?: string
    phone?: string
    email?: string
    addressLine1?: string
    addressLine2?: string
    city?: string
    state?: string
    zip?: string
    country?: string
  },
): Promise<APIResult<Order>> {
  const result = await instance
    .post('/api/order/updateShipping', {
      orderId,
      channel,
      action,
      service,
      address,
    })
    .then((res) => getResult<Order>(res))
  return result
}
