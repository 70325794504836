import instance from '.'
import { Optional } from '../types'
import {
  AspectMetadata,
  CategorySuggestions,
  CategoryTree,
} from '../types/Ebay.types'
import { getResult } from '../utils/functions'
import { APIResult } from '../utils/types'
import {
  Product,
  ProductTemplate,
  ProductAttribute,
  ProductTemplateAttribute,
  DataFields,
  InventoryColumn,
  ObjectFields,
  ProductTemplateAttributeOption,
  ProductImage,
  ProductVariation,
  ProductVariationImage,
  ProductVariationAttribute,
  ProductListing,
} from './types'

export interface Paginated<T> {
  data: T[]
  offset?: number
  limit: number
  items: number
}

export type ProductInput = Omit<ObjectFields<Product>, 'clientId'>
export type CreateProductInput = Omit<DataFields<Product>, 'clientId'>
export type GetProductTemplate = {
  template: ProductTemplate
  attributes: GetProductTemplateAttribute[]
  variations: ProductTemplateAttribute[]
}
export type GetProduct = {
  product: Product
  template: ProductTemplate
  attributes: GetProductAttribute[]
  images: ProductImage[]
  variations: GetProductVariation[]
  readiness?: 'ready' | 'partial' | 'error' | undefined
  listedOn?: string[]
}

export type GetProductVariationAttribute = ProductVariationAttribute & {
  name: string
}

export type SetProductVariationAttribute = {
  name: string
  value: string
}

export type GetProductVariation = {
  variation: ProductVariation
  images: ProductVariationImage[]
  attributes: GetProductVariationAttribute[]
}

export type GetProductAttribute = {
  attribute: ProductAttribute | undefined
  templateAttribute: ProductTemplateAttribute
  templateAttributeOptions: ProductTemplateAttributeOption[]
}

export type SetProductAttribute = {
  attribute?: Omit<DataFields<ProductAttribute>, 'productId'>
  templateAttribute: ObjectFields<ProductTemplateAttribute>
  templateAttributeOptions: Omit<
    DataFields<ProductTemplateAttributeOption>,
    'templateId' | 'templateAttributeId'
  >[]
}
export type SetProductImage = Omit<DataFields<ProductImage>, 'productId'>

export type SetProductVariation = {
  variation: Optional<Omit<DataFields<ProductVariation>, 'productId'>, 'sold'>
  attributes: SetProductVariationAttribute[]
  images: Omit<DataFields<ProductVariationImage>, 'productVariationId'>[]
  selectedImages?: File[]
}

export type GetProductTemplateAttribute = {
  attribute: ProductTemplateAttribute
  attributeOptions: ProductTemplateAttributeOption[]
}

export type SetProductTemplateAttribute = {
  attribute: Optional<ProductTemplateAttribute, 'id' | 'templateId'>
  attributeOptions: Omit<
    DataFields<ProductTemplateAttributeOption>,
    'templateId' | 'templateAttributeId'
  >[]
}

export type ProductTemplateAttributeInput = {
  attribute: Optional<
    Omit<DataFields<ProductTemplateAttribute>, 'id' | 'templateId'>,
    'index'
  >
  attributeOptions: Omit<
    DataFields<ProductTemplateAttributeOption>,
    'templateId' | 'templateAttributeId'
  >[]
}

export type CreateProductTemplateInput = Omit<
  DataFields<ProductTemplate>,
  'clientId'
>
export type ProductTemplateInput = Omit<
  ObjectFields<ProductTemplate>,
  'clientId'
>

export async function getProductTemplates(): Promise<
  APIResult<GetProductTemplate[]>
> {
  const result = await instance
    .get('/api/product/productTemplates')
    .then((res) => getResult<GetProductTemplate[]>(res))
  return result
}

export async function getProductTemplate(
  templateId: number,
): Promise<APIResult<GetProductTemplate>> {
  const result = await instance
    .post('/api/product/getProductTemplate', { templateId })
    .then((res) => getResult<GetProductTemplate>(res))
  return result
}

export async function updateProductTemplate(
  template: ProductTemplateInput,
  templateAttributes: ProductTemplateAttributeInput[],
  templateVariationAttributes: Omit<
    DataFields<ProductTemplateAttribute>,
    'templateId'
  >[],
): Promise<APIResult<GetProductTemplate>> {
  const result = await instance
    .post('/api/product/updateProductTemplate', {
      template,
      templateAttributes,
      templateVariationAttributes,
    })
    .then((res) => getResult<GetProductTemplate>(res))
  return result
}

export async function updateProductTemplateAttributes(
  templateId: number,
  templateAttributes: ProductTemplateAttributeInput[],
): Promise<APIResult<GetProductTemplateAttribute[]>> {
  const result = await instance
    .post('/api/product/updateProductTemplateAttributes', {
      templateId,
      templateAttributes,
    })
    .then((res) => getResult<GetProductTemplateAttribute[]>(res))
  return result
}

export async function getProducts(): Promise<APIResult<GetProduct[]>> {
  const result = await instance
    .post('/api/product/getProducts')
    .then((res) => getResult<GetProduct[]>(res))
  return result
}

export type QueryValue = string | number | boolean | null | string[] | number[]
export interface SearchProductsPagination {
  offset: number
  limit: number
}

export interface SearchProductsQuery {
  minPrice?: string
  maxPrice?: string
  minSold?: string
  maxSold?: string
  minQuantity?: string
  maxQuantity?: string
  skus?: string
  templateIds?: string
  hasTags?: string
  missingTags?: string
  attributes?: string
  matchAllAttributes?: string
  search?: string
  searchDescription?: string
  matchAllListings?: string
  listedOn: string
  notListedOn: string
  sortBy?: string
  orderBy?: string
}

export async function searchProducts(
  query: Record<string, string>,
): Promise<APIResult<Paginated<GetProduct>>> {
  const result = await instance
    .get('/api/product/searchProducts', {
      params: query,
    })
    .then((res) => getResult<Paginated<GetProduct>>(res))
  return result
}

export async function getAllSkus(): Promise<APIResult<string[]>> {
  const result = await instance
    .get('/api/product/allSkus')
    .then((res) => getResult<string[]>(res))
  return result
}

export async function getAllTags(): Promise<APIResult<string[]>> {
  const result = await instance
    .get('/api/product/allTags')
    .then((res) => getResult<string[]>(res))
  return result
}

export async function getAllAttributes(): Promise<APIResult<string[]>> {
  const result = await instance
    .get('/api/product/allAttributes')
    .then((res) => getResult<string[]>(res))
  return result
}

export async function getAllAttributeValues(): Promise<
  APIResult<{ [k: string]: string[] }>
> {
  const result = await instance
    .get('/api/product/allAttributeValues')
    .then((res) => getResult<{ [k: string]: string[] }>(res))
  return result
}

export async function getAllTemplates(): Promise<
  APIResult<{ name: string; id: number }[]>
> {
  const result = await instance
    .get('/api/product/allTemplates')
    .then((res) => getResult<{ name: string; id: number }[]>(res))
  return result
}

export async function getBasicProducts(): Promise<APIResult<Product[]>> {
  const result = await instance
    .post('/api/product/getBasicProducts')
    .then((res) => getResult<Product[]>(res))
  return result
}

export async function getProduct(
  productId: number,
): Promise<APIResult<GetProduct>> {
  const result = await instance
    .post('/api/product/getProduct', { productId })
    .then((res) => getResult<GetProduct>(res))
  return result
}

export async function updateProduct(
  product: ProductInput,
  attributes: SetProductAttribute[],
  images: SetProductImage[],
  variations: SetProductVariation[],
): Promise<APIResult<GetProduct>> {
  const result = await instance
    .post('/api/product/updateProduct', {
      product,
      attributes,
      images,
      variations,
    })
    .then((res) => getResult<GetProduct>(res))
  return result
}

export async function createProduct(
  product: CreateProductInput,
  attributes: SetProductAttribute[],
  images?: SetProductImage[],
): Promise<APIResult<GetProduct>> {
  const result = await instance
    .post('/api/product/createProduct', {
      product,
      attributes,
      images,
    })
    .then((res) => getResult<GetProduct>(res))
  return result
}

export async function createProductTemplate(
  template: CreateProductTemplateInput,
  templateAttributes: Omit<
    DataFields<ProductTemplateAttribute>,
    'id' | 'templateId' | 'index'
  >[],
): Promise<APIResult<ProductTemplate>> {
  const result = await instance
    .post('/api/product/createProductTemplate', {
      template,
      templateAttributes,
    })
    .then((res) => getResult<ProductTemplate>(res))
  return result
}

export async function getInventoryColumns(
  templateId?: number,
): Promise<APIResult<InventoryColumn>> {
  const result = await instance
    .post('/api/product/getInventoryColumns', { templateId })
    .then((res) => getResult<InventoryColumn>(res))
  return result
}

export async function setInventoryColumns(
  templateId: number | undefined,
  columns: string[],
): Promise<APIResult<InventoryColumn>> {
  const result = await instance
    .post('/api/product/setInventoryColumns', { templateId, columns })
    .then((res) => getResult<InventoryColumn>(res))
  return result
}

export async function getEbayCategories(): Promise<APIResult<CategoryTree>> {
  const result = await instance
    .post('/api/product/getEbayCategories')
    .then((res) => getResult<CategoryTree>(res))
  return result
}

export async function ebayTokenValid(): Promise<APIResult<boolean>> {
  const result = await instance
    .post('/api/product/ebayTokenValid')
    .then((res) => getResult<boolean>(res))
  return result
}

export async function getEbayCategorySuggestions(
  query: string,
): Promise<APIResult<CategorySuggestions>> {
  const result = await instance
    .post('/api/product/getEbayCategorySuggestions', { query })
    .then((res) => getResult<CategorySuggestions>(res))
  return result
}

export async function getProductAspectsForCategory(
  categoryId: number,
): Promise<APIResult<AspectMetadata>> {
  const result = await instance
    .post('/api/product/getProductAspectsForCategory', { categoryId })
    .then((res) => getResult<AspectMetadata>(res))
  return result
}

export async function deleteProduct(
  productId: number,
): Promise<APIResult<boolean>> {
  const result = await instance
    .post('/api/product/deleteProduct', { productId })
    .then((res) => getResult<boolean>(res))
  return result
}

export async function deleteProductTemplate(
  templateId: number,
): Promise<APIResult<boolean>> {
  const result = await instance
    .post('/api/product/deleteProductTemplate', { templateId })
    .then((res) => getResult<boolean>(res))
  return result
}

export interface CloudinarySignature {
  signature: string
  name: string
  apiKey: string
  timestamp: number
  folder: string
}
export async function getCloudinarySignature(): Promise<
  APIResult<CloudinarySignature>
> {
  const result = await instance
    .get('/api/product/getCloudinarySignature')
    .then((res) => getResult<CloudinarySignature>(res))
  return result
}

export async function deleteProductImage(
  productId: number | undefined,
  variationId: number | undefined,
  imageUrl: string,
): Promise<APIResult<boolean>> {
  const result = await instance
    .post('/api/product/deleteProductImage', {
      productId,
      variationId,
      imageUrl,
    })
    .then((res) => getResult<boolean>(res))
  return result
}

export async function duplicateProductTemplate(
  templateId: number,
): Promise<APIResult<number>> {
  const result = await instance
    .post('/api/product/duplicateTemplate', {
      templateId,
    })
    .then((res) => getResult<number>(res))
  return result
}

export async function duplicateProduct(
  productId: number,
): Promise<APIResult<number>> {
  const result = await instance
    .post('/api/product/duplicateProduct', {
      productId,
    })
    .then((res) => getResult<number>(res))
  return result
}

export async function convertProductTemplate(
  productId: number,
  templateId: number,
): Promise<APIResult<number>> {
  const result = await instance
    .post('/api/product/convertProductTemplate', {
      productId,
      templateId,
    })
    .then((res) => getResult<number>(res))
  return result
}

export async function mergeTemplateAttributes(params: {
  templateId: number
  source: string
  target: string
  action: string
  deleteSource: boolean
}): Promise<APIResult<void>> {
  const result = await instance
    .post('/api/product/mergeTemplateAttributes', params)
    .then((res) => getResult<void>(res))
  return result
}

export async function getProductListings(params?: {
  integrationId?: number
  productId?: number
}): Promise<APIResult<ProductListing[]>> {
  const result = await instance
    .get('/api/product/listings', {
      params,
    })
    .then((res) => getResult<ProductListing[]>(res))
  return result
}

export async function applyTags(params: {
  productIds: number[]
  addTags: string[]
  removeTags: string[]
}): Promise<APIResult<void>> {
  const result = await instance
    .post('/api/product/applyTags', params)
    .then((res) => getResult<void>(res))
  return result
}

export type ProductsOverview = {
  count: number
  listed: number
  unlisted: number
  sold: number
  incomplete: number
}

export async function getProductsOverview(): Promise<
  APIResult<ProductsOverview>
> {
  const result = await instance
    .get('/api/product/productsOverview')
    .then((res) => getResult<ProductsOverview>(res))
  return result
}
