import React, { Fragment } from 'react'

import Typography from '@mui/joy/Typography'
import { formatDate, formatDateTime } from '../utils/functions'
import Avatar from '@mui/joy/Avatar'
import JoyDataTable from '../components/common/JoyDataTable'
import { Tooltip } from '@mui/joy'
import { Link } from 'react-router-dom'
import Chip from '@mui/joy/Chip'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import PaymentIcon from '@mui/icons-material/Payment'
import CheckIcon from '@mui/icons-material/Check'
import { Order } from '../api/types'

export default function OrdersTable({
  orders,
  selectable,
  setSelectedOrders,
  rows,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
}: {
  selectable?: boolean
  orders: Order[]
  rows?: number
  page?: number
  pageSize?: number
  onPageChange?: (page: number) => void
  onPageSizeChange?: (pageSize: number) => void
  setSelectedOrders?: (rows: number[]) => void
}): JSX.Element {
  return (
    <JoyDataTable
      data={orders || []}
      // onRowClick={(order) =>
      //   history.push(`/orders/${order.id}`)
      // }
      onSelection={(selectedRows) => setSelectedOrders?.(selectedRows)}
      renderRow={(order) => (
        <Fragment key={order.id}>
          <td>
            {
              <Avatar
                sx={{
                  border: '1px solid #ededed',
                  width: 28,
                  height: 28,
                  backgroundColor: 'white',
                  margin: 'auto',
                }}
                key={order.channel}
                alt={order.channel}
                src={`/channel_icons/${order.channel?.toLowerCase()}.png`}
              />
            }
          </td>
          <td>
            <Typography>
              {order.items?.length
                ? order.items?.map((item) => (
                    <Typography
                      display="block"
                      key={item.productId}
                      level="body-sm"
                    >
                      <a
                        href={`/product/${item.productId}`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        {item.sku}
                      </a>
                    </Typography>
                  ))
                : '-'}
            </Typography>
          </td>
          <td>${order.totalAmount?.toFixed(2)}</td>
          <td>{order.totalQuantity}</td>
          <td style={{ textTransform: 'capitalize' }}>
            <Typography
              component="div"
              justifyContent={'center'}
              // endDecorator={
              //   <Dropdown>
              //     <MenuButton
              //       slots={{ root: IconButton }}
              //       slotProps={{
              //         root: {
              //           variant: 'plain',
              //           color: 'neutral',
              //           size: 'sm',
              //           sx: {
              //             '--IconButton-size': '18px',
              //           },
              //         },
              //       }}
              //       onClick={(e) => {
              //         e.stopPropagation()
              //         console.log()
              //       }}
              //     >
              //       <ExpandMoreIcon fontSize="small"></ExpandMoreIcon>
              //     </MenuButton>
              //     <Menu size="sm">
              //       <MenuItem disabled={true}>Mark Completed</MenuItem>
              //       <MenuItem disabled={true}>Mark Shipped</MenuItem>
              //       <MenuItem disabled={true}>Mark Paid</MenuItem>
              //     </Menu>
              //   </Dropdown>
              // }
            >
              {order.orderStatus &&
              order.orderStatus !== order.shippingStatus ? (
                <Chip
                  sx={{
                    ml: 0.33,
                  }}
                  size="sm"
                  color={getStatusColor(order)}
                  startDecorator={
                    getStatusColor(order) === 'success' ? (
                      <CheckIcon fontSize="small" />
                    ) : null
                  }
                >
                  {order.orderStatus.toLowerCase()}
                </Chip>
              ) : null}
              {order.shippingStatus ? (
                <Chip
                  sx={{
                    ml: 0.33,
                  }}
                  size="sm"
                  color={getShippingStatusColor(order)}
                  startDecorator={<LocalShippingIcon fontSize="small" />}
                >
                  {order.shippingStatus.toLowerCase()}
                </Chip>
              ) : null}
              {order.paymentStatus ? (
                <Chip
                  sx={{
                    ml: 0.33,
                  }}
                  size="sm"
                  color={getPaymentStatusColor(order)}
                  startDecorator={<PaymentIcon fontSize="small" />}
                >
                  {order.paymentStatus.toLowerCase()}
                </Chip>
              ) : null}
            </Typography>
          </td>
          <td>
            <Link to={`/order/${order.id}`}>Open</Link>
            {/* <a
                                    href={getChannelOrderLink(
                                      order.channel,
                                      order,
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Open
                                  </a> */}
          </td>
          <td>
            <Tooltip
              sx={{ cursor: 'pointer' }}
              size="sm"
              title={formatDateTime(order.orderDate)}
            >
              <Typography level="title-sm">
                {formatDate(order.orderDate)}
              </Typography>
            </Tooltip>
          </td>
        </Fragment>
      )}
      columns={[
        {
          key: 'channel',
          label: 'Channel',
          sortable: true,
          width: '100px',
        },
        {
          key: 'items',
          label: 'Products',
          sortable: true,
          width: '150px',
        },
        {
          key: 'totalAmount',
          label: 'Total',
          sortable: true,
          width: '80px',
        },
        {
          key: 'totalQuantity',
          label: 'Qt.',
          sortable: true,
          width: '80px',
        },
        {
          key: 'orderStatus',
          label: 'Status',
          sortable: true,
          width: '250px',
        },
        {
          key: 'externalOrderId',
          label: 'Order',
          sortable: false,
          width: '80px',
        },
        {
          key: 'orderDate',
          label: 'Order Date',
          sortable: true,
          width: '150px',
        },
      ]}
      selectable={selectable}
      rows={rows}
      page={page}
      pageSize={pageSize}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    />
  )
}

type ColorTypes = 'primary' | 'neutral' | 'danger' | 'success' | 'warning'
function getShippingStatusColor(order?: Order): ColorTypes | undefined {
  const status = order?.shippingStatus?.toLocaleLowerCase()
  if (!status) return undefined
  if (['fulfilled', 'completed', 'shipped'].includes(status)) {
    return 'success'
  } else if (['awaiting fulfillment', 'open'].includes(status)) {
    return 'warning'
  } else {
    return undefined
  }
}

function getPaymentStatusColor(order?: Order): ColorTypes | undefined {
  const status = order?.paymentStatus?.toLocaleLowerCase()
  if (!status) return undefined
  if (['paid', 'completed'].includes(status)) {
    return 'success'
  } else if (['proposed', 'open'].includes(status)) {
    return 'neutral'
  } else {
    return undefined
  }
}

function getStatusColor(order?: Order): ColorTypes | undefined {
  const status = order?.orderStatus?.toLocaleLowerCase()
  if (!status) return undefined
  if (['completed', 'shipped'].includes(status)) {
    return 'success'
  } else if (['open'].includes(status)) {
    return 'neutral'
  } else {
    return undefined
  }
}
