import React, { useMemo, useState } from 'react'
import Autocomplete from '@mui/joy/Autocomplete'
import AutocompleteOption from '@mui/joy/AutocompleteOption'
import Grid from '@mui/material/Grid'
import { createFilterOptions, SxProps, Theme } from '@mui/material'
import FloatLabelInput from './FloatLabelInput'
import Typography from '@mui/joy/Typography'
import AttributeInline from './AttributeInline'
import Chip from '@mui/joy/Chip'
import {
  getAttributeByTag,
  getAttributeName,
  getAttributeTag,
  getProductFieldValue,
} from '../../utils/functions'
import Close from '@mui/icons-material/Close'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'
import SvgIcon from '@mui/joy/SvgIcon'
import { TypographySystem } from '@mui/joy/styles/types/typography'
import Add from '@mui/icons-material/Add'
import Box from '@mui/joy/Box'
import {
  GetProduct,
  GetProductTemplate,
  GetProductTemplateAttribute,
} from '../../api/product'
import IconButton from '@mui/joy/IconButton'
import Tooltip from '@mui/joy/Tooltip'
import Visibility from '@mui/icons-material/Visibility'
import AlternateEmail from '@mui/icons-material/AlternateEmail'
import Button from '@mui/joy/Button'
import ChecklistRtl from '@mui/icons-material/ChecklistRtl'
import VerticalIconMenu from './VerticalIconMenu'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import ModalClose from '@mui/joy/ModalClose'
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt'
import MultipleGroupSelect from './MultipleGroupSelect'
import { GenericValueMapping } from '../product/ProductTitleDescription'
import { applyValueMapping } from '../../classes/Parser'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'

const OPTIONS_DISPLAY_LIMIT = 250

type AttributeSelectProps = {
  label?: string
  name?: string
  nameLevel?: keyof TypographySystem
  nameContainer?: boolean
  nameEditable?: boolean
  nameEditDisabled?: boolean
  nameValue?: string
  value?: string
  defaultValue?: string
  values?: string[]
  attributeNames?: string[]
  product?: GetProduct
  template?: GetProductTemplate
  templateIntegration?: { integrationId?: number; id?: number }
  parse?: boolean
  allowedValues?: string[]
  strict?: boolean
  valueMapping?: Record<string, string[]>
  attributeValueMapping?: GenericValueMapping[]
  attributeName?: string
  onValueMappingChange?: (value: string, values: string[]) => void
  options?: string[]
  channelOptions?: Record<string, string[] | undefined>
  channelAttributeOptions?: Record<
    string,
    Record<string, string[] | undefined> | undefined
  >
  multiple?: boolean
  maxLength?: number
  required?: boolean
  freeSolo?: boolean
  endDecorator?: React.ReactNode
  endElement?: React.ReactNode
  sx?: SxProps<Theme> | undefined
  xs?: [number, number]
  sm?: [number, number]
  md?: [number, number]
  lg?: [number, number]
  color?: string
  onValueMapClick?: () => void
  onBlur?: () => void
  onChange?: (value: string | undefined) => void
  onSelect?: (values: string[] | string | undefined) => void
  onUpdate?: () => void
  onNameChange?: (value: string) => void
  onNameBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void
  onClick?: () => void
  onFocus?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  nameLabel?: string | JSX.Element
  placeholder?: string
  namePlaceholder?: string
  type?: React.HTMLInputTypeAttribute
  size?: 'sm' | 'md' | 'lg'
  fullWidth?: boolean
  disabled?: boolean
  readOnly?: boolean
  errorText?: string | React.ReactNode
  helperText?: string | React.ReactNode
  autoComplete?: string
  autoCapitalize?: boolean
  autoCorrect?: boolean
  format?: 'USD'
  addOptions?: string[]
  defaultOption?: boolean
  warningText?: string | React.ReactNode
  renderAddOption?: (option: string) => React.ReactNode | string
  onAddOptionClick?: (option: string) => void
}

export default function AttributeSelect({
  label,
  name,
  nameLevel,
  nameEditable,
  nameContainer,
  nameEditDisabled,
  nameValue,
  value: defaultValue,
  values,
  attributeNames = [],
  product,
  template,
  templateIntegration,
  parse,
  allowedValues,
  strict,
  valueMapping,
  attributeValueMapping,
  attributeName,
  onValueMappingChange,
  options = [],
  channelOptions,
  channelAttributeOptions,
  multiple,
  maxLength,
  required,
  freeSolo = true,
  endDecorator,
  endElement,
  sx,
  xs,
  sm,
  md,
  lg,
  color,
  onBlur,
  onChange,
  onSelect,
  onNameChange,
  onClick,
  onNameBlur,
  onFocus,
  onKeyDown,
  nameLabel,
  placeholder,
  namePlaceholder,
  type,
  size,
  fullWidth,
  disabled,
  readOnly,
  errorText,
  helperText,
  autoComplete,
  autoCapitalize,
  autoCorrect,
  format,
  addOptions = [],
  defaultOption,
  warningText,
  renderAddOption,
  onAddOptionClick,
}: AttributeSelectProps): JSX.Element {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [focused, setFocused] = useState<boolean>(false)
  const [parseValues, setParseValues] = useState<boolean | undefined>(parse)
  const [page, setPage] = useState<number>(0)
  const [searchValueMapping, setSearchValueMapping] = useState<string>('')

  const isAttributeName = getAttributeName(defaultValue)
  const displayAttributeNameOption =
    nameValue && attributeNames.includes(nameValue)

  let templateAttribute: GetProductTemplateAttribute | undefined = undefined
  let attributeValue: string | undefined = undefined
  let valueMapped = false
  let unmappedValue: string | undefined = undefined
  // if product is provided, render view option, replace attributes with value or value mapping
  let value = defaultValue
  let displayValue = defaultValue
  const parseMethod = parseValues ?? parse
  const attributeNameMatch = getAttributeName(defaultValue)

  const suggestedAttributeNames = useMemo(() => {
    const suggestedAttributes: Record<
      string,
      { count: number; matches: string[] }
    > = {}
    if (!value && attributeName && channelAttributeOptions && options.length) {
      const key = Object.keys(channelAttributeOptions)?.[0] || ''

      Object.entries(channelAttributeOptions[key] || {}).forEach(
        ([aspect, values]) => {
          let count = 0
          const matches: string[] = []

          values?.forEach((value) => {
            if (options.includes(value)) {
              matches.push(value)
              count++
            }
          })
          if (count > 0) {
            suggestedAttributes['@' + aspect] = { count, matches }
          }
        },
      )
    }
    return Object.fromEntries(
      Object.entries(suggestedAttributes).sort(
        (a, b) => b[1].count - a[1].count,
      ),
    )
  }, [attributeName, channelAttributeOptions, options, value])

  const allOptions = (
    displayAttributeNameOption && nameValue ? [`@${nameValue}`] : []
  )
    ?.concat(Object.keys(suggestedAttributeNames))
    .concat(addOptions.map((v) => `__ADD_OPTION__${v}`))
    .concat(options || [])
    .concat(
      attributeNames
        .filter(
          (a) =>
            a !== nameValue &&
            !Object.keys(suggestedAttributeNames).includes('@' + a),
        )
        .map((a) => `@${a}`),
    )

  const renderOption = (
    props: Omit<React.HTMLAttributes<HTMLLIElement>, 'color'>,
    option: string,
  ) => {
    const addOptionValue = option.replace('__ADD_OPTION__', '')
    const isAttribute = option.startsWith('@')
    const isSuggestedNameAttribute = option.replace('@', '') === nameValue
    const isSuggestedAttribute = Object.keys(suggestedAttributeNames).includes(
      option,
    )

    return addOptions.length && option.startsWith('__ADD_OPTION__') ? (
      <AutocompleteOption
        {...props}
        sx={{ p: '3px' }}
        onClick={(e) => {
          e.preventDefault()
          setInputValue('')
          onAddOptionClick?.(addOptionValue)
        }}
      >
        {renderAddOption ? (
          renderAddOption(addOptionValue)
        ) : (
          <Typography
            level="title-sm"
            startDecorator={<Add fontSize="small" color="action" />}
          >
            <strong>
              Add{' '}
              {<AttributeInline size="s">@{addOptionValue}</AttributeInline>} to
              Template
            </strong>
          </Typography>
        )}
      </AutocompleteOption>
    ) : isAttribute ? (
      <AutocompleteOption {...props} sx={{ p: '3px' }}>
        <AttributeInline size="m" width="100%">
          {isSuggestedAttribute ? (
            <Typography p={0.3}>
              {option}
              <Tooltip
                sx={{
                  '& .MuiTooltip-tooltip': {
                    marginLeft: '5px', // Move tooltip content 5px to the right
                  },
                  '& .MuiTooltip-arrow': {
                    marginLeft: '5px', // Move arrow to align with tooltip
                  },
                }}
                placement="right-start"
                title={
                  <Typography display="block">
                    {suggestedAttributeNames[option]?.matches.map((m) => (
                      <Typography
                        key={m}
                        level="body-sm"
                        display="block"
                        sx={{ color: 'inherit' }}
                      >
                        {m}
                      </Typography>
                    ))}
                  </Typography>
                }
                size="sm"
              >
                <Typography
                  component="span"
                  level="body-xs"
                  sx={{ float: 'right', mt: 0.5, fontSize: '11px' }}
                >
                  {suggestedAttributeNames[option]?.count} options match
                </Typography>
              </Tooltip>
            </Typography>
          ) : null}
          {isSuggestedNameAttribute ? (
            <Typography p={0.3}>
              {option}
              <Typography
                component="span"
                level="body-xs"
                sx={{ float: 'right', mt: 0.5, fontSize: '11px' }}
              >
                Recommended
              </Typography>
            </Typography>
          ) : null}
          {!isSuggestedAttribute && !isSuggestedNameAttribute ? option : null}
        </AttributeInline>
      </AutocompleteOption>
    ) : (
      <AutocompleteOption {...props}>{option}</AutocompleteOption>
    )
  }

  let attributeValues = values
  if (product) {
    attributeValues = values?.map((value) => {
      const attribute = getAttributeByTag(product, value)
      if (!attribute) return value
      let attributeValue = attribute.attribute?.value || ''

      const valueMapped =
        attributeValueMapping && attributeValue
          ? applyValueMapping(attribute, attributeValueMapping)
          : undefined
      if (valueMapped) {
        attributeValue = valueMapped
      }
      return parseValues
        ? attributeValue
        : `@${attribute.templateAttribute.name}`
    })
  } else {
    attributeValues = values?.map((value) => {
      const attributeName = getAttributeName(value)
      return parseValues ? value : `@${attributeName}`
    })
  }

  if (product) {
    templateAttribute = template?.attributes.find(
      (a) => a.attribute.name === attributeNameMatch,
    )

    if (templateAttribute) {
      const attribute = product.attributes.find(
        (a) => a.templateAttribute.name === attributeNameMatch,
      )

      attributeValue = attribute?.attribute?.value
      value = attributeValue

      if (parseMethod) {
        color = 'gray'
        displayValue = attributeValue
      } else if (!parseMethod && templateAttribute) {
        displayValue = '@' + templateAttribute?.attribute.name
      }
    } else if (attributeNameMatch) {
      const productFieldValue = getProductFieldValue(
        product,
        attributeNameMatch,
      )

      if (parseMethod) {
        displayValue = productFieldValue ? `${productFieldValue}` : undefined
      } else if (!parseMethod) {
        displayValue = '@' + attributeNameMatch
      }
    }
  }

  if (!product && attributeNameMatch && !parseValues) {
    displayValue = '@' + attributeNameMatch
  }

  const valueMappingValue =
    valueMapping &&
    value &&
    Object.entries(valueMapping).find(
      ([_mapValue, values]) =>
        value &&
        values
          .map((v) => v.toLocaleLowerCase())
          .includes(value.toLocaleLowerCase()),
    )?.[0]

  if (valueMappingValue) {
    unmappedValue = value
    value = valueMappingValue
    valueMapped = true
  }

  if (valueMapped && parseMethod) {
    color = 'gray'
    displayValue = value
  }

  if (
    multiple &&
    maxLength !== undefined &&
    values &&
    values?.length >= maxLength
  ) {
    helperText = `Maximum of ${maxLength} values selected`
  }

  if (product) {
    if (
      required &&
      strict &&
      allowedValues?.length &&
      value &&
      !allowedValues.includes(value)
    ) {
      errorText = (
        <Typography>
          <strong>{value || 'Undefined'}</strong> is not in allowed values.{' '}
          <Typography
            level="body-xs"
            sx={{
              p: 0,
              textDecoration: 'underline',
              cursor: 'pointer',
              color: '#0000EE',
            }}
            variant="plain"
            onClick={() => {
              setModalOpen(true)
            }}
          >
            Map Value
          </Typography>
        </Typography>
      )
    } else if (required && attributeNameMatch && !value) {
      errorText = (
        <Typography>
          <strong>@{attributeNameMatch}</strong> is undefined.
        </Typography>
      )
    } else if (required && !value) {
      errorText = <Typography>Value is required.</Typography>
    } else if (
      (!required || !strict) &&
      allowedValues?.length &&
      value &&
      !warningText &&
      !allowedValues.includes(value)
    ) {
      warningText = (
        <Typography>
          <strong>{value}</strong> is not in suggested values.{' '}
          <Typography
            level="body-xs"
            sx={{
              p: 0,
              textDecoration: 'underline',
              cursor: 'pointer',
              color: '#0000EE',
            }}
            variant="plain"
            onClick={() => {
              setModalOpen(true)
            }}
          >
            Map Value
          </Typography>
        </Typography>
      )
    }
  }

  let inputColor: 'warning' | 'danger' | undefined = undefined
  if (errorText) {
    inputColor = 'danger'
  } else if (warningText) {
    inputColor = 'warning'
  }

  const ParseIcon = (
    <Button
      slots={{
        root: IconButton,
      }}
      size="md"
      onClick={(e) => {
        e.stopPropagation()
        setParseValues(!parseValues)
      }}
    >
      {parseValues ? (
        <Tooltip
          title={multiple ? 'View Attributes' : 'View Attribute'}
          size="sm"
        >
          <AlternateEmail fontSize="inherit" color="action"></AlternateEmail>
        </Tooltip>
      ) : (
        <Tooltip
          title={multiple ? 'Parse attribute values' : 'Parse attribute value'}
          size="sm"
        >
          <Visibility fontSize="inherit" color="action"></Visibility>
        </Tooltip>
      )}
    </Button>
  )

  let usePlaceholder = !value ? label : ''
  if (options.length > 250 && !value && !inputValue && focused) {
    usePlaceholder = 'Type to search values...'
  }

  const AutocompleteElement = (
    <FormControl>
      {multiple ? (
        <Autocomplete
          placeholder={usePlaceholder}
          onFocus={() => {
            setFocused(true)
          }}
          clearOnEscape
          disabled={disabled}
          color={inputColor}
          options={allOptions}
          filterOptions={createFilterOptions({
            matchFrom: 'any',
            limit: 250,
          })}
          groupBy={
            options.length
              ? (option) => (option.startsWith('@') ? 'Attributes' : 'Values')
              : undefined
          }
          multiple={multiple}
          value={attributeValues}
          freeSolo={freeSolo}
          onBlur={() => {
            setFocused(false)
            onBlur?.()
          }}
          inputValue={inputValue}
          openOnFocus={true}
          onInputChange={(e, inputChange) => {
            if (value && !inputValue) {
              setInputValue(value + inputChange)
              onChange?.('')
              return
            }
            setInputValue(inputChange)
          }}
          onChange={(e, newValues) => {
            const updateValues = newValues.map((value): string => {
              if (value?.startsWith('@')) {
                return getAttributeTag(value.replace('@', ''))
              }
              return value
            })
            if (maxLength) {
              onSelect?.(updateValues.slice(0, maxLength))
            } else {
              onSelect?.(updateValues)
            }
          }}
          slots={{
            clearIndicator: () => <></>,
            popupIndicator: () => <></>,
          }}
          slotProps={{
            root: {
              sx: {
                pr: attributeValues?.length ? '2px' : undefined,
              },
            },
            endDecorator: {
              style: { alignItems: 'center', display: 'inherit' },
            },
          }}
          getOptionLabel={(option) => option}
          renderOption={renderOption}
          renderTags={(tags, getTagProps) =>
            tags.map((item, index) => {
              if (
                item.startsWith('@') &&
                attributeNames?.includes(item.slice(1))
              ) {
                return (
                  <Chip
                    variant="outlined"
                    endDecorator={
                      <SvgIcon fontSize="md">
                        <Close />
                      </SvgIcon>
                    }
                    {...getTagProps({ index })}
                    key={item}
                    sx={{ borderRadius: '12px', minWidth: 0 }}
                  >
                    <strong>{item}</strong>
                  </Chip>
                )
              }

              return (
                <Chip
                  variant="outlined"
                  {...getTagProps({ index })}
                  endDecorator={
                    <SvgIcon fontSize="md">
                      <Close />
                    </SvgIcon>
                  }
                  key={item}
                >
                  {item}
                </Chip>
              )
            })
          }
          endDecorator={
            <>
              {parse === undefined && attributeValues?.length && product
                ? ParseIcon
                : undefined}
              {endDecorator}
            </>
          }
        />
      ) : (
        <Autocomplete
          placeholder={usePlaceholder}
          clearOnEscape
          freeSolo={freeSolo}
          options={allOptions}
          groupBy={
            options.length
              ? (option) => {
                  if (option.startsWith('__ADD_OPTION__')) {
                    return 'Add'
                  } else if (!option.startsWith('@')) {
                    return 'Values'
                  } else if (
                    option === '@' + nameValue ||
                    Object.keys(suggestedAttributeNames)?.includes(option)
                  ) {
                    return 'Suggested'
                  } else {
                    return 'Attributes'
                  }
                }
              : undefined
          }
          onFocus={() => {
            setFocused(true)
          }}
          multiple={true}
          filterOptions={createFilterOptions({
            matchFrom: 'any',
            limit: 250,
          })}
          slots={{
            clearIndicator: () => <></>,
            popupIndicator: () => <></>,
          }}
          slotProps={{
            root: {
              sx: {
                pr: displayValue && !disabled ? '3px' : undefined,
              },
            },
            endDecorator: {
              style: { alignItems: 'center', display: 'inherit' },
            },
            input: {
              disabled: !!(isAttributeName && parseValues),
            },
          }}
          openOnFocus={true}
          disabled={disabled}
          value={displayValue ? [displayValue] : []}
          onBlur={(e) => {
            setFocused(false)
            if (!value && inputValue) {
              setInputValue('')
              onChange?.(inputValue)
            }
            onBlur?.()
          }}
          color={inputColor}
          onKeyDown={(e) => {
            if (e.key !== 'Backspace') return
            if (!inputValue && isAttributeName) {
              setInputValue('')
            } else if (!inputValue) {
              setInputValue(defaultValue?.slice(0, -1) || '')
            }
          }}
          inputValue={inputValue}
          onInputChange={(e, inputChange) => {
            if (!inputValue && displayValue) {
              setInputValue(displayValue + inputChange)
              onChange?.('')
              return
            }
            setInputValue(inputChange)
          }}
          onChange={(e, newValue) => {
            const v = newValue?.length ? newValue.pop() : undefined
            if (v?.startsWith('@')) {
              onChange?.(getAttributeTag(v.replace('@', '')))
              setParseValues(false)
              return
            }
            onChange?.(v)
          }}
          getOptionLabel={(option) => option}
          renderOption={renderOption}
          renderTags={(tags, getTagProps) =>
            tags.map((item, index) => {
              if (
                item.startsWith('@') &&
                attributeNames?.includes(item.slice(1))
              ) {
                return (
                  <Chip
                    variant="outlined"
                    {...getTagProps({ index })}
                    key={item}
                    sx={{ borderRadius: '6px' }}
                    slotProps={{
                      root: {
                        style: {
                          height: '28px',
                        },
                      },
                    }}
                  >
                    <Typography sx={{ color: color || inputColor }}>
                      <strong>{item}</strong>
                    </Typography>
                  </Chip>
                )
              }

              return (
                <Typography
                  ml={'7.5px'}
                  mr={'-11px'}
                  key={item}
                  sx={{
                    letterSpacing: 'normal',
                    color: color || inputColor,
                  }}
                >
                  {item}
                </Typography>
              )
            })
          }
          endDecorator={
            <>
              {valueMapped && !helperText ? (
                <Tooltip
                  size="sm"
                  title={
                    <span>
                      Value mapped from <strong>{unmappedValue}</strong> to{' '}
                      <strong>{value}</strong>
                    </span>
                  }
                >
                  <SvgIcon size="sm" sx={{ mr: 0.5 }}>
                    <ChecklistRtl color="primary" sx={{ opacity: 0.7 }} />
                  </SvgIcon>
                </Tooltip>
              ) : undefined}
              {parse === undefined && attributeValue && product
                ? ParseIcon
                : undefined}
              {!!allowedValues?.length && (
                <VerticalIconMenu
                  size="small"
                  options={['Value Mapping']}
                  onClick={() => {
                    setModalOpen(true)
                  }}
                />
              )}
              {endDecorator}
            </>
          }
        />
      )}

      {errorText || warningText || helperText ? (
        <FormHelperText>
          <Typography
            ml={1}
            sx={{ mt: '-4px' }}
            level="body-xs"
            color={inputColor}
          >
            {errorText || warningText || helperText}
          </Typography>
        </FormHelperText>
      ) : null}
    </FormControl>
  )

  return (
    <Grid container justifyContent="center" spacing={1}>
      {name && (
        <Grid
          item
          xs={xs?.[0] || 12}
          sm={sm?.[0] || 12}
          md={md?.[0] || 3}
          lg={lg?.[0] || 2}
        >
          {!nameEditable && !nameContainer ? (
            <Typography level={nameLevel || 'title-md'} sx={{ pt: '0.5em' }}>
              <strong>{name}</strong>
            </Typography>
          ) : null}
          {nameEditable ? (
            <FloatLabelInput
              value={name}
              onChange={onNameChange}
              onBlur={onNameBlur}
              label={nameLabel}
              placeholder={namePlaceholder}
              type={type}
              size={size}
              fullWidth={fullWidth}
              required={required}
              disabled={disabled || nameEditDisabled}
              readOnly={readOnly}
              errorText={errorText}
              helperText={helperText}
              autoComplete={autoComplete}
              autoCapitalize={autoCapitalize}
              autoCorrect={autoCorrect}
              format={format}
            />
          ) : null}

          {nameContainer ? (
            <Typography
              sx={{
                border: '1px solid #e8e9eb',
                lineHeight: '34px',
                px: '8px',
                borderRadius: '6px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'left',
                boxShadow: '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
                color: '#32383E',
                backgroundColor: '#FBFCFE',
                cursor: 'pointer',
              }}
            >
              {required ? (
                <Tooltip title="Required">
                  <strong title={name}>{name}*</strong>
                </Tooltip>
              ) : (
                <strong title={name}>{name}</strong>
              )}
            </Typography>
          ) : null}
        </Grid>
      )}

      <Grid
        item
        xs={name ? xs?.[1] || 12 : 12}
        sm={name ? sm?.[1] || 12 : 12}
        md={name ? md?.[1] || 9 : 12}
        lg={name ? lg?.[1] || 10 : 12}
      >
        {endElement ? (
          <Box sx={{ display: 'flex', width: '100%', overflow: 'auto' }}>
            <Box sx={{ flexGrow: 1 }}>{AutocompleteElement}</Box>
            <Box sx={{ ml: 1 }}>{endElement}</Box>
          </Box>
        ) : (
          AutocompleteElement
        )}
      </Grid>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalDialog
          layout="center"
          sx={{ overflow: 'auto', p: 2 }}
          minWidth="md"
        >
          <ModalClose />

          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Typography level="h3">Channel Value Mapping</Typography>
            </Grid>

            <Grid item xs={12}>
              <Box p={3} mb={3}>
                <Typography level="title-lg">Template Value Mapping</Typography>
                <Typography level="body-xs">
                  Mapping will only apply to this channel template.
                </Typography>
              </Box>
              <Grid container justifyContent="center" spacing={1}>
                {allowedValues &&
                allowedValues.length > OPTIONS_DISPLAY_LIMIT ? (
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item xs={2}>
                        <Button
                          color="neutral"
                          variant="soft"
                          sx={{ float: 'right' }}
                          onClick={() => setPage(Math.max(page - 1, 0))}
                          disabled={page < 1}
                        >
                          <KeyboardArrowLeftOutlinedIcon />
                        </Button>
                      </Grid>
                      <Grid item xs={8}>
                        <FloatLabelInput
                          label={'Search Channel Values'}
                          fullWidth={true}
                          value={searchValueMapping}
                          onChange={(v) => {
                            setPage(0)
                            setSearchValueMapping(v)
                          }}
                        ></FloatLabelInput>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          color="neutral"
                          variant="soft"
                          sx={{ float: 'left' }}
                          onClick={() =>
                            setPage(
                              Math.min(
                                page + 1,
                                Math.ceil(
                                  allowedValues.length / OPTIONS_DISPLAY_LIMIT,
                                ),
                              ),
                            )
                          }
                          disabled={
                            page >=
                            Math.floor(
                              allowedValues.length / OPTIONS_DISPLAY_LIMIT,
                            )
                          }
                        >
                          <KeyboardArrowRightOutlinedIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid item xs={5}>
                  <Typography level="title-md">
                    <strong>Value</strong>
                  </Typography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>
                  <Typography level="title-md">
                    <strong>Channel Value</strong>
                  </Typography>
                </Grid>
                {allowedValues &&
                  allowedValues?.length > OPTIONS_DISPLAY_LIMIT &&
                  allowedValues
                    ?.filter((v) =>
                      v
                        .toLocaleLowerCase()
                        .startsWith(searchValueMapping.toLocaleLowerCase()),
                    )
                    .slice(
                      page * OPTIONS_DISPLAY_LIMIT,
                      (page + 1) * OPTIONS_DISPLAY_LIMIT,
                    )
                    ?.map((allowedValue) => {
                      const valueMappingValues = valueMapping?.[allowedValue]
                      const templateAttributeOptions =
                        templateAttribute?.attributeOptions.map((o) => ({
                          source: 'template',
                          value: o.value,
                        }))

                      const valueMappingValueOptions = valueMappingValues?.map(
                        (v) => ({ value: v }),
                      )

                      const channelOptionKeys = channelOptions
                        ? Object.keys(channelOptions)
                        : []

                      let allChannelOptions = templateAttributeOptions
                      channelOptionKeys.forEach((channelKey) => {
                        allChannelOptions = allChannelOptions?.concat(
                          channelOptions?.[channelKey]?.map((o) => ({
                            source: channelKey,
                            value: o,
                          })) || [],
                        )
                      })

                      return (
                        <Grid item xs={12} key={allowedValue}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item xs={5}>
                              <MultipleGroupSelect
                                value={valueMappingValueOptions || []}
                                freeSolo={true}
                                options={allChannelOptions}
                                autoHighlight={false}
                                onChange={(values) => {
                                  onValueMappingChange?.(allowedValue, values)
                                }}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <SvgIcon
                                size="lg"
                                color="neutral"
                                sx={{
                                  position: 'relative',
                                  top: '4px',
                                  right: '0px',
                                  opacity: 0.5,
                                }}
                              >
                                <ArrowRightAlt />
                              </SvgIcon>
                            </Grid>
                            <Grid item xs={5}>
                              <FloatLabelInput
                                value={allowedValue}
                                disabled={true}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })}

                {allowedValues &&
                  allowedValues?.length <= OPTIONS_DISPLAY_LIMIT &&
                  allowedValues?.map((allowedValue) => {
                    const valueMappingValues = valueMapping?.[allowedValue]
                    const templateAttributeOptions =
                      templateAttribute?.attributeOptions.map((o) => ({
                        source: 'template',
                        value: o.value,
                      }))

                    const valueMappingValueOptions = valueMappingValues?.map(
                      (v) => ({ value: v }),
                    )

                    const channelOptionKeys = channelOptions
                      ? Object.keys(channelOptions)
                      : []

                    let allChannelOptions = templateAttributeOptions
                    channelOptionKeys.forEach((channelKey) => {
                      allChannelOptions = allChannelOptions?.concat(
                        channelOptions?.[channelKey]?.map((o) => ({
                          source: channelKey,
                          value: o,
                        })) || [],
                      )
                    })

                    return (
                      <Grid item xs={12} key={allowedValue}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={5}>
                            <MultipleGroupSelect
                              value={valueMappingValueOptions || []}
                              freeSolo={true}
                              options={allChannelOptions}
                              autoHighlight={false}
                              onChange={(values) => {
                                onValueMappingChange?.(allowedValue, values)
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <SvgIcon
                              size="lg"
                              color="neutral"
                              sx={{
                                position: 'relative',
                                top: '4px',
                                right: '0px',
                                opacity: 0.5,
                              }}
                            >
                              <ArrowRightAlt />
                            </SvgIcon>
                          </Grid>
                          <Grid item xs={5}>
                            <FloatLabelInput
                              value={allowedValue}
                              disabled={true}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })}

                {allowedValues &&
                allowedValues.length > OPTIONS_DISPLAY_LIMIT ? (
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={1}>
                      <Grid item xs={12}>
                        <Button
                          color="neutral"
                          sx={{ float: 'left' }}
                          variant="soft"
                          onClick={() => setPage(Math.max(page - 1, 0))}
                          disabled={page < 1}
                        >
                          <KeyboardArrowLeftOutlinedIcon />
                        </Button>
                        <Button
                          color="neutral"
                          sx={{ float: 'right' }}
                          variant="soft"
                          onClick={() =>
                            setPage(
                              Math.min(
                                page + 1,
                                Math.ceil(
                                  allowedValues.length / OPTIONS_DISPLAY_LIMIT,
                                ),
                              ),
                            )
                          }
                          disabled={
                            page >=
                            Math.floor(
                              allowedValues.length / OPTIONS_DISPLAY_LIMIT,
                            )
                          }
                        >
                          <KeyboardArrowRightOutlinedIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button sx={{ float: 'right' }}>Save</Button>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
    </Grid>
  )
}
