import * as React from 'react'
import Box from '@mui/joy/Box'
import Table from '@mui/joy/Table'
import Typography from '@mui/joy/Typography'
import Sheet from '@mui/joy/Sheet'
import Checkbox from '@mui/joy/Checkbox'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import IconButton from '@mui/joy/IconButton'
import Link from '@mui/joy/Link'
import Select from '@mui/joy/Select'
import Option from '@mui/joy/Option'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { visuallyHidden } from '@mui/utils'
import { isDefined } from '../../utils/functions'

function labelDisplayedRows({
  from,
  to,
  count,
}: {
  from: number
  to: number
  count: number
}) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<T>(
  order: Order,
  orderBy: keyof T,
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

interface EnhancedTableProps<T> {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: keyof T | undefined
  rowCount: number
  columns: Column<T>[]
  selectable?: boolean
}

function EnhancedTableHead<T>(props: EnhancedTableProps<T>) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selectable,
  } = props
  const createSortHandler =
    (column: Column<T>) => (event: React.MouseEvent<unknown>) => {
      if (!column.sortable) return
      onRequestSort(event, column.key)
    }

  return (
    <thead>
      <tr>
        {selectable && (
          <th>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              slotProps={{
                input: {
                  'aria-label': 'Select All',
                },
              }}
              sx={{ verticalAlign: 'sub' }}
            />
          </th>
        )}
        {props.columns.map((column) => {
          const active = orderBy === column.key
          return (
            <th
              style={{ width: column.width }}
              key={column.label}
              aria-sort={
                active
                  ? ({ asc: 'ascending', desc: 'descending' } as const)[order]
                  : undefined
              }
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                underline="none"
                color="neutral"
                textColor={active ? 'primary.plainColor' : undefined}
                component="button"
                onClick={createSortHandler(column)}
                endDecorator={
                  column.sortable ? (
                    <ArrowDownwardIcon
                      fontSize="small"
                      sx={[active ? { opacity: 1 } : { opacity: 0 }]}
                    />
                  ) : null
                }
                sx={{
                  margin: 'auto',
                  fontWeight: 'lg',

                  '& svg': {
                    transition: '0.2s',
                    transform:
                      active && order === 'desc'
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                  },

                  '&:hover': { '& svg': { opacity: 1 } },
                }}
              >
                {column.label}
                {active ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </Link>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
interface EnhancedTableToolbarProps {
  numSelected: number
  title: string | React.ReactNode
}
function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  // const { numSelected } = props
  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          py: 1,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          borderTopLeftRadius: 'var(--unstable_actionRadius)',
          borderTopRightRadius: 'var(--unstable_actionRadius)',
        },
        // numSelected > 0 && {
        //   bgcolor: 'background.level1',
        // },
      ]}
    >
      <Typography
        level="h4"
        sx={{ flex: '1 1 100%' }}
        id="tableTitle"
        component="div"
      >
        {props.title}
      </Typography>
      {/* {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          level="h4"
          sx={{ flex: '1 1 100%' }}
          id="tableTitle"
          component="div"
        >
          {props.title}
        </Typography>
      )} */}
      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton size="sm" color="danger" variant="solid">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton size="sm" variant="outlined" color="neutral">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Box>
  )
}

interface Column<T> {
  key: keyof T // Key of the object to sort by
  label: string // Display label for the column
  sortable?: boolean // Whether this column is sortable
  width?: string
}
interface DataTableProps<T> {
  data: T[]
  columns: Column<T>[]
  onRowClick?: (item: T) => void
  renderRow: (item: T) => React.ReactNode // Function to render a row
  onSelection?: (selectedRows: number[]) => void
  rowsPerPage?: number // Number of rows per page
  // tableProps?: TableProps // Props to pass to the MUI Joy Table
  selectable?: boolean
  pagination?: boolean
  title?: string | React.ReactNode
  rows?: number
  page?: number
  pageSize?: number
  onPageChange?: (page: number) => void
  onPageSizeChange?: (pageSize: number) => void
}

export default function JoyDataTable<T extends { id?: number }>({
  data,
  columns,
  onRowClick,
  onSelection,
  pagination = true,
  renderRow,
  title,
  selectable,
  rows,
  page: defaultPage,
  pageSize,
  onPageChange,
  onPageSizeChange,
}: // rowsPerPage,
DataTableProps<T>): JSX.Element {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof T>('id')
  const [selected, setSelected] = React.useState<number[]>([]) // index
  const [page, setPage] = React.useState<number>(defaultPage || 0)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(pageSize || 25)
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    // on partial
    if (selected.length > 0 && selected.length < data.length) {
      handleSetSelected([])
      return
    }

    if (event.target.checked) {
      const newSelected = data.map((n) => n.id).filter(isDefined)
      handleSetSelected(newSelected)
      return
    }
    handleSetSelected([])
  }

  const handleSetSelected = (selected: number[]) => {
    setSelected(selected)
    onSelection?.(selected)
  }

  const handleClick = (
    event: React.MouseEvent<unknown>,
    id: number | undefined,
  ) => {
    if (!id) return
    const selectedIndex = selected.indexOf(id)
    let newSelected: number[] = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    handleSetSelected(newSelected)
  }
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
    onPageChange?.(newPage)
  }
  const handleChangeRowsPerPage = (event: unknown, newValue: number | null) => {
    setRowsPerPage(newValue || 25)
    onPageSizeChange?.(newValue || 25)
    setPage(0)
    onPageChange?.(0)
  }
  const getLabelDisplayedRowsTo = () => {
    if (rows) {
      return rows
    }
    if (data.length === -1) {
      return (page + 1) * rowsPerPage
    }
    return rowsPerPage === -1
      ? data.length
      : Math.min(data.length, (page + 1) * rowsPerPage)
  }
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (rows || data.length)) : 0
  const colSpan = selectable ? columns.length + 1 : columns.length

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: '100%',
        border: 'none',
        // boxShadow: 'sm',
        // borderRadius: 'sm',
        overflow: 'auto',
      }}
    >
      <EnhancedTableToolbar title={title} numSelected={selected.length} />
      <Table
        hoverRow
        sx={{
          '--TableCell-headBackground': 'transparent',
          '--TableCell-selectedBackground': (theme) =>
            theme.vars.palette.success.softBg,
          '& thead th:nth-child(1)': selectable
            ? {
                width: '40px',
              }
            : {},
          '& thead th:nth-child(2)': selectable
            ? {
                width: '30%',
              }
            : {},
          '& tr > *:nth-child(n)': { textAlign: 'center' },
        }}
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
          columns={columns}
          selectable={selectable}
        />

        <tbody>
          {[...data]
            .sort(getComparator<T>(order, orderBy))
            .slice(
              rows ? undefined : page * rowsPerPage,
              rows ? undefined : page * rowsPerPage + rowsPerPage,
            )
            .map((item, index) => {
              const isItemSelected =
                selectable && !!(item.id && selected.includes(item.id))
              const labelId = `enhanced-table-checkbox-${index}`

              return (
                <tr
                  onClick={(event) => handleClick(event, item.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={JSON.stringify(item)}
                  // selected={isItemSelected}
                  style={
                    isItemSelected
                      ? ({
                          '--TableCell-dataBackground':
                            'var(--TableCell-selectedBackground)',
                          '--TableCell-headBackground':
                            'var(--TableCell-selectedBackground)',
                        } as React.CSSProperties)
                      : {}
                  }
                >
                  {selectable && (
                    <th scope="row">
                      <Checkbox
                        checked={isItemSelected}
                        slotProps={{
                          input: {
                            'aria-labelledby': labelId,
                          },
                        }}
                        sx={{ verticalAlign: 'top' }}
                      />
                    </th>
                  )}
                  {renderRow(item)}
                </tr>
              )
            })}

          {!data.length ? (
            <tr
              style={
                {
                  height: `calc(${emptyRows} * 40px)`,
                  '--TableRow-hoverBackground': 'transparent',
                } as React.CSSProperties
              }
            >
              <td colSpan={colSpan}>
                <Box p={3}>
                  <Typography level="body-md">No Data</Typography>
                </Box>
              </td>
            </tr>
          ) : null}
          {emptyRows > 0 && (
            <tr
              style={
                {
                  height: `calc(${emptyRows} * 40px)`,
                  '--TableRow-hoverBackground': 'transparent',
                } as React.CSSProperties
              }
            >
              <td colSpan={colSpan} aria-hidden />
            </tr>
          )}
        </tbody>
        {pagination ? (
          <tfoot>
            <tr>
              <td colSpan={colSpan}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    justifyContent: 'flex-end',
                  }}
                >
                  <FormControl orientation="horizontal" size="sm">
                    <FormLabel>Rows per page:</FormLabel>
                    <Select
                      onChange={handleChangeRowsPerPage}
                      value={rowsPerPage}
                    >
                      <Option value={5}>5</Option>
                      <Option value={10}>10</Option>
                      <Option value={25}>25</Option>
                      <Option value={50}>50</Option>
                      <Option value={100}>100</Option>
                    </Select>
                  </FormControl>
                  <Typography sx={{ textAlign: 'center', minWidth: 80 }}>
                    {labelDisplayedRows({
                      from: data.length === 0 ? 0 : page * rowsPerPage + 1,
                      to: getLabelDisplayedRowsTo(),
                      count: rows || (data.length === -1 ? -1 : data.length),
                    })}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                      size="sm"
                      color="neutral"
                      variant="outlined"
                      disabled={page === 0}
                      onClick={() => handleChangePage(page - 1)}
                      sx={{ bgcolor: 'background.surface' }}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton
                      size="sm"
                      color="neutral"
                      variant="outlined"
                      disabled={
                        data.length !== -1
                          ? page >=
                            Math.ceil((rows || data.length) / rowsPerPage) - 1
                          : false
                      }
                      onClick={() => handleChangePage(page + 1)}
                      sx={{ bgcolor: 'background.surface' }}
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </Box>
                </Box>
              </td>
            </tr>
          </tfoot>
        ) : null}
      </Table>
    </Sheet>
  )
}
