/**
 * Utility Functions
 */

import axios, { AxiosResponse } from 'axios'
import { PRODUCT_FIELDS_ENUM, ROLES } from '../types'
import {
  APIResult,
  DataFields,
  DataObject,
  IdObject,
  ObjectFields,
  ValueFields,
} from './types'
import { ATTRIBUTE_TAG_END, ATTRIBUTE_TAG_START } from './constants'
import {
  CloudinarySignature,
  GetProduct,
  GetProductAttribute,
  SetProductImage,
} from '../api/product'
import { sha256 as jsSha256 } from 'js-sha256'
import { IntegrationName, ProductIntegrationType } from '../api/integration'

export async function sha256(message: string): Promise<string> {
  return jsSha256(message)
}

export function getResult<T>(res: AxiosResponse | void): APIResult<T> {
  if (res) {
    return res.data as APIResult<T>
  } else {
    return { success: false, data: undefined, message: 'No response.' }
  }
}

export function formatDate(
  date: string | number | Date | null | undefined,
): string {
  return new Date(date || new Date()).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}

export function formatDateTime(
  date: string | number | Date | null | undefined,
): string {
  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })

  const useDate = date ? new Date(date) : new Date()
  const timePart = timeFormatter.format(useDate)
  const datePart = dateFormatter.format(useDate)

  return `${timePart}, ${datePart}`
}

export function formatNameValue(str: string | undefined): string {
  if (!str?.length) return ''

  let formattedName = str.replace(/[^a-zA-Z0-9]/g, '')
  formattedName = formattedName.slice(0, formattedName.length)

  return formattedName
}

export function isSuperAdmin(roleId: number | undefined): boolean {
  return !!roleId && roleId <= ROLES.SUPER_ADMIN
}

export function isAdmin(roleId: number | undefined): boolean {
  return !!roleId && roleId <= ROLES.ADMIN
}

export function isManager(roleId: number | undefined): boolean {
  return !!roleId && roleId <= ROLES.MANAGER
}

export function wait(ms = 100): Promise<void> {
  return new Promise((res) => setTimeout(() => res(), ms))
}

/**
 * Split by delimiter and trim each result.
 */
export function split(str: string | undefined, delim = ' '): string[] {
  if (!str) return []
  return str.split(delim).map((s) => s.trim())
}

export function log(msg: unknown): void {
  console.log(msg)
}

export function err(e: Error): void {
  console.error(e.message)
}

export function random(range: number, min = 0): number {
  return Math.floor(Math.random() * range) + min
}

export function range(range: number): number[] {
  return Array(range)
    .fill(0)
    .map((zero, i) => i)
}

export function createSku(
  productId?: number,
  length?: number,
  prefix?: string,
): string {
  if (productId) {
    return (prefix || 'SK') + String(productId).padStart(length || 6, '0')
  }
  const bank = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  return range(8)
    .map((i) => bank[random(bank.length)])
    .join('')
}

export function copy<T>(data: T): T {
  if (!data) return data
  return JSON.parse(JSON.stringify(data))
}

export function getAttributeTag(name: string | undefined): string {
  if (!name) return ''
  return ATTRIBUTE_TAG_START + ' ' + name + ' ' + ATTRIBUTE_TAG_END
}

export function getAttributeName(tag: string | undefined): string | undefined {
  if (!tag) return undefined
  const pattern = `${ATTRIBUTE_TAG_START}\\s*([a-zA-Z0-9\\-_/() ]+?)\\s*${ATTRIBUTE_TAG_END}`
  const reg = new RegExp(pattern, 'gim')
  return reg.exec(tag)?.[1]
}

export function getAttributeValue(
  product: GetProduct,
  attributeName: string,
): string | undefined {
  return product?.attributes?.find(
    (a) => a.templateAttribute.name === attributeName,
  )?.attribute?.value
}

export function getAttributeByName(
  product: GetProduct | undefined,
  attributeName: string | undefined,
): GetProductAttribute | undefined {
  if (!product || !attributeName) return undefined
  return product?.attributes?.find(
    (a) => a.templateAttribute.name === attributeName,
  )
}

export function getAttributeByTag(
  product: GetProduct | undefined,
  attributeTag: string | undefined,
): GetProductAttribute | undefined {
  if (!product || !attributeTag) return undefined
  const attributeName = getAttributeName(attributeTag)
  return product?.attributes?.find(
    (a) => a.templateAttribute.name === attributeName,
  )
}

export function getAttributeNames(tag: string | undefined): string[] {
  if (!tag) return []
  const pattern = `${ATTRIBUTE_TAG_START}\\s*([a-zA-Z0-9\\-_/() ]+?)\\s*${ATTRIBUTE_TAG_END}`
  const reg = new RegExp(pattern, 'gim')
  const matches = reg.exec(tag) || []
  matches.shift() // remove first entire match
  return matches
}

export function getProductFieldValue(
  product: GetProduct,
  field: string,
): string | number | boolean | undefined {
  switch (field) {
    case PRODUCT_FIELDS_ENUM.ACTIVE:
      return product.product.active
    case PRODUCT_FIELDS_ENUM.DESCRIPTION:
      return product.product.description
    case PRODUCT_FIELDS_ENUM.SKU:
      return product.product.sku
    case PRODUCT_FIELDS_ENUM.ID:
      return product.product.id
    case PRODUCT_FIELDS_ENUM.TAGS:
      return product.product.tags
    case PRODUCT_FIELDS_ENUM.CONDITION:
      return product.product.condition
    case PRODUCT_FIELDS_ENUM.COST:
      return product.product.cost
    case PRODUCT_FIELDS_ENUM.PRICE:
      return product.product.price
    case PRODUCT_FIELDS_ENUM.MSRP:
      return product.product.msrp
    case PRODUCT_FIELDS_ENUM.QUANTITY:
      return product.product.quantity
    case PRODUCT_FIELDS_ENUM.SOLD:
      return product.product.sold
    case PRODUCT_FIELDS_ENUM.NOTES:
      return product.product.notes
    case PRODUCT_FIELDS_ENUM.WEIGHT:
      return product.product.weight
    case PRODUCT_FIELDS_ENUM.WEIGHT_UNIT:
      return product.product.weightUnit
    case PRODUCT_FIELDS_ENUM.WIDTH:
      return product.product.width
    case PRODUCT_FIELDS_ENUM.LENGTH:
      return product.product.length
    case PRODUCT_FIELDS_ENUM.HEIGHT:
      return product.product.height
    case PRODUCT_FIELDS_ENUM.SIZE_UNIT:
      return product.product.sizeUnit
    case PRODUCT_FIELDS_ENUM.UPDATED:
      return product.product.updatedAt
    case PRODUCT_FIELDS_ENUM.CREATED:
      return product.product.createdAt
    default:
      return undefined
  }
}

export function isLike(
  str1: string | undefined,
  str2: string | undefined,
): boolean {
  if (str1 === undefined || str2 === undefined) return false
  const string1 = str1.toLowerCase().trim()
  const string2 = str2.toLowerCase().trim()
  return string1 === string2
}

export function isLikeAny(
  compare: string | undefined,
  strings: string[],
): boolean {
  return strings
    .map((str) => isLike(str, compare))
    .some((alike) => alike === true)
}

export function isDefined<T>(input: T | undefined): input is T {
  return input !== undefined && input !== null
}

/**
 * Uploads selectedImages to cloudinary and concats the url, index to the images array and returns.
 */
export async function cloudinaryUploadImages(
  signature: CloudinarySignature,
  images: string[],
  selectedImages: File[],
): Promise<SetProductImage[] | undefined> {
  const updatedImages: SetProductImage[] = images.map((img, i) => ({
    index: i,
    url: img,
  }))
  const uploadedBaseIndex = updatedImages.length
  if (signature?.name) {
    const formData = new FormData()
    for (let i = 0; i < selectedImages.length; i++) {
      const file = selectedImages[i]
      if (!file) return
      formData.append('file', file)
      formData.append('api_key', signature.apiKey)
      formData.append('timestamp', signature.timestamp.toString())
      formData.append('signature', signature.signature)
      formData.append('folder', signature.folder)
      const url =
        'https://api.cloudinary.com/v1_1/' + signature.name + '/auto/upload'
      await axios
        .post(url, formData)
        .then((res) => {
          log(res.data)
          const imageUrl = res.data.secure_url

          if (imageUrl) {
            updatedImages.push({
              url: imageUrl,
              index: uploadedBaseIndex + i,
            })
          } else {
            log('Error uploading image.')
          }
        })
        .catch((e) => err(e))
    }
  } else {
    log('Cannot upload images.')
  }

  return updatedImages
}

/**
 * Uploads selectedImages to cloudinary and concats the url, index to the images array and returns.
 */
export async function cloudinaryUploadImage(
  signature: CloudinarySignature,
  imageFile: File,
): Promise<string | undefined> {
  let uploadedImageUrl: string | undefined

  if (signature?.name) {
    const formData = new FormData()
    if (!imageFile) return
    formData.append('file', imageFile)
    formData.append('api_key', signature.apiKey)
    formData.append('timestamp', signature.timestamp.toString())
    formData.append('signature', signature.signature)
    formData.append('folder', signature.folder)
    const url =
      'https://api.cloudinary.com/v1_1/' + signature.name + '/auto/upload'
    await axios
      .post(url, formData)
      .then((res) => {
        log(res.data)
        const imageUrl = res.data.secure_url
        uploadedImageUrl = imageUrl
      })
      .catch((e) => err(e))
  } else {
    // No signature
    log('Cannot upload image. Missing Cloudinary signature.')
    return undefined
  }

  return uploadedImageUrl
}

export function round(
  num: number,
  digits = 2,
  options: { method: 'round' | 'ceil' | 'floor' } = { method: 'round' },
): number {
  const mult = Math.pow(10, digits)

  switch (options.method) {
    case 'round':
      return Math.round(num * mult) / mult
    case 'ceil':
      return Math.ceil(num * mult) / mult
    case 'floor':
      return Math.floor(num * mult) / mult
    default:
      return Math.round(num * mult) / mult
  }
}

export function unique<T>(array: T[]): T[] {
  return [...new Set(array)]
}

export function parseBoolean(boolString: string | undefined): boolean {
  return boolString?.toLowerCase() === 'true'
}

interface TitleDescription {
  title?: string
  description?: string
}
export enum TitleDescriptionSourceEnum {
  PRODUCT_INTEGRATION = 'productIntegration',
  TEMPLATE_INTEGRATION = 'templateIntegration',
  PRODUCT = 'product',
}
export function getTitleSource<
  T extends TitleDescription,
  J extends TitleDescription,
>(
  productIntegration?: T,
  templateIntegration?: J,
  product?: GetProduct,
): TitleDescriptionSourceEnum | undefined {
  if (productIntegration?.title) {
    return TitleDescriptionSourceEnum.PRODUCT_INTEGRATION
  } else if (templateIntegration?.title) {
    return TitleDescriptionSourceEnum.TEMPLATE_INTEGRATION
  } else if (product?.product.title) {
    return TitleDescriptionSourceEnum.PRODUCT
  } else {
    return undefined
  }
}

export function getDescriptionSource<
  T extends TitleDescription,
  J extends TitleDescription,
>(
  productIntegration?: T,
  templateIntegration?: J,
  product?: GetProduct,
): TitleDescriptionSourceEnum | undefined {
  if (productIntegration?.description) {
    return TitleDescriptionSourceEnum.PRODUCT_INTEGRATION
  } else if (templateIntegration?.description) {
    return TitleDescriptionSourceEnum.TEMPLATE_INTEGRATION
  } else if (product?.product.description) {
    return TitleDescriptionSourceEnum.PRODUCT
  } else {
    return undefined
  }
}

export function getTitleValue<
  T extends TitleDescription,
  J extends TitleDescription,
>(
  productIntegration?: T,
  templateIntegration?: J,
  product?: GetProduct,
): string {
  return (
    productIntegration?.title ||
    templateIntegration?.title ||
    product?.product.title ||
    ''
  )
}

export function getDescriptionValue<
  T extends TitleDescription,
  J extends TitleDescription,
>(
  productIntegration?: T,
  templateIntegration?: J,
  product?: GetProduct,
): string {
  return (
    productIntegration?.description ||
    templateIntegration?.description ||
    product?.product.description ||
    ''
  )
}

export function removeElements(array: string[], remove: string[]): string[] {
  const newArray: string[] = []
  array.forEach((a) => {
    if (!remove.includes(a)) {
      newArray.push(a)
    }
  })
  return newArray
}

export function valueFields<T extends IdObject>(obj: T): ValueFields<T> {
  const { id, ...rest } = obj
  return rest
}

export function dataFields<T extends DataObject>(obj: T): DataFields<T> {
  const { id, active, createdAt, updatedAt, ...rest } = obj
  return rest
}

export function objectFields<T extends DataObject>(obj: T): ObjectFields<T> {
  const { active, createdAt, updatedAt, ...rest } = obj
  return rest
}

export function arrayToRecord<T>(
  array: T[] | undefined,
  getKey: (element: T) => string,
): Record<string, T> {
  if (!array) return {}
  const record: Record<string, T> = {}
  array.forEach((element) => {
    record[getKey(element)] = element
  })
  return record
}

export function reduceArrayToRecord<T>(
  array: T[] | undefined,
  getKey: (element: T) => string | undefined,
): Record<string, T[]> {
  if (!array) return {}
  const record: Record<string, T[]> = {}

  array?.forEach((element) => {
    const key = getKey(element)
    if (!key) return
    record[key] = (record[key] || [])?.concat([element])
  })

  return record
}

export function arrayToValueRecord<T, K>(
  array: T[] | undefined,
  getKey: (element: T) => string | undefined,
  getValue: (element: T) => K,
): Record<string, K> {
  if (!array) return {}
  const record: Record<string, K> = {}

  array?.forEach((element) => {
    const key = getKey(element)
    if (!key) return
    record[key] = getValue(element)
  })

  return record
}

export function arrayToNamedRecord<T extends { name: string }>(
  array: T[] | undefined,
): Record<string, T> {
  if (!array) return {}
  const record: Record<string, T> = {}
  array.forEach((element) => {
    record[element.name] = element
  })
  return record
}

export function getSelectedProductIntegration<T extends IntegrationName>(
  productIntegrations: ProductIntegrationType<T>[] | undefined,
): ProductIntegrationType<T> | undefined {
  if (!productIntegrations?.length) return undefined

  return (
    productIntegrations.find((p) => p.selected) ||
    productIntegrations.find((p) => p.index === 0)
  )
}

export function getFloat(value: string | undefined | null): number {
  if (
    !value ||
    value === '' ||
    value === 'undefined' ||
    value === 'null' ||
    value === null
  )
    return 0
  return value ? parseFloat(value) : 0
}
