import React, { useEffect, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  EtsyProductIntegration,
  EtsyProductPropertyIntegration,
  EtsyTemplatePropertyIntegration,
  EtsyShopReturnPolicy,
  EtsyShopShippingProfile,
  EtsyTaxonomyNode,
  EtsyTaxonomyProperty,
  EtsyTemplateIntegration,
  EtsyValueMapping,
  etsyWhoMadeDisplayMap,
  etsyWhenMadeDisplayMap,
  etsyWhenMadeValueMap,
  etsyWhoMadeValueMap,
} from '../../../../types/Etsy.types'
import { getEtsyTaxonomyProperties } from '../../../../api/integrations/etsy'
import {
  arrayToRecord,
  copy,
  err,
  getAttributeByTag,
  getAttributeName,
  getAttributeTag,
  isDefined,
  isLike,
  unique,
} from '../../../../utils/functions'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Button from '@mui/joy/Button'
import {
  GetProduct,
  GetProductTemplate,
  GetProductTemplateAttribute,
  ProductTemplateAttributeInput,
  updateProductTemplateAttributes,
} from '../../../../api/product'
import AspectExamples from '../ebay/AspectExamples'
import {
  GetIntegrationResult,
  IntegrationName,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import { extractAttributeName } from '../../../../classes/Parser'
import { DataFields } from '../../../../api/types'
import { DEFAULT_ATTRIBUTE_TYPE } from '../../../../utils/constants'
import SingleSelect from '../../../common/SingleSelect'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import ListSyncComponent from '../common/ListSyncComponent'
import MissingIntegration from '../MissingIntegration'
import FloatLabelInput from '../../../common/FloatLabelInput'
import AttributeSelect from '../../../common/AttributeSelect'
import NamedSingleSelect from '../../../common/NamedSingleSelect'
import NamedInput from '../../../common/NamedInput'
import Visibility from '@mui/icons-material/Visibility'
import AlternateEmail from '@mui/icons-material/AlternateEmail'
import VerticalIconMenu from '../../../common/VerticalIconMenu'
import ValueMappingModal from '../common/ValueMappingModal'
import { getEbayAttributeAspectValues } from '../../../../api/integrations/ebay'
import { EbayAttributeAspectValues } from '../../../../types/Ebay.types'
import { PRODUCT_FIELD_NAMES } from '../../../../types'
import ListUnlistButtons from '../common/ListUnlistButtons'

interface EtsyIntegrationComponentProps {
  integration: GetIntegrationResult<IntegrationName.ETSY>
  templateIntegration: EtsyTemplateIntegration
  productIntegration?: EtsyProductIntegration
  taxonomy: EtsyTaxonomyNode[]
  shippingProfiles: EtsyShopShippingProfile[]
  returnPolicies: EtsyShopReturnPolicy[]
  template: GetProductTemplate
  product?: GetProduct
  onTemplateChange?: (
    name: string,
    templateIntegration: DataFields<EtsyTemplateIntegration> | undefined,
  ) => void
  onProductChange?: (
    name: string,
    productIntegration: DataFields<EtsyProductIntegration> | undefined,
  ) => void
  onUpdate?: () => void
}

const createReturnPolicyTag = (p: EtsyShopReturnPolicy | undefined): string => {
  if (!p) return ''
  return `${p.accepts_returns ? 'Returns, ' : 'No Returns, '}${
    p.accepts_exchanges ? 'Exchanges, ' : 'No Exchanges, '
  }${p.return_deadline} Days`
}

export default function EtsyIntegrationComponent({
  integration,
  templateIntegration,
  productIntegration,
  taxonomy,
  shippingProfiles,
  returnPolicies,
  template: defaultTemplate,
  product,
  onTemplateChange,
  onProductChange,
  onUpdate,
}: EtsyIntegrationComponentProps): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [template, setTemplate] = useState<GetProductTemplate>(defaultTemplate)

  const [title, setTitle] = useState<string | undefined>(
    templateIntegration.title,
  )
  const [description, setDescription] = useState<string | undefined>(
    templateIntegration.description,
  )
  const [materials, setMaterials] = useState<string>(
    templateIntegration.materials,
  )

  const [list, setList] = useState<boolean>(templateIntegration.list ?? false)
  const [sync, setSync] = useState<boolean>(templateIntegration.sync ?? false)
  const [syncQuantity, setSyncQuantity] = useState<boolean>(
    templateIntegration.syncQuantity ?? false,
  )

  const [detailUpdate, setDetailUpdate] = useState<number>(0)

  const [selectedShippingProfileName, setSelectedShippingProfileName] =
    useState<string>(
      shippingProfiles.find(
        (s) =>
          `${s.shipping_profile_id}` === templateIntegration.shippingProfileId,
      )?.title || '',
    )

  const [selectedReturnPolicyName, setSelectedReturnPolicyName] =
    useState<string>(
      createReturnPolicyTag(
        returnPolicies.find(
          (r) => `${r.return_policy_id}` === templateIntegration.returnPolicyId,
        ),
      ),
    )
  const [whoMade, setWhoMade] = useState<string>(
    productIntegration?.whoMade ?? templateIntegration.whoMade ?? '',
  )
  const [whenMade, setWhenMade] = useState<string>(
    productIntegration?.whenMade ?? templateIntegration.whenMade ?? '',
  )

  const [taxonomyProperties, setTaxonomyProperties] = useState<
    EtsyTaxonomyProperty[]
  >([])
  const [filteredTaxonomyNodes, setFilteredTaxonomyNodes] = useState<
    EtsyTaxonomyNode[]
  >([])
  const [taxonomySearch, setTaxonomySearch] = useState<string>('')
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<
    { id: number; name: string } | undefined
  >(
    templateIntegration.taxonomyId
      ? {
          id: parseInt(templateIntegration.taxonomyId),
          name: templateIntegration.taxonomyName,
        }
      : undefined,
  )

  const [
    etsyTemplatePropertyIntegrations,
    setEtsyTemplatePropertyIntegrations,
  ] = useState<EtsyTemplatePropertyIntegration[]>(
    templateIntegration.properties ?? [],
  )

  const [etsyProductPropertyIntegrations, setEtsyProductPropertyIntegrations] =
    useState<EtsyProductPropertyIntegration[]>(
      productIntegration?.properties?.length
        ? productIntegration?.properties
        : templateIntegration?.properties?.map(
            (p): EtsyProductPropertyIntegration => ({
              propertyId: p.propertyId,
              propertyName: p.propertyName,
              valueIds: p.valueIds,
              values: p.values,
              scaleId: p.scaleId,
              dataType: p.dataType,
              required: p.required,
            }),
          ) || [],
    )

  const [
    propertyTemplateIntegrationRecord,
    setPropertyTemplateIntegrationRecord,
  ] = useState<Record<string, DataFields<EtsyTemplatePropertyIntegration>>>(
    arrayToRecord(
      etsyTemplatePropertyIntegrations || [],
      (p) => p.propertyName,
    ),
  )

  const [propertyTemplateIntegrationIdRecord] = useState<
    Record<string, DataFields<EtsyTemplatePropertyIntegration>>
  >(arrayToRecord(etsyTemplatePropertyIntegrations || [], (p) => p.propertyId))

  const [
    propertyProductIntegrationRecord,
    setPropertyProductIntegrationRecord,
  ] = useState<Record<string, DataFields<EtsyProductPropertyIntegration>>>(
    arrayToRecord(etsyProductPropertyIntegrations || [], (p) => p.propertyName),
  )

  const [parseDetails, setParseDetails] = useState<boolean>(true)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedApplyTo, setSelectedApplyTo] = useState<string>('')

  // Etsy Template Value Mapping propertyName => { etsyValue: mapValues[] }
  const [
    etsyTemplatePropertyValueMapping,
    setEtsyTemplatePropertyValueMapping,
  ] = useState<Record<string, Record<string, string[]>>>({})

  // Etsy Property Value Mapping propertyId => { valueId: mapValues[] }
  const [etsyPropertyValueMapping, setEtsyPropertyValueMapping] = useState<
    Record<string, Record<string, string[]>>
  >({})

  // Etsy Attribute Value Mapping applyTo: => { value: mapValues[] }
  const [etsyAttributeValueMapping, setEtsyAttributeValueMapping] = useState<
    Record<string, DataFields<EtsyValueMapping>[]>
  >({})

  const valueMapRecordToArray = (
    etsyTemplatePropertyValueMapping: Record<string, Record<string, string[]>>,
  ): DataFields<EtsyValueMapping>[] => {
    if (!integration?.integration?.id) return []
    const templatePropertyValueMappings = Object.entries(
      etsyTemplatePropertyValueMapping,
    )
      .map(([propertyName, valueMap]) => {
        if (valueMap === undefined) return undefined

        const templatePropertyIntegration =
          propertyTemplateIntegrationRecord[propertyName]

        const templatePropertyIntegrationId = templatePropertyIntegration?.id
        if (!templatePropertyIntegrationId || !integration?.integration?.id)
          return undefined
        const valueMapping: DataFields<EtsyValueMapping>[] = Object.entries(
          valueMap,
        ).map(([valueName, mapValues], i) => {
          return {
            integrationId: integration.integration?.id || 0,
            templateIntegrationId: templateIntegration.id,
            templatePropertyIntegrationId,
            value: valueName,
            index: i,
            values: mapValues.map((v) => ({ value: v })),
          }
        })
        return valueMapping
      })
      .flat()
      .filter(isDefined)

    const globalPropertyValueMappings = Object.entries(etsyPropertyValueMapping)
      .map(([propertyId, valueMap]) => {
        const valueMapping: DataFields<EtsyValueMapping>[] = Object.entries(
          valueMap,
        )
          .map(([valueId, mapValues], i) => {
            if (mapValues === undefined) return undefined
            return {
              integrationId: integration.integration?.id || 0,
              propertyId: propertyId,
              valueId: valueId,
              value: '',
              index: i,
              values: mapValues.map((v) => ({ value: v })),
            }
          })
          .filter(isDefined)
        return valueMapping
      })
      .flat()

    return templatePropertyValueMappings.concat(globalPropertyValueMappings)
  }

  const handleTemplatePropertyValueMappingUpdate = (
    propertyName: string,
    value: string,
    values: string[],
  ) => {
    const newEtsyValueMapping = copy(etsyTemplatePropertyValueMapping)
    const updatedEtsyValueMapping: Record<string, Record<string, string[]>> = {
      ...(newEtsyValueMapping || {}),
      [propertyName]: {
        ...(newEtsyValueMapping?.[propertyName] || {}),
        [value]: values,
      },
    }

    setEtsyTemplatePropertyValueMapping(updatedEtsyValueMapping)
  }

  const handleAttributeValueMappingUpdate = (
    applyTo: string,
    attributeName: string,
    value: string,
    values: string[],
  ) => {
    const attribute = getAttributeByTag(product, attributeName)

    const newEtsyValueMapping = copy(etsyAttributeValueMapping)
    const applyToValueMapping = newEtsyValueMapping[applyTo]
    const applyToValueValueMappings = applyToValueMapping?.filter(
      (v) => v.value !== value,
    )
    const existingApplyToValueValueMappings = applyToValueMapping?.find(
      (v) => v.value === value,
    )
    const updatedEtsyValueMapping = {
      ...newEtsyValueMapping,
      [applyTo]: (applyToValueValueMappings || []).concat({
        index: existingApplyToValueValueMappings?.index || 0,
        integrationId: templateIntegration.integrationId,
        templateIntegrationId: templateIntegration.id,
        attributeId: attribute?.templateAttribute.id,
        applyTo: applyTo,
        value: value,
        values: values.map((v) => ({ value: v })),
      }),
    }

    setEtsyAttributeValueMapping(updatedEtsyValueMapping)
  }

  const valueMappingArrayToRecord = (
    valueMapping?: DataFields<EtsyValueMapping>[],
  ) => {
    if (!valueMapping) return {}
    const record: Record<string, string[]> = {}
    valueMapping.forEach((v) => {
      record[v.value] = v.values?.map((v) => v.value) || []
    })

    return record
  }

  useEffect(() => {
    const etsyValueMapping: DataFields<EtsyValueMapping>[] | undefined =
      templateIntegration.valueMappings
    if (!etsyValueMapping) return
    const templatePropertyRecord: Record<string, Record<string, string[]>> = {}
    const propertyRecord: Record<string, Record<string, string[]>> = {}
    const attributeRecord: Record<string, DataFields<EtsyValueMapping>[]> = {}

    etsyValueMapping.forEach((valueMapping) => {
      const templatePropertyIntegrationId =
        valueMapping.templatePropertyIntegrationId
      const propertyId = valueMapping.propertyId
      const templatePropertyIntegration =
        templatePropertyIntegrationId &&
        propertyTemplateIntegrationIdRecord[templatePropertyIntegrationId]

      const valueId = valueMapping.valueId
      const mapValue = valueMapping.value
      const applyTo = valueMapping.applyTo
      // const attributeId = valueMapping.attributeId
      const mapValues = valueMapping.values?.map((v) => v.value) || []

      // template property
      if (templatePropertyIntegration) {
        templatePropertyRecord[templatePropertyIntegration.propertyName] = {
          [mapValue]: mapValues,
        }

        // global etsy property
      } else if (propertyId && valueId) {
        propertyRecord[propertyId] = {
          [valueId]: mapValues,
        }

        // attribute applyTo
        // and attributeId?
      } else if (applyTo) {
        attributeRecord[applyTo] = (
          (attributeRecord[applyTo] || []) as DataFields<EtsyValueMapping>[]
        ).concat(valueMapping)
      }
    })

    setEtsyTemplatePropertyValueMapping(templatePropertyRecord)
    setEtsyPropertyValueMapping(propertyRecord)
    setEtsyAttributeValueMapping(attributeRecord)
    setDetailUpdate(detailUpdate + 1)
    // return templatePropertyRecord
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateIntegration?.properties, templateIntegration.valueMappings])

  const [attributeAspectValues, setAttributeAspectValues] = useState<
    EbayAttributeAspectValues | undefined
  >()

  const templateAttributesRecord = useMemo(() => {
    return arrayToRecord(template?.attributes, (e) => e.attribute.name)
  }, [template?.attributes])

  useEffect(() => {
    const templatePropertyIntegrations =
      etsyTemplatePropertyIntegrations.slice()
    const productPropertyIntegrations = etsyProductPropertyIntegrations.slice()
    taxonomyProperties?.forEach((property) => {
      const propertyIntegration =
        propertyTemplateIntegrationRecord[property.display_name]

      const attribute = templateAttributesRecord[property.display_name]
      if (!propertyIntegration && attribute?.attribute.name) {
        templatePropertyIntegrations.push({
          propertyName: property.display_name,
          propertyId: `${property.property_id}`,
          templateIntegrationId: templateIntegration.id,
          values: getAttributeTag(attribute?.attribute.name),
          required: property.is_required,
        })
      }
    })

    setEtsyProductPropertyIntegrations(productPropertyIntegrations)
    setEtsyTemplatePropertyIntegrations(templatePropertyIntegrations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxonomyProperties])

  useEffect(() => {
    const templateId = template.template.id
    if (!templateId) return
    getEbayAttributeAspectValues(templateId)
      .then((res) => {
        if (res.success && res.data) {
          setAttributeAspectValues(res.data)
        }
      })
      .catch((e) => err(e))
  }, [template.template.id])

  useEffect(() => {
    if (!integration.integration?.id) return

    const shippingProfileIdNum = shippingProfiles.find(
      (p) => p.title === selectedShippingProfileName,
    )?.shipping_profile_id
    const shippingProfileId = shippingProfileIdNum
      ? `${shippingProfileIdNum}`
      : ''

    const returnPolicyIdNum = returnPolicies.find(
      (p) => createReturnPolicyTag(p) === selectedReturnPolicyName,
    )?.return_policy_id
    const returnPolicyId = returnPolicyIdNum ? `${returnPolicyIdNum}` : ''

    const valueMappings: DataFields<EtsyValueMapping>[] = valueMapRecordToArray(
      etsyTemplatePropertyValueMapping,
    )

    const attributeValueMapping = Object.values(
      etsyAttributeValueMapping,
    ).flat()

    if (product) {
      const newProductIntegration: Omit<
        DataFields<EtsyProductIntegration>,
        'clientId'
      > = {
        id: templateIntegration.id,
        productId: product.product.id,
        name: templateIntegration.name,
        index: templateIntegration.index,
        selected: productIntegration?.selected || false,
        templateIntegrationId: templateIntegration.id,
        title: title || '',
        description: description,
        materials: materials,
        whenMade: whenMade,
        whoMade: whoMade,
        shippingProfileId: shippingProfileId,
        returnPolicyId: returnPolicyId,
        list,
        sync,
        syncQuantity,
        properties: Object.values(propertyProductIntegrationRecord),
      }
      onProductChange?.(templateIntegration.name, newProductIntegration)
    }

    if (!product) {
      const newTemplateIntegration: Omit<
        DataFields<EtsyTemplateIntegration>,
        'clientId'
      > = {
        id: templateIntegration.id,
        name: templateIntegration.name,
        index: templateIntegration.index,
        integrationId:
          templateIntegration.integrationId || integration.integration?.id,
        templateId: template?.template.id,
        taxonomyId: selectedTaxonomy?.id ? `${selectedTaxonomy.id}` : '0',
        taxonomyName: selectedTaxonomy?.name || '',
        title: title || '',
        description: description,
        materials: materials,
        whenMade: whenMade,
        whoMade: whoMade,
        shippingProfileId: shippingProfileId,
        returnPolicyId: returnPolicyId,
        list,
        sync,
        syncQuantity,
        properties: Object.values(propertyTemplateIntegrationRecord),
        valueMappings: valueMappings.concat(attributeValueMapping),
      }

      onTemplateChange?.(templateIntegration.name, newTemplateIntegration)
    } else {
      const newTemplateIntegration: Omit<
        DataFields<EtsyTemplateIntegration>,
        'clientId'
      > = {
        ...templateIntegration,
        properties: Object.values(propertyTemplateIntegrationRecord),
        valueMappings: valueMappings.concat(attributeValueMapping),
      }

      onTemplateChange?.(templateIntegration.name, newTemplateIntegration)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    description,
    materials,
    propertyTemplateIntegrationRecord,
    propertyProductIntegrationRecord,
    selectedReturnPolicyName,
    selectedShippingProfileName,
    selectedTaxonomy,
    list,
    sync,
    syncQuantity,
    whenMade,
    whoMade,
    etsyTemplatePropertyValueMapping,
    etsyPropertyValueMapping,
    etsyAttributeValueMapping,
  ])

  useEffect(() => {
    if (!taxonomySearch) {
      setFilteredTaxonomyNodes(taxonomy)
      return
    }

    const filterTaxonomy = (nodes: EtsyTaxonomyNode[]): EtsyTaxonomyNode[] =>
      nodes
        .map((node): EtsyTaxonomyNode | undefined => {
          if (node.name.toLowerCase().includes(taxonomySearch.toLowerCase())) {
            return node
          } else {
            const childrenIncluded = filterTaxonomy(node.children)
            if (childrenIncluded?.length) {
              return { ...node, children: childrenIncluded }
            }
            return undefined
          }
        })
        .filter(isDefined)

    const filteredTaxonomy = filterTaxonomy(taxonomy)
    setFilteredTaxonomyNodes(filteredTaxonomy)
  }, [taxonomy, taxonomySearch])

  useEffect(() => {
    if (!selectedTaxonomy?.id || !taxonomy.length) return
    getEtsyTaxonomyProperties(selectedTaxonomy.id)
      .then((res) => {
        if (res.success && res.data) {
          setTaxonomyProperties(res.data)
        }
      })
      .catch((e) => err(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaxonomy?.id])

  const handleTaxonomyChange = (id: number, name: string) => {
    setSelectedTaxonomy({ id, name })
  }

  const handleToggleParseDetails = () => {
    setParseDetails(!parseDetails)
  }

  const handlePropertyProductAttributeChange = (
    property: EtsyTaxonomyProperty,
    value: string | undefined,
    scaleName?: string,
  ) => {
    const newPropertyProductIntegrationRecord = copy(
      propertyProductIntegrationRecord,
    )
    const propertyProductIntegration =
      newPropertyProductIntegrationRecord[property.display_name]

    const scaleIdNum = property.scales.find(
      (scale) => scale.display_name === scaleName,
    )?.scale_id
    const scaleId = scaleIdNum ? `${scaleIdNum}` : undefined

    if (!propertyProductIntegration) {
      newPropertyProductIntegrationRecord[property.display_name] = {
        productIntegrationId: productIntegration?.id || 0,
        propertyId: `${property.property_id}`,
        propertyName: property.display_name,
        values: value,
        scaleId: scaleId,
        required: property.is_required,
      }

      setPropertyProductIntegrationRecord(newPropertyProductIntegrationRecord)
      return
    }

    if (value !== undefined) {
      propertyProductIntegration.values = value
    }
    if (scaleId) {
      propertyProductIntegration.scaleId = scaleId
    }

    newPropertyProductIntegrationRecord[property.display_name] =
      propertyProductIntegration

    setPropertyProductIntegrationRecord(newPropertyProductIntegrationRecord)
  }

  const handlePropertyTemplateAttributeChange = (
    property: EtsyTaxonomyProperty,
    value: string | undefined,
    scaleName?: string,
  ) => {
    const newPropertyTemplateIntegrationRecord = copy(
      propertyTemplateIntegrationRecord,
    )
    const propertyTemplateIntegration =
      newPropertyTemplateIntegrationRecord[property.display_name]

    const scaleIdNum = property.scales.find(
      (scale) => scale.display_name === scaleName,
    )?.scale_id
    const scaleId = scaleIdNum ? `${scaleIdNum}` : undefined

    if (!propertyTemplateIntegration) {
      newPropertyTemplateIntegrationRecord[property.display_name] = {
        templateIntegrationId: templateIntegration?.id || 0,
        propertyId: `${property.property_id}`,
        propertyName: property.display_name,
        values: value,
        scaleId: scaleId,
        required: property.is_required,
      }

      setPropertyTemplateIntegrationRecord(newPropertyTemplateIntegrationRecord)
      return
    }

    if (value !== undefined) {
      propertyTemplateIntegration.values = value
    }
    if (scaleId) {
      propertyTemplateIntegration.scaleId = scaleId
    }

    newPropertyTemplateIntegrationRecord[property.display_name] =
      propertyTemplateIntegration

    setPropertyTemplateIntegrationRecord(newPropertyTemplateIntegrationRecord)
  }

  const handleAddTemplateAttributeOption = async (
    selectedTemplateAttribute: GetProductTemplateAttribute | undefined,
    options: string[],
  ) => {
    if (!selectedTemplateAttribute) return
    // Get template id from template attribute
    const templateId = selectedTemplateAttribute.attribute.templateId
    const templateAttributeName = selectedTemplateAttribute.attribute.name

    const newIndex = template?.attributes.length // length+1
    const newOptionIndex = selectedTemplateAttribute.attributeOptions.length // length+1
    if (!templateId || !templateAttributeName) return

    // add attribute with options
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: {
        name: templateAttributeName,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newIndex,
      },
      attributeOptions: options.map((o) => ({
        value: o,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newOptionIndex,
      })),
    }

    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success && res.data) {
      const newTemplate = copy(template)
      const updateIndex = newTemplate.attributes.findIndex(
        (a) => a.attribute.name === templateAttributeName,
      )
      if (
        updateIndex < 0 ||
        !res.data?.[0] ||
        !newTemplate.attributes[updateIndex]
      )
        return
      const newTemplateAttribute =
        newTemplate.attributes[updateIndex]?.attribute

      const newTemplateAttributeOptions =
        newTemplate.attributes[updateIndex]?.attributeOptions

      if (!newTemplateAttribute) return
      newTemplate.attributes[updateIndex] = {
        attribute: newTemplateAttribute,
        attributeOptions:
          newTemplateAttributeOptions?.concat(res.data?.[0].attributeOptions) ||
          [],
      }
      setTemplate(newTemplate)
      // onUpdate?.()
    }
  }

  const handleAddTemplateAttribute = async (
    property: EtsyTaxonomyProperty,
    name: string,
  ) => {
    // Get template id from first template attribute
    const templateId = template?.attributes?.[0]?.attribute.templateId
    const newIndex = template?.attributes?.length // length+1
    if (!templateId) return
    // add attribute
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: {
        name: name,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newIndex,
      },
      attributeOptions: [],
    }
    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success && res.data) {
      const newTemplate = copy(template)
      const newAttributes = newTemplate.attributes.slice()
      newTemplate.attributes = newAttributes.concat(res.data)
      setTemplate(newTemplate)
      // onUpdate?.()
    }

    handlePropertyTemplateAttributeChange(property, getAttributeTag(name))
  }

  const handleWhoMade = (whoMadeDisplay: string) => {
    setWhoMade(etsyWhoMadeValueMap[whoMadeDisplay] || whoMadeDisplay)
  }

  const handleWhenMade = (whenMadeDisplay: string) => {
    setWhenMade(etsyWhenMadeValueMap[whenMadeDisplay] || whenMadeDisplay)
  }

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.ETSY, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        setAlert({
          open: true,
          severity: 'error',
          message: `${e.response.data.message}`,
        })
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!templateIntegration?.integrationId || !productId) return
    unlistProduct(
      IntegrationName.ETSY,
      productId,
      templateIntegration?.integrationId,
    )
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  if (!integration) {
    return <MissingIntegration />
  }

  const attributeNames = template?.attributes
    .map((a) => a.attribute.name)
    .concat(Object.keys(PRODUCT_FIELD_NAMES))

  return (
    <Grid container spacing={6} pt={2} px={1}>
      {/* List Sync */}
      <Grid item xs={12}>
        <ListSyncComponent
          title={true}
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onListChange={setList}
          onSyncChange={setSync}
          onSyncQuantityChange={setSyncQuantity}
        />
      </Grid>

      {/* Category */}
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography level="title-lg">Category</Typography>
          </Grid>
          <Grid item xs={12}>
            {selectedTaxonomy?.id ? (
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <NamedInput
                    name="Category"
                    value={selectedTaxonomy.name}
                    disabled={true}
                    autoComplete="off"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={false}
                    size="sm"
                    variant="plain"
                    onClick={() => setSelectedTaxonomy(undefined)}
                    sx={{ float: 'right', mt: '3px' }}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={12}>
                    <FloatLabelInput
                      fullWidth
                      label="Search Categories"
                      value={taxonomySearch}
                      onChange={(v) => setTaxonomySearch(v)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        px: 12,
                        pt: 4,
                        backgroundColor: '#FBFCFE',
                        borderStyle: 'solid',
                        borderRadius: '6px',
                        borderColor:
                          'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
                        boxShadow:
                          '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08)',
                        padding: '1em',
                      }}
                    >
                      <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{
                          height: '300px',
                          flexGrow: 1,
                          overflowY: 'auto',
                        }}
                      >
                        {filteredTaxonomyNodes.map((node) => {
                          return (
                            <DisplayTaxonomyNode
                              key={node.id}
                              node={node}
                              onChange={(id, name) =>
                                handleTaxonomyChange(id, name)
                              }
                            />
                          )
                        })}
                      </TreeView>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Title Desc */}
      <Grid item xs={12}>
        <ProductTitleDescription
          key={`product-title-description-${detailUpdate}`}
          template={template}
          product={product}
          valueMapping={(etsyAttributeValueMapping['title'] || [])?.concat(
            etsyAttributeValueMapping['description'] || [],
          )}
          title={title}
          description={description}
          onTitleChange={setTitle}
          onDescriptionChange={setDescription}
          disableUnparsedEdit={true}
          onAttributeClick={(attributeName, element) => {
            setSelectedApplyTo(element || '')
            setModalOpen(true)
          }}
        />
      </Grid>

      {/* Misc */}
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <AttributeSelect
              name="Materials"
              nameContainer={true}
              attributeNames={attributeNames}
              freeSolo={true}
              multiple={true}
              maxLength={13}
              values={materials ? materials.split(', ') : []}
              onSelect={(values) => {
                Array.isArray(values) && setMaterials(values.join(', '))
              }}
              template={template}
              templateIntegration={templateIntegration}
              product={product}
              valueMapping={valueMappingArrayToRecord(
                etsyAttributeValueMapping?.['materials'],
              )}
              attributeValueMapping={etsyAttributeValueMapping?.['materials']}
              endDecorator={
                <VerticalIconMenu
                  size="small"
                  options={['Value Mapping']}
                  onClick={() => {
                    setSelectedApplyTo('materials')
                    setModalOpen(true)
                  }}
                  p={0}
                />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <AttributeSelect
              name="Who Made"
              nameContainer={true}
              options={Object.values(etsyWhoMadeDisplayMap)}
              allowedValues={Object.values(etsyWhoMadeDisplayMap)}
              freeSolo={false}
              value={etsyWhoMadeDisplayMap[whoMade] || whoMade}
              onChange={(whoMade) => {
                handleWhoMade(whoMade || '')
              }}
              attributeNames={attributeNames}
              valueMapping={valueMappingArrayToRecord(
                etsyAttributeValueMapping?.['who_made'],
              )}
              onValueMappingChange={(value, values) => {
                handleAttributeValueMappingUpdate(
                  'who_made',
                  whoMade,
                  value,
                  values,
                )
              }}
              template={template}
              templateIntegration={templateIntegration}
              product={product}
              required={true}
            />
          </Grid>

          <Grid item xs={12}>
            <AttributeSelect
              name="When Made"
              nameContainer={true}
              options={Object.values(etsyWhenMadeDisplayMap)}
              allowedValues={Object.values(etsyWhenMadeDisplayMap)}
              freeSolo={false}
              value={etsyWhenMadeDisplayMap[whenMade] || whenMade}
              onChange={(whenMade) => {
                handleWhenMade(whenMade || '')
              }}
              attributeNames={attributeNames}
              valueMapping={valueMappingArrayToRecord(
                etsyAttributeValueMapping?.['when_made'],
              )}
              onValueMappingChange={(value, values) => {
                handleAttributeValueMappingUpdate(
                  'when_made',
                  whenMade,
                  value,
                  values,
                )
              }}
              templateIntegration={templateIntegration}
              template={template}
              product={product}
              required={true}
            />
          </Grid>

          <Grid item xs={12}>
            <NamedSingleSelect
              key={selectedShippingProfileName}
              nameContainer={true}
              name="Shipping Profile"
              placeholder="Shipping Profile"
              nameEditable={false}
              required={true}
              options={shippingProfiles.map((p) => `${p.title}`)}
              value={selectedShippingProfileName}
              onChange={(value) => setSelectedShippingProfileName(value || '')}
              disabled={!shippingProfiles?.length}
              helperText={
                !shippingProfiles?.length ? (
                  <Typography level="body-xs">
                    No shipping profiles found, Create Etsy Shipping Profiles{' '}
                    <a
                      target="__blank"
                      href="https://www.etsy.com/your/shops/me/tools/shipping-profiles"
                    >
                      here
                    </a>
                  </Typography>
                ) : undefined
              }
            ></NamedSingleSelect>
          </Grid>

          <Grid item xs={12}>
            <NamedSingleSelect
              key={selectedReturnPolicyName}
              nameContainer={true}
              name="Return Policy"
              placeholder="Return Policy"
              required={true}
              nameEditable={false}
              options={returnPolicies.map((p) => createReturnPolicyTag(p))}
              value={selectedReturnPolicyName}
              onChange={(value) => setSelectedReturnPolicyName(value || '')}
              disabled={!returnPolicies?.length}
              helperText={
                !returnPolicies?.length ? (
                  <Typography level="body-xs">
                    No return policies. Create Etsy Return Policies{' '}
                    <a
                      target="__blank"
                      href="https://help.etsy.com/hc/en-us/articles/360000572888-Refunds-Returns-and-Exchanges-for-Sellers?segment=selling"
                    >
                      here
                    </a>
                  </Typography>
                ) : undefined
              }
            ></NamedSingleSelect>
          </Grid>
        </Grid>
      </Grid>

      {/* Properties */}
      {taxonomyProperties?.length ? (
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={1}></Grid>

                <Grid item xs={10}>
                  <Typography level="h4">Attributes</Typography>
                </Grid>

                <Grid item xs={1}>
                  {product && (
                    <Button
                      sx={{ float: 'right' }}
                      size="sm"
                      variant="plain"
                      color="neutral"
                      onClick={handleToggleParseDetails}
                      endDecorator={
                        !parseDetails ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <AlternateEmail fontSize="small" />
                        )
                      }
                    >
                      <Typography level="body-sm">
                        {!parseDetails ? 'Parse' : 'View'}
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={1}>
                {!product &&
                  taxonomyProperties.map((property) => {
                    const propertyIntegration =
                      propertyTemplateIntegrationRecord[property.display_name]

                    const attributeMatch = template?.attributes.find((a) =>
                      isLike(a.attribute.name, property.display_name),
                    )

                    let templateAttribute:
                      | GetProductTemplateAttribute
                      | undefined

                    const attributeValuesName = extractAttributeName(
                      propertyIntegration?.values,
                    )

                    // If undefined
                    if (
                      propertyIntegration?.values === undefined &&
                      attributeMatch
                    ) {
                      // If undefined set matched
                      templateAttribute = attributeMatch
                      const attributeTag = getAttributeTag(
                        attributeMatch.attribute.name,
                      )
                      if (attributeTag) {
                        handlePropertyTemplateAttributeChange(
                          property,
                          attributeTag,
                        )
                      }
                    }
                    if (attributeValuesName) {
                      templateAttribute = template?.attributes.find(
                        (attribute) =>
                          attribute.attribute.name === attributeValuesName,
                      )
                    }

                    return (
                      <Grid item xs={12} key={property.property_id}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={12} md={3}>
                            <Typography
                              sx={{
                                border: '1px solid #e8e9eb',
                                lineHeight: '34px',
                                px: '8px',
                                borderRadius: '6px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textAlign: 'left',
                                boxShadow:
                                  '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
                                color: '#32383E',
                                backgroundColor: '#FBFCFE',
                              }}
                            >
                              <strong title={`${property.display_name}`}>
                                {property.display_name}
                              </strong>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={
                              12 -
                              (property.possible_values.length ? 3 : 0) -
                              (property.scales.length ? 3 : 0)
                            }
                            md={
                              9 -
                              (property.possible_values.length ? 3 : 0) -
                              (property.scales.length ? 3 : 0)
                            }
                          >
                            <AttributeSelect
                              value={propertyIntegration?.values}
                              nameContainer={true}
                              attributeNames={attributeNames}
                              onChange={(newValue) => {
                                handlePropertyTemplateAttributeChange(
                                  property,
                                  newValue || '',
                                )
                              }}
                              onUpdate={onUpdate}
                              addOptions={
                                !attributeMatch ? [property.display_name] : []
                              }
                              onAddOptionClick={(name) =>
                                handleAddTemplateAttribute(property, name)
                              }
                              onValueMappingChange={(value, values) => {
                                handleTemplatePropertyValueMappingUpdate(
                                  property.display_name,
                                  value,
                                  values,
                                )
                              }}
                              valueMapping={
                                etsyTemplatePropertyValueMapping[
                                  property.display_name
                                ]
                              }
                              attributeName={property.display_name}
                              key={property.display_name + parseDetails}
                              parse={false}
                              multiple={false}
                              freeSolo={true}
                              nameValue={property.display_name}
                              required={property.is_required}
                              templateIntegration={templateIntegration}
                              template={template}
                              options={unique(
                                property.possible_values.map((o) => o.name),
                              )}
                              channelAttributeOptions={{
                                ebay: attributeAspectValues,
                              }}
                              // channelOptions={{
                              //   ebay: attributeAspectValues?.[attributeName],
                              // }}
                              allowedValues={property.possible_values.map(
                                (o) => o.name,
                              )}
                            />
                          </Grid>
                          {property.scales.length ? (
                            <Grid item xs={3} md={3}>
                              <SingleSelect
                                placeholder="Scales"
                                value={
                                  property.scales.find(
                                    (scale) =>
                                      `${scale.scale_id}` ===
                                      propertyIntegration?.scaleId,
                                  )?.display_name || ''
                                }
                                options={property.scales.map(
                                  (v) => v.display_name,
                                )}
                                onChange={(scaleName) =>
                                  handlePropertyTemplateAttributeChange(
                                    property,
                                    undefined,
                                    scaleName,
                                  )
                                }
                              />
                            </Grid>
                          ) : null}
                          {property.possible_values.length ? (
                            <Grid item xs={3} md={3}>
                              <AspectExamples
                                label="Allowed Values"
                                templateAttribute={templateAttribute}
                                options={property.possible_values.map(
                                  (v) => v.name,
                                )}
                                onChange={(options) =>
                                  handleAddTemplateAttributeOption(
                                    templateAttribute,
                                    options,
                                  )
                                }
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    )
                  })}

                {product &&
                  template &&
                  taxonomyProperties.map((property) => {
                    const propertyIntegration =
                      propertyProductIntegrationRecord[property.display_name]

                    const attributeName =
                      getAttributeName(propertyIntegration?.values) || ''

                    return (
                      <Grid item xs={12} key={property.property_id}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={12} md={3}>
                            <Typography
                              sx={{
                                border: '1px solid #e8e9eb',
                                lineHeight: '34px',
                                px: '8px',
                                borderRadius: '6px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textAlign: 'left',
                                boxShadow:
                                  '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
                                color: '#32383E',
                                backgroundColor: '#FBFCFE',
                              }}
                            >
                              <strong title={`${property.display_name}`}>
                                {property.display_name}
                              </strong>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12 - (property.scales.length ? 3 : 0)}
                            md={9 - (property.scales.length ? 3 : 0)}
                          >
                            <AttributeSelect
                              attributeName={property.display_name}
                              key={property.display_name + parseDetails}
                              value={propertyIntegration?.values}
                              parse={parseDetails}
                              multiple={false}
                              freeSolo={true}
                              nameValue={property.display_name}
                              required={property.is_required}
                              templateIntegration={templateIntegration}
                              template={template}
                              product={product}
                              attributeNames={attributeNames}
                              onChange={(newValue) =>
                                handlePropertyProductAttributeChange(
                                  property,
                                  newValue || '',
                                )
                              }
                              options={unique(
                                property.possible_values.map((o) => o.name),
                              )}
                              channelOptions={{
                                ebay: attributeAspectValues?.[attributeName],
                              }}
                              channelAttributeOptions={{
                                ebay: attributeAspectValues,
                              }}
                              onUpdate={onUpdate}
                              allowedValues={property.possible_values.map(
                                (o) => o.name,
                              )}
                              onValueMappingChange={(value, values) => {
                                handleTemplatePropertyValueMappingUpdate(
                                  property.display_name,
                                  value,
                                  values,
                                )
                              }}
                              valueMapping={
                                etsyTemplatePropertyValueMapping[
                                  property.display_name
                                ]
                              }
                            />
                          </Grid>
                          {property.scales.length ? (
                            <Grid item xs={3} md={3}>
                              <SingleSelect
                                placeholder="Scales"
                                value={
                                  property.scales.find(
                                    (scale) =>
                                      `${scale.scale_id}` ===
                                      propertyIntegration?.scaleId,
                                  )?.display_name || ''
                                }
                                options={property.scales.map(
                                  (v) => v.display_name,
                                )}
                                onChange={(scaleName) =>
                                  handlePropertyProductAttributeChange(
                                    property,
                                    undefined,
                                    scaleName,
                                  )
                                }
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    )
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={12} mt={8}>
        <ListUnlistButtons
          title={true}
          list={list}
          productIntegration={productIntegration}
          product={product}
          onList={handleListProduct}
          onUnlist={handleUnlistProduct}
        />
      </Grid>

      <Grid item xs={12} mt={4}>
        <Button
          variant="soft"
          color="danger"
          sx={{ float: 'left' }}
          onClick={() => {
            if (product && productIntegration) {
              onProductChange?.(templateIntegration.name, undefined)
            } else if (!product) {
              onTemplateChange?.(templateIntegration.name, undefined)
            }
          }}
        >
          Delete
        </Button>
      </Grid>

      <ValueMappingModal
        key={`value-map-modal-${selectedApplyTo}`}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        applyTo={{
          title: 'Title',
          description: 'Description',
          materials: 'Materials',
        }}
        selectedApplyTo={selectedApplyTo}
        valueMappingRecord={etsyAttributeValueMapping}
        onChange={(valueMapping) => {
          setEtsyAttributeValueMapping(valueMapping)
        }}
        template={template}
        templateIntegration={templateIntegration}
      />

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </Grid>
  )
}

function DisplayTaxonomyNode({
  node,
  onChange,
}: {
  node: EtsyTaxonomyNode
  onChange: (taxonomyId: number, taxonomyName: string) => void
}): JSX.Element {
  return <TreeNode node={node} key={node.id} onChange={onChange} />
}

function TreeNode({
  node,
  onChange,
}: {
  node: EtsyTaxonomyNode
  onChange: (taxonomyId: number, taxonomyName: string) => void
}): JSX.Element {
  return (
    <TreeItem
      key={node.id}
      nodeId={`${node.id}`}
      label={node.name}
      onClick={() => !node.children.length && onChange(node.id, node.name)}
    >
      {node.children?.map((node) => (
        <TreeNode node={node} key={node.id} onChange={onChange} />
      ))}
    </TreeItem>
  )
}
