import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  Aspect,
  EbayTemplateIntegration,
  SetEbayProductAspectIntegration,
} from '../../../../types/Ebay.types'
import {
  GetProduct,
  GetProductAttribute,
  GetProductTemplate,
  GetProductTemplateAttribute,
} from '../../../../api/product'
import { useMemo } from 'react'
import Alert, { AlertInput } from '../../../common/Alert'
import { copy, getAttributeTag, isLike } from '../../../../utils/functions'
import AttributeSelect from '../../../common/AttributeSelect'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import Button from '@mui/joy/Button'
import { extractAttributeName } from '../../../../classes/Parser'

export default function EbayProductAspects({
  product,
  template,
  templateIntegration,
  valueMapping,
  onValueMapChange,
  aspects,
  aspectIntegrations,
  templateAttributes,
  disabled,
  channelOptions,
  onChange,
  onUpdate,
}: {
  product: GetProduct
  template: GetProductTemplate
  templateIntegration?: EbayTemplateIntegration
  valueMapping?: Record<string, Record<string, string[]>>
  onValueMapChange?: (
    valueMapping: Record<string, Record<string, string[]>>,
  ) => void
  aspects: Aspect[]
  aspectIntegrations: SetEbayProductAspectIntegration[]
  templateAttributes: GetProductTemplateAttribute[]
  disabled?: boolean
  channelOptions?: Record<
    string,
    Record<string, string[] | undefined> | undefined
  >
  onChange: (aspect: SetEbayProductAspectIntegration) => void
  onUpdate?: () => void
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [parseDetails, setParseDetails] = useState<boolean>(true)

  // const valueMappingRecord = templateIntegration?.valueMappings.

  // const handleChange = (aspect: AspectIntegrationInput) => {
  //   onChange(aspect)
  //   // onUpdate?.()
  // }

  const handleAspectValueMappingChange = (
    valueMapping: Record<string, Record<string, string[]>>,
  ) => {
    onValueMapChange?.(valueMapping)
  }

  const handleAspectValueChange = (
    aspect: Aspect,
    aspectIntegration: SetEbayProductAspectIntegration | undefined,
    newValue: string | undefined,
  ) => {
    // Add new
    if (!aspectIntegration) {
      const newAspectIntegration: SetEbayProductAspectIntegration = {
        aspectName: aspect.localizedAspectName,
        value: newValue || '',
        dataType: aspect.aspectConstraint.aspectDataType,
        required: aspect.aspectConstraint.aspectRequired,
      }
      onChange(newAspectIntegration)
      return
    }

    // Update value of existing
    aspectIntegration.value = newValue || ''
    onChange(aspectIntegration)
  }

  const handleToggleParseDetails = () => {
    setParseDetails(!parseDetails)
  }

  const attributeNames = product.attributes.map((a) => a.templateAttribute.name)
  const aspectIntegrationsRecord: Record<
    string,
    SetEbayProductAspectIntegration
  > = useMemo(() => {
    const aspectIntegrationsRecord: Record<
      string,
      SetEbayProductAspectIntegration
    > = {}
    aspectIntegrations.forEach((aspectIntegration) => {
      aspectIntegrationsRecord[aspectIntegration.aspectName] = aspectIntegration
    })
    return aspectIntegrationsRecord
  }, [aspectIntegrations])

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Typography level="h4">Item Specifics</Typography>
      </Grid>
      <Grid item xs={1}>
        <Button
          sx={{ float: 'right' }}
          size="sm"
          variant="plain"
          color="neutral"
          onClick={handleToggleParseDetails}
          endDecorator={
            parseDetails ? (
              <AlternateEmailIcon fontSize="small" />
            ) : (
              <VisibilityIcon fontSize="small" />
            )
          }
        >
          <Typography level="body-sm">
            {parseDetails ? 'View' : 'Parse'}
          </Typography>
        </Button>
      </Grid>
      {aspects.map((aspect) => {
        const aspectName = aspect.localizedAspectName
        const aspectIntegration = aspectIntegrationsRecord[aspectName]

        let productAttribute: GetProductAttribute | undefined
        const attributeName = extractAttributeName(aspectIntegration?.value)
        if (attributeName) {
          productAttribute = product?.attributes.find(
            (attribute) => attribute.templateAttribute.name === attributeName,
          )
        }

        if (aspectIntegration?.value === undefined) {
          productAttribute = product?.attributes.find((a) =>
            isLike(a.templateAttribute.name, aspect.localizedAspectName),
          )
        }

        // let valueInOptions = false
        const options: string[] = []

        aspect.aspectValues?.forEach((v) => {
          // if (v.localizedValue === value) {
          //   valueInOptions = true
          // }
          options.push(v.localizedValue)
        })

        return (
          <Grid item xs={12} key={aspectName}>
            {aspect.aspectValues?.length ? (
              <AttributeSelect
                nameContainer={true}
                key={aspectName + parseDetails}
                name={aspectName}
                nameEditable={false}
                nameLevel="title-sm"
                product={product}
                template={template}
                templateIntegration={templateIntegration}
                parse={parseDetails}
                valueMapping={valueMapping?.[aspectName]}
                onValueMappingChange={(value, values) => {
                  const newValueMapping = copy(valueMapping || {})
                  const newValueMapRecord: Record<string, string[]> = {
                    [value]: values,
                  }
                  // update aspect
                  newValueMapping[aspectName] = newValueMapRecord
                  // set aspect value mapping

                  handleAspectValueMappingChange(newValueMapping)
                }}
                channelOptions={{
                  etsy: channelOptions?.['etsy']?.[
                    productAttribute?.templateAttribute.name || ''
                  ],
                }}
                allowedValues={options}
                strict={aspect.aspectConstraint.aspectMode === 'SELECTION_ONLY'}
                // freeSolo={aspect.aspectConstraint.aspectMode === 'FREE_TEXT'}
                freeSolo={true}
                required={aspect.aspectConstraint.aspectRequired}
                // disabled={!parseDetails}
                // errorText={errorText}
                // warningText={warningText}
                options={options}
                multiple={false}
                attributeNames={attributeNames}
                value={
                  aspectIntegration?.value ??
                  getAttributeTag(productAttribute?.templateAttribute.name)
                }
                onChange={(newValue) =>
                  handleAspectValueChange(aspect, aspectIntegration, newValue)
                }
              />
            ) : (
              <AttributeSelect
                name={aspectName}
                nameContainer={true}
                nameEditable={false}
                product={product}
                template={template}
                templateIntegration={templateIntegration}
                parse={parseDetails}
                valueMapping={valueMapping?.[aspectName]}
                onValueMappingChange={(value, values) => {
                  const newValueMapping = copy(valueMapping || {})

                  const newValueMapRecord: Record<string, string[]> = {
                    [value]: values,
                  }
                  // update aspect
                  newValueMapping[aspectName] = newValueMapRecord
                  // set aspect value mapping

                  handleAspectValueMappingChange(newValueMapping)
                }}
                channelOptions={{
                  etsy: channelOptions?.['etsy']?.[
                    productAttribute?.templateAttribute.name || ''
                  ],
                }}
                channelAttributeOptions={{
                  etsy: channelOptions?.['etsy'],
                }}
                allowedValues={options}
                nameLevel="title-sm"
                required={aspect.aspectConstraint.aspectRequired}
                value={
                  aspectIntegration?.value ??
                  getAttributeTag(productAttribute?.templateAttribute.name)
                }
                attributeNames={!parseDetails ? attributeNames : []}
                onChange={(newValue) =>
                  handleAspectValueChange(aspect, aspectIntegration, newValue)
                }
              />
            )}
          </Grid>
        )
      })}

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
